import React, { useEffect, useState, useRef, useContext } from 'react';

import {
  ExpandLess,
  ExpandMore,
  SettingsBackupRestore,
  Reorder,
  Search,
  Clear,
  InfoOutlined,
} from '@mui/icons-material';

import {
  getActiveCount,
  getInactiveCount,
  getRunningCount,
  getStoppedCount,
} from '../../../utils/web_utils';

import {
  lastUpdate,
  stop_duration,
  getAddress,
  getDistance,
  deviceFilter,
} from '../../../utils/utils';

import {
  Card,
  Divider,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';

import { Item } from '../Utils';
import { MobileContext } from '../../../Firebase/MobileProvider';

const AllDeviceInfo = ({ devices, infoCardClick }) => {
  const MyCard = ({ title, value, titleMessage, index }) => {
    return (
      <Card
        square
        style={{ padding: 6, minWidth: 100, cursor: 'pointer' }}
        onClick={() => infoCardClick(index)}
      >
        <Tooltip title={titleMessage}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography>{title}</Typography>
            <Divider style={{ width: '90%' }} />
            <Typography>{value}</Typography>
          </div>
        </Tooltip>
      </Card>
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        <MyCard
          title="Active"
          value={getActiveCount(devices)}
          titleMessage="Click to Select All Active Vehicles"
          index={0}
        />
        <MyCard
          title="In-Active"
          value={getInactiveCount(devices)}
          titleMessage="Click to Select All Inactive Vehicles"
          index={1}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        <MyCard
          title="Running"
          value={getRunningCount(devices)}
          titleMessage="Click to Select All Running Vehicles"
          index={2}
        />
        <MyCard
          title="Stopped"
          value={getStoppedCount(devices)}
          titleMessage="Click to Select All Stopped Vehicles"
          index={3}
        />
      </div>
    </div>
  );
};

const DeviceInfo = ({ selectedDevice, status }) => {
  const [address, setAddress] = useState('');
  const locationRef = useRef(null);

  useEffect(() => {
    if (status && status.lat && status.lng) {
      if (
        !locationRef.current ||
        Object.keys(locationRef.current).length === 0 ||
        getDistance(
          { lat: locationRef.current.lat, lng: locationRef.current.lng },
          { lat: status.lat, lng: status.lng }
        ) >= 150
      ) {
        getAddress(status.lat, status.lng)
          .then((add) => setAddress(add))
          .catch((err) => console.log(err));
        locationRef.current = { ...status };
      }
    }
  }, [status]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        width: '100%',
      }}
    >
      <Item title="ID" value={selectedDevice.id} />
      <Item title="Reg. No" value={selectedDevice.registration_number} />
      <Item title="Igition" value={status && status.acc} />
      <Item title="Speed" value={status && status.speed} />
      <Item title="Charging" value={status && status.charging} />
      <Item title="Relay" value={status && status.fuel_line} />
      <Item title="Voltage level" value={status && status.voltage_level} />
      <Item
        title="Number of Satellite"
        value={status && status.number_of_satellite}
      />
      {selectedDevice.device_model === 'S5E' && (
        <div>
          <Item
            title="PTO io status"
            value={
              status && status.pto_io_status && status.pto_io_status === 1
                ? 'ON'
                : 'OFF'
            }
          />
          <Item
            title="Overspeed"
            value={
              status && status.over_speed && status.over_speed === 1
                ? 'True'
                : 'False'
            }
          />
          <Item title="Temperature" value={status && status.temperature} />
        </div>
      )}

      <Item
        title="Last Update"
        value={status && lastUpdate(status.update_time)}
      />

      <Item
        title="Stop Duration"
        value={
          status &&
          stop_duration(
            status.active_time ? status.active_time : status.update_time
          )
        }
      />
      <Item
        title="Total distance"
        value={
          selectedDevice.device_model === 'S5E'
            ? status && status.milage + ' KM'
            : status && (status.milage / 1000).toFixed(2) + ' KM'
        }
      />
      <Item title="Address" value={status && address} />
    </div>
  );
};

const DeviceList = ({
  userDevices,
  sharedDevices,
  fromSharedUser,
  search,
  selectedDevice,
  setSelectedDevice,
}) => {
  const [state, setState] = useState({
    searchedDevice: [],
  });

  useEffect(() => {
    if (search === '') {
      setState((old) => ({
        ...old,
        searchedDevice: fromSharedUser ? [...sharedDevices] : [...userDevices],
      }));
    } else {
      setState((old) => ({
        ...old,
        searchedDevice: fromSharedUser
          ? [...sharedDevices].filter((x) => deviceFilter(x, search))
          : [...userDevices].filter((x) => deviceFilter(x, search)),
      }));
    }
  }, [search, userDevices, sharedDevices, fromSharedUser]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        width: '100%',
      }}
    >
      <List style={{ width: '100%' }}>
        {state.searchedDevice.map((item, index) => (
          <ListItem
            button
            key={index}
            divider
            onClick={() => setSelectedDevice(item)}
            selected={selectedDevice && item.id === selectedDevice.id}
          >
            <ListItemText style={{ textTransform: 'uppercase' }}>
              {item.registration_number}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const MobileVehicleInfo = ({
  status,
  infoCardClick,
  handleShowAllVehiclesClick,
}) => {
  const { userDevices, sharedDevices } = useContext(FirebaseContext);

  const { selectedDevice, fromSharedUser, setSelectedDevice } =
    useContext(MobileContext);

  const containerRef = useRef(null);

  const [state, setState] = useState({
    open: true,
    listOpen: false,
    search: '',
  });

  const handleChange = (e) => {
    e.persist();
    setState((old) => ({
      ...old,
      search: e.target ? e.target.value : '',
    }));
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '100%',
        height: '40%',
        zIndex: 500,
      }}
      ref={containerRef}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {state.open && (
          <Slide
            direction="up"
            in={state.open}
            container={containerRef.current}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                backgroundColor: grey[300],
                overflow: 'auto',
              }}
            >
              {state.listOpen ? (
                <DeviceList
                  sharedDevices={sharedDevices}
                  userDevices={userDevices}
                  fromSharedUser={fromSharedUser}
                  search={state.search}
                  selectedDevice={selectedDevice}
                  setSelectedDevice={setSelectedDevice}
                />
              ) : selectedDevice ? (
                <DeviceInfo selectedDevice={selectedDevice} status={status} />
              ) : (
                <AllDeviceInfo
                  devices={fromSharedUser ? sharedDevices : userDevices}
                  infoCardClick={infoCardClick}
                />
              )}
            </div>
          </Slide>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            alignItems: 'center',
            padding: 10,
          }}
        >
          {state.listOpen ? (
            <TextField
              style={{ flex: 1 }}
              placeholder="Search vehicle by reg. number"
              onChange={handleChange}
              name="search"
              value={state.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: 'pointer',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setState((old) => ({ ...old, search: '' }))
                      }
                    >
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <Typography variant="button">
              {selectedDevice
                ? 'Selected Vehicle Info'
                : fromSharedUser
                ? 'Shared Vehicles Overview'
                : 'Vehicles Overview'}
            </Typography>
          )}

          <div style={{ display: 'flex' }}>
            {!state.listOpen && (
              <IconButton
                onClick={() =>
                  setState((old) => ({ ...old, listOpen: !old.listOpen }))
                }
              >
                <Reorder />
              </IconButton>
            )}
            {state.listOpen && selectedDevice ? (
              <IconButton
                onClick={() =>
                  setState((old) => ({ ...old, listOpen: !old.listOpen }))
                }
              >
                <InfoOutlined />
              </IconButton>
            ) : (
              selectedDevice && (
                <Tooltip title="Click to show all Vehicles">
                  <IconButton onClick={handleShowAllVehiclesClick}>
                    <SettingsBackupRestore />
                  </IconButton>
                </Tooltip>
              )
            )}

            <IconButton
              onClick={() => setState((old) => ({ ...old, open: !old.open }))}
            >
              {state.open ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileVehicleInfo;
