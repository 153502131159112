import { DEVICES_URL } from '../utils/url';

class DeviceManager {
  constructor(axios, AUTHORIZATION_KEY) {
    this.axios = axios;
    this.AUTHORIZATION_KEY = AUTHORIZATION_KEY;
  }

  updateDevice(device_id, formData) {
    return new Promise((resolve, reject) => {
      this.axios
        .put(DEVICES_URL + 'client/' + device_id, formData, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          // console.log(err);
        });
    });
  }
}

export default DeviceManager;
