import { useContext, useState, useEffect } from 'react';

import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import {
  Card,
  Tooltip,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import { GetApp, ChevronLeft, ChevronRight } from '@mui/icons-material';
import UserDeviceAutoComplete from '../../sub/UserDeviceAutoComplete';
import SharedDeviceAutoComplete from '../../sub/SharedDeviceAutoComplete';
import dateformat from 'dateformat';
import {
  getTotalDistanceFromMonthlyData,
  getTotalFuelFromMonthlyData,
  getTotalRunningTimeFromMonthlyData,
  locationsCountFromMonthlyData,
} from '../../../utils/utils';
import { locationManager } from '../../../api-networking';
import { MonthlyReportTable } from '../../table';

const MonthlyReportSingle = () => {
  const { selectedDevice, fromSharedUser } = useContext(FirebaseContext);

  const [state, setState] = useState({
    selectedDate: new Date(),
    monthlyData: [],
    visible: false,
  });

  useEffect(() => {
    if (selectedDevice && state.selectedDate) {
      let data = {
        device_id: selectedDevice.id,
        year: state.selectedDate.getFullYear(),
        month: state.selectedDate.getMonth(),
        device_type: selectedDevice.vehicle_type,
      };
      setState((old) => ({ ...old, visible: true }));
      locationManager
        .getMonthlyData(data)
        .then((d) => {
          console.log(d);
          setState((old) => ({
            ...old,
            monthlyData:
              selectedDevice.vehicle_type === 8
                ? [...d]
                : [...d].filter((x) => x.distance > 1500),
            visible: false,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [selectedDevice, state.selectedDate]);

  const prevClick = () => {
    let d = new Date(state.selectedDate);
    d.setMonth(d.getMonth() - 1);
    setState((old) => ({ ...old, selectedDate: d }));
  };

  const nextClick = () => {
    let d = new Date(state.selectedDate);
    d.setMonth(d.getMonth() + 1);
    setState((old) => ({ ...old, selectedDate: d }));
  };

  const downloadFile = () => {
    let data = {
      device: selectedDevice,
      year: state.selectedDate.getFullYear(),
      month: state.selectedDate.getMonth(),
    };
    setState((old) => ({ ...old, visible: true }));

    locationManager
      .downloadMonthlyFile(data)
      .then((data) => {
        setState((old) => ({ ...old, visible: false }));
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => {
        console.log(err);
        setState((old) => ({ ...old, visible: false }));
      });
  };
  return (
    <div
      style={{
        display: 'flex',
        padding: 10,
        width: '100%',
        height: '100%',
      }}
    >
      {selectedDevice ? (
        <Card style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: 10,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 20,
                    border: '1px solid #f48e0d',
                    padding: 10,
                  }}
                >
                  <Typography variant="caption">
                    Total Distance:{' '}
                    {getTotalDistanceFromMonthlyData(state.monthlyData)} KM
                  </Typography>
                  {selectedDevice.mileage && (
                    <Typography variant="caption">
                      Total Fuel:{' '}
                      {getTotalFuelFromMonthlyData(
                        state.monthlyData,
                        selectedDevice
                      )}{' '}
                      Lit
                    </Typography>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #f48e0d',
                    padding: 10,
                  }}
                >
                  <Typography variant="caption">
                    Active ({state.monthlyData.length}) Days
                  </Typography>

                  <Typography variant="caption">
                    Location ({locationsCountFromMonthlyData(state.monthlyData)}
                    ) Nos
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 20,
                    border: '1px solid #f48e0d',
                    padding: 10,
                  }}
                >
                  <Typography variant="caption">
                    Avg Running Time (
                    {(
                      getTotalRunningTimeFromMonthlyData(state.monthlyData) /
                      state.monthlyData.length
                    ).toFixed(2)}
                    ) Hrs/Day
                  </Typography>

                  <Typography variant="caption">
                    Total Running Time (
                    {getTotalRunningTimeFromMonthlyData(state.monthlyData)}) Hrs
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: state.visible ? 'center' : 'space-between',
                  alignItems: 'center',
                  width: '20%',
                }}
              >
                {state.visible ? (
                  <CircularProgress />
                ) : (
                  <>
                    <IconButton onClick={prevClick}>
                      <ChevronLeft color="secondary" fontSize="large" />
                    </IconButton>

                    <Typography style={{ marginLeft: 20, marginRight: 20 }}>
                      Report on {dateformat(state.selectedDate, 'mmmm-yyyy')}
                    </Typography>

                    <IconButton onClick={nextClick}>
                      <ChevronRight color="secondary" fontSize="large" />
                    </IconButton>
                  </>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                {fromSharedUser ? (
                  <SharedDeviceAutoComplete />
                ) : (
                  <UserDeviceAutoComplete />
                )}

                <Tooltip
                  title="Download"
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  <IconButton onClick={downloadFile}>
                    <GetApp />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                marginTop: 10,
              }}
            >
              <div style={{ width: '98%', height: '100%' }}>
                <MonthlyReportTable
                  monthly_data={state.monthlyData}
                  device={selectedDevice}
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          {fromSharedUser ? (
            <SharedDeviceAutoComplete />
          ) : (
            <UserDeviceAutoComplete />
          )}
        </div>
      )}
    </div>
  );
};

export default MonthlyReportSingle;
