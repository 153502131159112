import moment from 'moment';
import axios from 'axios';
import * as geolib from 'geolib';

const plurl =
  'https://eu1.locationiq.com/v1/reverse.php?key=pk.e21ecbeb56349835349cc603348783be';

export const isValidEmail = (email) => {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(email)) {
    return false;
  }

  return true;
};

export const getDistance = (oldGeo, newGeo) => {
  return geolib.getDistance(
    { lat: oldGeo.lat, lng: oldGeo.lng },
    { lat: newGeo.lat, lng: newGeo.lng }
  );
};

export const fuelRequired = (distance, congestionTime, device) => {
  if (device.mileage) {
    let disKm = distance / 1000;
    let runningConsumption = disKm / device.mileage;

    if (device.congestion_consumption) {
      let x = congestionTime / 3600;
      let congesCon = x * device.congestion_consumption;
      runningConsumption = runningConsumption + congesCon;
    }

    return runningConsumption.toFixed(2);
  } else {
    return 'undefined';
  }
};

export const getTotalDistanceFromMonthlyData = (monthlyData) => {
  let d = monthlyData.reduce((acc, obj) => acc + obj.distance, 0);
  return (d / 1000).toFixed(2);
};

export const getTotalRunningTimeFromMonthlyData = (monthlyData) => {
  let d = monthlyData.reduce((acc, obj) => acc + obj.running_time, 0);
  return (d / 3600).toFixed(0);
};

export const locationsCountFromMonthlyData = (monthlyData) => {
  return monthlyData.reduce((acc, obj) => acc + obj.count, 0);
};

export const getTotalFuelFromMonthlyData = (monthlyData, selectedDevice) => {
  let totalFuel = monthlyData.reduce(
    (acc, x) =>
      acc + Number(fuelRequired(x.distance, x.congestion_time, selectedDevice)),
    0
  );
  // console.log(totalFuel,"Fuel")
  if (totalFuel > 0) {
    return totalFuel.toFixed(2);
  }
  return 0.0;
};

export const getDateFromCellData = (cellData) => {
  return (
    String(cellData.day).padStart(2, 0) +
    '-' +
    String(cellData.month + 1).padStart(2, 0) +
    '-' +
    String(cellData.year)
  );
};

export const formatDuration = (time) => {
  let hr = Math.floor(time / 3600);
  let rem = time % 3600;
  let min = Math.floor(rem / 60);

  return (
    String(hr).padStart(2, 0) + ' hrs ' + String(min).padStart(2, 0) + ' mins'
  );
};

export const getSuffix = (n) => {
  if (n >= 11 && n <= 13) {
    return 'th';
  }
  switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formatTime = (timeStr) => {
  let arr = timeStr.split(':');
  let hr = Number(arr[0]);
  let min = Number(arr[1]);
  let suffix = '';
  if (Math.floor(hr / 12) === 0) {
    suffix = ' AM';
  } else {
    hr = hr - 12;
    suffix = ' PM';
  }
  if (hr === 0) {
    hr = 12;
  }
  return String(hr).padStart(2, 0) + ':' + String(min).padStart(2, 0) + suffix;
};

export const minToStr = (timeInMin) => {
  let hour = Math.floor(timeInMin / 60);
  let min = timeInMin % 60;

  let suffix = '';

  let suff = Math.floor(hour / 12);
  hour = hour % 12;

  if (suff === 0) {
    suffix = ' AM';
  } else {
    suffix = ' PM';
  }

  if (hour === 0) {
    hour = 12;
  }

  return (
    String(hour).padStart(2, 0) + ':' + String(min).padStart(2, 0) + suffix
  );
};

export const getUpdatedTime = (time) => {
  return moment(time).format('MMMM Do YYYY, h:mm:ss a');
};

export const getAddress = (lat, lng) => {
  let url = plurl + '&lat=' + lat + '&lon=' + lng + '&format=json';
  // console.log('Calll Address API');
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data.display_name) {
          resolve(response.data.display_name);
        }
      })
      .catch((err) => reject(err));
  });
};

export const getDistanceFromLocations = (locations) => {
  let dist = 0;

  for (let i = 0; i < locations.length; i++) {
    if (i > 0) {
      dist = dist + getDistance(locations[i - 1].geo, locations[i].geo);
    }
  }

  dist = (dist / 1000).toFixed(2);
  return dist;
};

export const getRotation = (oldGeo, newGeo) =>
  geolib.getGreatCircleBearing(
    {
      latitude: oldGeo.lat,
      longitude: oldGeo.lng,
    },
    { latitude: newGeo.lat, longitude: newGeo.lng }
  );

export const lastUpdate = (time) => {
  return time ? moment(time).format('MMMM DD YYYY, h:mm:ss a') : 'undefined';
};

export const stop_duration = (dateStr) => {
  let updateTime = new Date(dateStr);
  let currentDate = new Date();
  let diff = Math.floor((currentDate.getTime() - updateTime.getTime()) / 1000);

  if (diff <= 30) {
    return 'RUNNING';
  } else if (diff > 30 && diff < 60) {
    return diff + ' seconds ago';
  } else if (diff > 60 && diff < 3600) {
    let min = Math.floor(diff / 60);
    let sec = diff % 60;
    return min + ' min ' + sec + ' secs ago';
  } else if (diff > 3600 && diff < 3600 * 24) {
    let hr = Math.floor(diff / 3600);
    let rem = diff % 3600;
    let min = Math.floor(rem / 60);
    let sec = rem % 60;
    return hr + ' hrs ' + min + ' min ' + sec + ' secs ago';
  } else {
    let day = Math.floor(diff / (3600 * 24));
    let hrRem = diff % (3600 * 24);
    let hr = Math.floor(hrRem / 3600);
    let rem = hrRem % 3600;
    let min = Math.floor(rem / 60);
    let sec = rem % 60;
    return day + ' days ' + hr + ' hrs ' + min + ' min ' + sec + ' secs ago';
  }

  // return diff
};

export const deviceFilter = (device, search) => {
  return (
    (device.id && device.id.toLowerCase().includes(search.toLowerCase())) ||
    (device.mask_id &&
      device.mask_id.toLowerCase().includes(search.toLowerCase())) ||
    (device.registration_number &&
      device.registration_number
        .toLowerCase()
        .includes(search.toLowerCase())) ||
    (device.vehicle_model &&
      device.vehicle_model.toLowerCase().includes(search.toLowerCase())) ||
    (device.device_sim_number &&
      device.device_sim_number.toLowerCase().includes(search.toLowerCase())) ||
    (device.chasis_number &&
      device.chasis_number.toLowerCase().includes(search.toLowerCase())) ||
    (device.code && device.code.toLowerCase().includes(search.toLowerCase())) ||
    (device.driver_name &&
      device.driver_name.toLowerCase().includes(search.toLowerCase())) ||
    (device.driver_phone &&
      device.driver_phone.toLowerCase().includes(search.toLowerCase()))
  );
};
