import { utils, writeFileXLSX, CellStyle } from 'xlsx-js-style';

const DEVICES_HEADER = [
  'ID',
  'REGISTRATION_NO',
  'CHASIS_NO',
  'MODEL',
  'CODE',
  'CUSTOMER NAME',
  'CUSTOMER CONTACT',
  'LAST UPDATES',
];

const getColumnWidth = (devices, headers) => {
  //id	registration_number	chasis_number	model	code	day_count	start_time	end_time	location_count	distance	running_time	congestion_time	idle_time

  let data = [...devices];
  let arr = [
    { wch: data.reduce((w, r) => Math.max(w, r.id.length), headers[0].length) },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.registration_number.trim().length),
        headers[1].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) =>
          Math.max(w, r.chasis_number ? r.chasis_number.trim().length : 0),
        headers[2].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) =>
          Math.max(w, r.vehicle_model ? r.vehicle_model.trim().length : 0),
        headers[3].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.code ? r.code.trim().length : 0),
        headers[4].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.driver_name ? r.driver_name.trim().length : 0),
        headers[5].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) =>
          Math.max(w, r.driver_phone ? r.driver_phone.trim().length : 0),
        headers[6].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.update_time.trim().length),
        headers[7].length
      ),
    },
    // {wch:data.reduce((w, r) => Math.max(w, r.day_count.length), headers[5].length)},
    // {wch:data.reduce((w, r) => Math.max(w, r.start_time.trim().length), headers[6].length)},
    // {wch:data.reduce((w, r) => Math.max(w, r.end_time.trim().length), headers[7].length)},
    // {wch:data.reduce((w, r) => Math.max(w, r.location_count.length), headers[8].length)},
    // {wch:data.reduce((w, r) => Math.max(w, r.distance.length), headers[9].length)},
    // {wch:data.reduce((w, r) => Math.max(w, r.running_time.trim().length), headers[10].length)},
    // {wch:data.reduce((w, r) => Math.max(w, r.congestion_time.trim().length), headers[11].length)},
    // {wch:data.reduce((w, r) => Math.max(w, r.idle_time.trim().length), headers[12].length)},
  ];

  return arr;
};

export const downloadDevicesFile = (devices) => {
  // let date = new Date()

  const ws = utils.json_to_sheet(
    [...devices].map(({ device_sim_number, ...item }) => item),
    { origin: 'A5' }
  );

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: DEVICES_HEADER.length - 1 } },
  ];
  ws['!merges'] = merge;

  let topArr = [
    [
      {
        v: 'Rangs Motors',
        t: 's',
        s: CellStyle({
          font: { name: 'Courier', sz: 24 },
          alignment: { horizontal: 'center', vertical: 'center' },
        }),
      },
    ],

    [
      {
        v: 'Kallyanpur Dhaka',
        t: 's',
        s: {
          alignment: { horizontal: 'center', vertical: 'center' },
          font: { bold: true },
        },
      },
    ],
  ];

  utils.sheet_add_aoa(ws, topArr, { origin: 'A1' });

  // ws['!data'][merge]
  // const max_width_a = [...state.data].reduce((w, r) => Math.max(w, r.id.length), 10);
  // const max_width_b = [...state.data].reduce((w, r) => Math.max(w, r.registration_number.trim().length), 10);
  ws['!cols'] = getColumnWidth(devices, DEVICES_HEADER);

  utils.sheet_add_aoa(ws, [DEVICES_HEADER], { origin: 'A5' });
  const wb = utils.book_new();

  utils.book_append_sheet(wb, ws, 'All');
  writeFileXLSX(wb, 'Devices.xlsx');

  // utils.book_append_sheet(wb, ws, dateformat(date, "mmmm-yyyy"));
  // writeFileXLSX(wb, dateformat(date, "mmmm-yyyy")+".xlsx");
};

const getColumnWidthofRouteSummeryTable = (datas, headers) => {
  let data = [...datas];
  let arr = [
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.start_address.length),
        headers[0].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.start_time.length),
        headers[1].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.distance.length),
        headers[2].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.duration.length),
        headers[3].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.end_time.length),
        headers[4].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.end_address.length),
        headers[5].length
      ),
    },
  ];

  return arr;
};

export const downloadRouteSummery = (datas) => {
  const headers = [
    'Start Address',
    'Start At',
    'Distance(KM)',
    'Duration',
    'End At',
    'End Address',
  ];

  const ws = utils.json_to_sheet(datas, { origin: 'A2' });
  ws['!cols'] = getColumnWidthofRouteSummeryTable(datas, headers);

  utils.sheet_add_aoa(ws, [headers], { origin: 'A2' });
  const wb = utils.book_new();

  utils.book_append_sheet(wb, ws, 'Route Summery');
  writeFileXLSX(wb, 'Route Summery.xlsx');
};
