import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import { Chart } from 'react-google-charts';
import { BarChart } from '@mui/icons-material';
import { MobileContext } from '../../Firebase/MobileProvider';
import { locationManager } from '../../api-networking';
import { minToStr } from '../../utils/utils';
const Item = ({ title, value }) => {
  return (
    <Card>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 80,
          alignItems: 'center',
          padding: 6,
        }}
      >
        <Typography>{title}</Typography>
        <Typography>{value}</Typography>
      </div>
    </Card>
  );
};

const MobileSpeedReport = () => {
  const [state, setState] = useState({
    value: 0,
    info: { max: 0, min: 0, avg: 0 },
    data: [],
    frequency: [],
    timeLineData: [],
    barData: [],
  });
  const { selectedDevice, selectedDate } = useContext(MobileContext);

  const barOptions = {
    hAxis: {
      title: 'Years',
      direction: -1,
      slantedText: true,
      slantedTextAngle: 90,
      textPosition: 'Out',
    },
    chart: {
      title: 'Speed Frequency Distribution',
    },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
    colors: ['#f48e0d'],
    vAxis: {
      title: 'Speed',
    },

    legend: { position: 'none' },
  };

  const lineOptions = {
    title: 'Speed Timeline',
    titleTextStyle: { color: '#f48e0d', bold: true },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
    colors: ['#f48e0d'],
    hAxis: {
      title: 'Time',
      slantedTextAngle: 90,
    },
    vAxis: {
      title: 'Speed',
    },
    curveType: 'function',
  };

  useEffect(() => {
    if (selectedDate && selectedDevice) {
      let data = {
        id: selectedDevice.id,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
        day: selectedDate.getDate(),
        device_type: selectedDevice.vehicle_type,
      };
      locationManager
        .getDailySpeedData(data)
        .then((d) => {
          // let a = [['x', 'min', 'avg', 'max']].concat(
          //   d.data.map((x) => [x.time, x.min, x.avg, x.max])
          // );
          console.log(d);
          setState((old) => ({
            ...old,
            info: d.info ? d.info : { max: 0, min: 0, avg: 0 },
            data: d.data,
            frequency: d.frequency,
          }));
          // console.log(d);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDate, selectedDevice]);

  useEffect(() => {
    if (state.data.length > 1) {
      setState((old) => ({
        ...old,
        timeLineData: [['time', 'speed']].concat(
          old.data.map((x) => [minToStr(x.time), x.avg])
        ),
      }));
    }
  }, [state.data]);

  useEffect(() => {
    if (state.frequency.length > 1) {
      setState((old) => ({
        ...old,
        barData: [['Speed', 'Count']].concat(
          old.frequency.map((x) => [
            x._id + ' to ' + (x._id + 10) + ' (K/M)',
            x.count,
          ])
        ),
      }));
    }
  }, [state.frequency]);
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', flex: 2, backgroundColor: 'red' }}></div>
        <div
          style={{ display: 'flex', flex: 3, backgroundColor: '#eeeeee' }}
        ></div>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          <Item title="Max" value={state.info.max + ' KM/H'} />
          <Item title="Avg" value={state.info.avg + ' KM/H'} />
          <Item title="Min" value={state.info.min + ' KM/H'} />
        </div>

        <div
          style={{
            display: 'flex',
            flex: 1,
            padding: 10,
          }}
        >
          <Card style={{ width: '100%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                padding: 10,
                height: '96%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flex: 1,
                }}
              >
                {state.value === 0
                  ? state.barData.length > 1 && (
                      <Chart
                        chartType="Bar"
                        width="100%"
                        height="100%"
                        data={state.barData}
                        options={barOptions}
                      />
                    )
                  : state.timeLineData.length > 1 && (
                      <Chart
                        chartType="LineChart"
                        width="100%"
                        height="100%"
                        data={state.timeLineData}
                        options={lineOptions}
                      />
                    )}
              </div>
            </div>
          </Card>
        </div>

        <BottomNavigation
          showLabels
          value={state.value}
          onChange={(event, newValue) => {
            setState((old) => ({ ...old, value: newValue }));
          }}
        >
          <BottomNavigationAction label="Frequenct" icon={<BarChart />} />
          <BottomNavigationAction label="Timeline" icon={<BarChart />} />
        </BottomNavigation>
      </div>
    </div>
  );
};

export default React.memo(MobileSpeedReport);
