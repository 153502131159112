import React, { useContext } from 'react';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';
import Home from './Home';
import Welcome from './Welcome';
import EmailVerify from './EmailVerify';
import Authentication from './Authentication';

const Main = () => {
  const context = useContext(FirebaseContext);

  if (context.user) {
    if (context.user.email) {
      if (context.user.emailVerified) {
        return <Home />;
      } else {
        return <EmailVerify />;
      }
    } else {
      return <Welcome />;
    }
  } else {
    return <Authentication />;
  }
};

export default Main;
