import React, { createContext, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';
// import axios from 'axios';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  signInWithRedirect,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth';
import { AuthErrorDialog, AuthInfoDialog } from '../components/pages/Utils';
import {
  DASHBOARD_TITLE,
  ALL_VEHICLE_TITLE,
  LOGOUT_TITLE,
  ALL_VEHICLE_IN_MAP_TITLE,
  SHARED_VEHICLE_TITLE,
  PROFILE_TITLE,
  NOTIFICATIONS_TITLE,
  ALERTS_TITLE,
  EXPENSES_TITLE,
  MONTHLY_REPORT_TITLE,
  DAILY_TRAVEL_REPORT_TITLE,
  WORKSHOP_TITLE,
} from '../utils/web_utils';
import Dashboard from '../components/pages/home-components/Dashboard';
import AllVehicles from '../components/pages/home-components/AllVehicles';
import VehiclesInMap from '../components/pages/home-components/VehiclesInMap';
import SharedVehicles from '../components/pages/home-components/SharedVehicles';
import Profile from '../components/pages/home-components/Profile';
import Notifications from '../components/pages/home-components/Notifications';
import Alerts from '../components/pages/home-components/Alerts';
import Expenses from '../components/pages/home-components/Expenses';
import MonthlyReport from '../components/pages/home-components/MonthlyReport';
import DailyTravelReport from '../components/pages/home-components/DailyTravelReport';
import WorkshopReport from '../components/pages/home-components/WorkshopReport';
import LiveTracking from '../components/pages/home-components/LiveTracking';
import DailyReport from '../components/pages/home-components/DailyReport';
import MonthlyReportSingle from '../components/pages/home-components/MonthlyReportSingle';
import SpeedReport from '../components/pages/home-components/SpeedReport';
import RouteSummery from '../components/pages/home-components/RouteSummery';
import MonthlyTemperatureReport from '../components/pages/home-components/MonthlyTemperatureReport';
import PTIOReport from '../components/pages/home-components/PTIOReport';
import {
  deviceManager,
  userManager,
  commandManager,
  sharedDeviceManager,
} from '../api-networking';
import MySnackbar from '../components/sub/MySnackbar';

const components = {};

components[DASHBOARD_TITLE] = Dashboard;
components[ALL_VEHICLE_TITLE] = AllVehicles;
components[ALL_VEHICLE_IN_MAP_TITLE] = VehiclesInMap;
components[SHARED_VEHICLE_TITLE] = SharedVehicles;
components[PROFILE_TITLE] = Profile;
components[NOTIFICATIONS_TITLE] = Notifications;
components[ALERTS_TITLE] = Alerts;
components[EXPENSES_TITLE] = Expenses;
components[MONTHLY_REPORT_TITLE] = MonthlyReportSingle;
components[DAILY_TRAVEL_REPORT_TITLE] = DailyTravelReport;
components[WORKSHOP_TITLE] = WorkshopReport;

export const FirebaseContext = createContext();

const firebaseConfig = {
  apiKey: 'AIzaSyAw4aWIgsCJvc9zoISFLiZDMokjJZ2HK_8',
  authDomain: 'sino-72f9d.firebaseapp.com',
  databaseURL: 'https://sino-72f9d.firebaseio.com',
  projectId: 'sino-72f9d',
  storageBucket: 'sino-72f9d.appspot.com',
  messagingSenderId: '997737582322',
  appId: '1:997737582322:web:e0988c01c9a4e3bc0c8a6b',
  measurementId: 'G-TEDRPJLX4G',
};

const FirebaseProvider = ({ children }) => {
  const [state, setState] = useState({
    user: {},
    dbUser: null,
    authToken: null,
    error: false,
    error_msg: '',
    info: false,
    info_msg: '',
    userDevices: [],
    sharedDevices: [], // not array but result push as array
    users: [],
    selectedDevice: null,
    backStack: [Dashboard],
    foreStack: [],
    title: DASHBOARD_TITLE,
    editFormOpen: false,
    snackbarOpen: false,
    snackbarMessage: '',
    snackbarSeverity: 'success',
    settingOpen: false,
    shareDeviceOpen: false,
    sharedUsersOpen: false,
    fromSharedUser: false,
  });

  const closeShareDeviceDialog = () => {
    setState((old) => ({ ...old, shareDeviceOpen: false }));
  };

  const openShareDeviceDialog = (device) => {
    setState((old) => ({
      ...old,
      shareDeviceOpen: true,
      selectedDevice: device,
    }));
  };

  const updateUserDevice = (formData) => {
    deviceManager
      .updateDevice(state.selectedDevice.id, formData)
      .then((updatedDevice) => {
        let devices = [...state.userDevices];
        devices[devices.indexOf(state.selectedDevice)] = updatedDevice;
        setState((old) => ({
          ...old,
          userDevices: devices,
          editFormOpen: false,
        }));
      })
      .catch((err) =>
        setState((old) => ({ ...old, error: true, error_msg: err.message }))
      );
  };

  const setSnackbar = (open, message, severity, user) => {
    setState((old) => ({
      ...old,
      snackbarOpen: open,
      snackbarMessage: message,
      snackbarSeverity: severity,
      dbUser: user ? user : old.dbUser,
    }));
  };

  const handleSnakbarClose = () => {
    setState((old) => ({ ...old, snackbarOpen: false }));
  };

  const closeSettings = () => {
    setState((old) => ({ ...old, settingOpen: false }));
  };

  const openSettings = (device) => {
    setState((old) => ({ ...old, settingOpen: true, selectedDevice: device }));
  };

  useEffect(() => {
    // console.log('Use Effect Called');
    if (state.backStack[state.backStack.length - 1] === Dashboard) {
      setState((old) => ({ ...old, title: DASHBOARD_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === AllVehicles) {
      setState((old) => ({ ...old, title: ALL_VEHICLE_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === VehiclesInMap) {
      setState((old) => ({ ...old, title: ALL_VEHICLE_IN_MAP_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === SharedVehicles) {
      setState((old) => ({ ...old, title: SHARED_VEHICLE_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === Profile) {
      setState((old) => ({ ...old, title: 'Profile of ' + state.dbUser.name }));
    } else if (state.backStack[state.backStack.length - 1] === Notifications) {
      setState((old) => ({ ...old, title: NOTIFICATIONS_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === Alerts) {
      setState((old) => ({ ...old, title: ALERTS_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === Expenses) {
      setState((old) => ({ ...old, title: EXPENSES_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === MonthlyReport) {
      setState((old) => ({ ...old, title: MONTHLY_REPORT_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === WorkshopReport) {
      setState((old) => ({ ...old, title: WORKSHOP_TITLE }));
    } else if (state.backStack[state.backStack.length - 1] === LiveTracking) {
      setState((old) => ({
        ...old,
        title: state.selectedDevice
          ? 'Live Tracking of ' + state.selectedDevice.registration_number
          : 'Live Tracking',
      }));
    } else if (state.backStack[state.backStack.length - 1] === DailyReport) {
      setState((old) => ({
        ...old,
        title: 'Daily Report of ' + state.selectedDevice.registration_number,
      }));
    } else if (
      state.backStack[state.backStack.length - 1] === MonthlyReportSingle
    ) {
      setState((old) => ({
        ...old,
        title: state.selectedDevice
          ? 'Monthly Report of ' + state.selectedDevice.registration_number
          : 'Monthly Report',
      }));
    } else if (state.backStack[state.backStack.length - 1] === RouteSummery) {
      setState((old) => ({
        ...old,
        title: 'Route Summery of ' + state.selectedDevice.registration_number,
      }));
    } else if (state.backStack[state.backStack.length - 1] === SpeedReport) {
      setState((old) => ({
        ...old,
        title: 'Speed Report of ' + state.selectedDevice.registration_number,
      }));
    } else if (
      state.backStack[state.backStack.length - 1] === DailyTravelReport
    ) {
      setState((old) => ({
        ...old,
        title: state.selectedDevice
          ? 'Travel Report of ' + state.selectedDevice.registration_number
          : 'Travel Report',
      }));
    } else if (
      state.backStack[state.backStack.length - 1] === MonthlyTemperatureReport
    ) {
      setState((old) => ({
        ...old,
        title:
          'Monthly Temperature Report of ' +
          state.selectedDevice.registration_number,
      }));
    } else if (state.backStack[state.backStack.length - 1] === PTIOReport) {
      setState((old) => ({
        ...old,
        title: 'PTIO Report of ' + state.selectedDevice.registration_number,
      }));
    }
  }, [state.backStack, state.selectedDevice, state.dbUser]);

  const setEditFormOpen = (value) => {
    setState((old) => ({ ...old, editFormOpen: value }));
  };

  const closeSharedUsersDialog = () => {
    setState((old) => ({
      ...old,
      sharedUsersOpen: false,
      selectedDevice: null,
    }));
  };

  const openShareUsersDialog = (device) => {
    setState((old) => ({
      ...old,
      selectedDevice: device,
      sharedUsersOpen: true,
    }));
  };

  const onBackButtonClick = () => {
    let backStack = [...state.backStack];
    let foreStack = [...state.foreStack];
    foreStack.push(backStack.pop());
    setState((old) => ({ ...old, backStack: backStack, foreStack: foreStack }));
  };

  const onForwardButtonClick = () => {
    let backStack = [...state.backStack];
    let foreStack = [...state.foreStack];
    backStack.push(foreStack.pop());
    setState((old) => ({ ...old, backStack: backStack, foreStack: foreStack }));
  };

  const onNavItemClick = (item) => {
    if (item.title === LOGOUT_TITLE) {
      signout();
    } else {
      setState((old) => ({
        ...old,
        backStack: [...old.backStack, components[item.title]],
      }));
    }
  };

  const onMapClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, LiveTracking],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onDailyReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, DailyReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onMonthlyReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, MonthlyReportSingle],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onRouteSummeryClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, RouteSummery],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onSpeedReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, SpeedReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onDailyTravelReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, DailyTravelReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onMonthlyTemperatureReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, MonthlyTemperatureReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onPTIOReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, PTIOReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onEditClick = (device) => {
    setState((old) => ({
      ...old,
      editFormOpen: true,
      selectedDevice: device,
    }));
  };

  const sendCommandToserver = (command) => {
    commandManager
      .sendCommandToserver(command)
      .then((comm) =>
        setState((old) => ({
          ...old,
          snackbarOpen: true,
          snackbarMessage:
            'Your command is Executed Successfully. Command will be Executed within 30 seconds',
          snackbarSeverity: 'success',
        }))
      )
      .catch((err) =>
        setState((old) => ({ ...old, error: true, error_msg: err.message }))
      );
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const database = getDatabase(app);

  const closeErrorDialog = () => {
    setState((old) => ({ ...old, error: false }));
  };

  const closeInfoDialog = () => {
    setState((old) => ({ ...old, info: false }));
  };

  const getSingleDeviceDatabaseRef = (device_id) => {
    return ref(database, 'devices/' + device_id);
  };

  const updateState = (user) => {
    userManager
      .getUserByEmail(user)
      .then((dbUser) => {
        if (state.userDevices.length === 0) {
          userManager
            .getUserDevices(dbUser)
            .then((devices) =>
              setState((old) => ({
                ...old,
                userDevices: devices,
                dbUser: dbUser,
                user: user,
              }))
            )
            .catch((err) =>
              setState((old) => ({
                ...old,
                error: true,
                error_msg: err.message,
              }))
            );
        }
      })
      .catch((err) => {
        setState((old) => ({ ...old, error: true, error_msg: err.message }));
      });
  };

  const registerToServer = (user) => {
    let usr = {
      email: user.email,
      name: user.displayName ? user.displayName : '',
      contact: user.phoneNumber ? user.phoneNumber : '',
      image: user.photoURL ? user.photoURL : '',
    };

    userManager
      .registerToServer(usr)
      .then((dbUser) =>
        setState((old) => ({ ...old, user: user, dbUser: dbUser }))
      )
      .catch((err) =>
        setState((old) => ({ ...old, error: true, error_msg: err.message }))
      );
  };

  const googlePopupSignIn = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    signInWithRedirect(auth, provider);
  };

  const signUp = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        sendVerificationEmail(userCredential.user);
      })
      .catch((err) => {
        setState((old) => ({ ...old, error: true, error_msg: err.message }));
      });
  };

  const sendVerificationEmail = (user) => {
    sendEmailVerification(user)
      .then(() => {
        registerToServer(user);
      })
      .catch((err) => {
        setState((old) => ({ ...old, error: true, error_msg: err.message }));
      });
  };

  const signout = () => {
    signOut(auth)
      .then(() => {
        setState({ ...state, user: null });
      })
      .catch((err) => {
        const errorCode = err.code;
        const errorMessage = err.message;
      });
  };

  const reset_password_request = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setState((old) => ({
          ...old,
          info: true,
          info_msg:
            'Password Reset Mail has been sent to your email.Please Check..',
        }));
      })
      .catch((error) => {
        setState((old) => ({ ...old, error: true, error_msg: error.message }));
      });
  };

  const login = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        updateState(user);
      })
      .catch((error) => {
        setState((old) => ({ ...old, error: true, error_msg: error.message }));
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log('User Found');
        updateState(user);
      } else {
        // console.log('User Not Found');
        setState({ ...state, user: null });
      }
    });
  }, []);

  const setSelectedDevice = (device) => {
    // console.log('SET SELECTED Device Called', device);
    setState((old) => ({ ...old, selectedDevice: device }));
  };

  const getUserSharedDevices = () => {
    sharedDeviceManager
      .getUserSharedDevices(state.dbUser._id)
      .then((data) => setState((old) => ({ ...old, sharedDevices: data })))
      .catch((err) => console.log(err));
  };
  return (
    <FirebaseContext.Provider
      value={{
        getSingleDeviceDatabaseRef: getSingleDeviceDatabaseRef,
        signUp: signUp,
        login: login,
        signout: signout,
        user: state.user,
        dbUser: state.dbUser,
        googlePopupSignIn: googlePopupSignIn,
        reset_password_request: reset_password_request,
        userDevices: state.userDevices,
        selectedDevice: state.selectedDevice,
        sharedDevices: state.sharedDevices,
        setSelectedDevice: setSelectedDevice,
        getUserSharedDevices: getUserSharedDevices,
        onNavItemClick: onNavItemClick,
        title: state.title,
        ChildComponent: state.backStack[state.backStack.length - 1],
        backStack: state.backStack,
        foreStack: state.foreStack,
        onBackButtonClick: onBackButtonClick,
        onForwardButtonClick: onForwardButtonClick,
        onMapClick: onMapClick,
        editFormOpen: state.editFormOpen,
        setEditFormOpen: setEditFormOpen,
        onEditClick: onEditClick,
        updateUserDevice: updateUserDevice,
        settingOpen: state.settingOpen,
        openSettings: openSettings,
        closeSettings: closeSettings,
        sendCommandToserver: sendCommandToserver,
        closeShareDeviceDialog: closeShareDeviceDialog,
        shareDeviceOpen: state.shareDeviceOpen,
        openShareDeviceDialog: openShareDeviceDialog,
        users: state.users,
        setSnackbar: setSnackbar,
        sharedUsersOpen: state.sharedUsersOpen,
        closeSharedUsersDialog: closeSharedUsersDialog,
        openShareUsersDialog: openShareUsersDialog,
        onDailyReportClick: onDailyReportClick,
        onMonthlyReportClick: onMonthlyReportClick,
        onRouteSummeryClick: onRouteSummeryClick,
        onSpeedReportClick: onSpeedReportClick,
        onDailyTravelReportClick: onDailyTravelReportClick,
        fromSharedUser: state.fromSharedUser,
        onMonthlyTemperatureReportClick: onMonthlyTemperatureReportClick,
        onPTIOReportClick: onPTIOReportClick,
      }}
    >
      {children}
      <AuthErrorDialog
        open={state.error}
        handleClose={closeErrorDialog}
        message={state.error_msg}
      />
      <AuthInfoDialog
        open={state.info}
        handleClose={closeInfoDialog}
        message={state.info_msg}
      />

      <MySnackbar
        open={state.snackbarOpen}
        handleClose={handleSnakbarClose}
        message={state.snackbarMessage}
        severity={state.snackbarSeverity}
      />
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
