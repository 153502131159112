import { useState } from 'react';
import AuthenticationBase from './AuthenticationBase';
import Login from './Login';
import SignUp from './Signup';
import PasswordReset from './PasswordReset';

const Authentication = () => {
  const [state, setState] = useState({ component: 0 });

  const loadLoginPage = () => {
    setState((old) => ({ ...old, component: 0 }));
  };

  const loadSignUpPage = () => {
    setState((old) => ({ ...old, component: 1 }));
  };

  const loadPasswordResetPage = () => {
    setState((old) => ({ ...old, component: 2 }));
  };

  return (
    <AuthenticationBase>
      {state.component === 0 ? (
        <Login
          loadSignUpPage={loadSignUpPage}
          loadPasswordResetPage={loadPasswordResetPage}
        />
      ) : state.component === 1 ? (
        <SignUp loadLoginPage={loadLoginPage} />
      ) : (
        <PasswordReset loadLoginPage={loadLoginPage} />
      )}
    </AuthenticationBase>
  );
};

export default Authentication;
