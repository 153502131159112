import { Typography } from '@mui/material';
// import centerImage from '../../images/center.png';
// import bottomLeft from '../../images/bottom left corner.png';
// import topRight from '../../images/top right corner.png';
import logo from '../../images/logo.png';
import carImage from '../../images/car.svg';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { MediaQueryContext } from '../../Firebase/MediaQueryProvider';
import { PURPLE, WHITE, RED, ASH_BLACK } from '../../utils/colors';

const AuthenticationBase = ({ children }) => {
  const { isTabletOrMobile, isBigScreen } = useContext(MediaQueryContext);
  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: PURPLE,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,

          display: 'flex',
          justifyContent: 'center',
          zIndex: 200,
        }}
      >
        <Typography sx={{ color: RED }}>Copyright reserved @2023</Typography>
      </div>

      {isTabletOrMobile ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: '5%',
            left: 0,
            right: 0,
            // backgroundColor: 'yellow',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              height: '40%',
              left: 0,
              right: 0,
              backgroundColor: '#eeeeee',
            }}
          >
            <img
              src={logo}
              alt="car"
              width="20%"
              style={{ position: 'absolute', top: 10, left: 10 }}
            />
            <motion.img
              src={carImage}
              alt="car"
              style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
              initial={{ bottom: '100%' }}
              animate={{ bottom: 0 }}
              transition={{ duration: 1, type: 'spring', stiffness: 100 }}
            />
          </div>

          <motion.div
            style={{
              position: 'absolute',
              top: '25%',
              height: '60%',
              left: '3%',
              right: '3%',
              backgroundColor: 'white',
              padding: 10,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              borderRadius: 10,
              zIndex: 20,
            }}
            initial={{ top: '100%' }}
            animate={{ top: '25%' }}
            transition={{ duration: 1, type: 'spring', stiffness: 80 }}
          >
            {children}
          </motion.div>
        </div>
      ) : (
        <motion.div
          style={{
            position: 'absolute',
            top: '20%',
            bottom: '20%',
            left: '25%',
            right: '25%',
            backgroundColor: WHITE,
          }}
          animate={{
            scale: [0.8, 1.1, 1.3],
            rotate: [0, 180, 360],
            // borderRadius: ['0%', '0%', '3%', '5%', '0%'],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            times: [0, 0.2, 0.5, 0.8, 1],
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: '48%',
              // backgroundColor: RED,
            }}
          >
            <img
              src={logo}
              alt="car"
              width="30%"
              style={{ position: 'absolute', top: 20, left: 20 }}
            />
            <img
              src={carImage}
              alt="car"
              style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
            />
            <motion.div
              style={{
                position: 'absolute',
                top: '25%',
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
              whileHover={{ scale: 1.2 }}
            >
              <Typography fontSize={isBigScreen ? '1.5rem' : '1.2rem'}>
                Welcome to SultanTracker
              </Typography>
              <Typography>Login to Continue</Typography>
            </motion.div>
          </div>

          <div
            style={{
              position: 'absolute',
              right: 20,
              top: '5%',
              bottom: 0,
              left: '52%',
              // backgroundColor: GREEN,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {children}
          </div>

          <div
            style={{
              position: 'absolute',
              right: '50%',
              top: '10%',
              bottom: '10%',
              width: '1px',
              backgroundColor: ASH_BLACK,
            }}
          ></div>
        </motion.div>
      )}
    </div>
  );
};

export default AuthenticationBase;
