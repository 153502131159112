import React from 'react';
import Background from './Background';
import { Divider, Card } from '@mui/material';

const EmailVerify = () => {
  return (
    <Background>
      <Card style={{ width: '60%', height: '70%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Divider style={{ width: '100%' }}>Verify Email</Divider>
          <p>
            We Send a Verification Link to Your Email Please Verify and refresh
            this page
          </p>
        </div>
      </Card>
    </Background>
  );
};

export default EmailVerify;
