import { useState, useEffect, useContext } from 'react';
import { Typography } from '@mui/material';
import { getUpdatedTime, getAddress } from '../../../utils/utils';
import { MediaQueryContext } from '../../../Firebase/MediaQueryProvider';

const LocationPopUp = ({ location }) => {
  const [address, setAddress] = useState('');
  const { isTabletOrMobile } = useContext(MediaQueryContext);

  useEffect(() => {
    getAddress(location.geo.lat, location.geo.lng)
      .then((add) => setAddress(add))
      .catch((err) => console.log(err));
  }, [location]);
  const Item = ({ title, value }) => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between', padding: 6 }}
      >
        <Typography variant="caption">{title}</Typography>
        <Typography variant="caption" style={{ textAlign: 'right' }}>
          {value}
        </Typography>
      </div>
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        minWidth: isTabletOrMobile ? 180 : 400,
        maxWidth: isTabletOrMobile ? 400 : 800,
        padding: isTabletOrMobile ? 0 : 10,
        flexDirection: 'column',
      }}
    >
      <Item title="ID" value={location.id} />
      <Item title="IGNITION" value={location.geo.acc} />
      <Item title="SPEED" value={location.geo.speed + ' KMPH'} />
      <Item title="UPDATE TIME" value={getUpdatedTime(location.devicetime)} />
      <Item title="ADDRESS" value={address} />
    </div>
  );
};

export default LocationPopUp;
