import { Column, Table, AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import dateformat from 'dateformat';

import { grey, orange } from '@mui/material/colors';

import {
  Typography,
  Tooltip,
  Card,
  Avatar,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  getDateFromCellData,
  fuelRequired,
  formatDuration,
  getSuffix,
  formatTime,
  lastUpdate,
  stop_duration,
} from '../.../../../utils/utils';
import { getFrontView } from '../../utils/types';
import { ExpandMore, ExpandLess, MoreVert } from '@mui/icons-material';
// import ListIcon from '@mui/icons-material/List';
import { useRef, useState, useEffect, useContext } from 'react';
import { Item } from '../pages/Utils';
import { MobileContext } from '../../Firebase/MobileProvider';

const headerStyle = {
  color: orange[700],
};

const rowStyle = (index) => {
  if (index % 2 === 0) {
    return { backgroundColor: grey[100] };
  } else {
    return { backgroundColor: grey[300] };
  }
};

export const MobileVehicleList = ({ data }) => {
  const [state, setState] = useState({
    open: false,
    selectedIndex: -1,
    anchorEl: null,
    device: null,
  });

  const open = Boolean(state.anchorEl);

  const {
    onMapClick,
    onDailyReportClick,
    onMonthlyReportClick,
    onTravelReportClick,
    onSpeedReportClick,
  } = useContext(MobileContext);

  const listRef = useRef();

  useEffect(() => {
    listRef.current.recomputeRowHeights();
  }, [state.selectedIndex]);

  const getRowHeight = ({ index }) => {
    return index === state.selectedIndex
      ? data[index].device_model === 'S5E'
        ? 320
        : 260
      : 80;
  };

  const renderRow = ({ index, key, style }) => {
    // const [anchorEl, setAnchorEl] = useState()

    let item = data[index];

    // console.log(key);

    return (
      <div
        style={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
        }}
        key={key}
      >
        <Card style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              padding: 10,
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={getFrontView(item.vehicle_type)}
                alt="type"
                sx={{ backgroundColor: '#555555' }}
              />
              <div
                style={{
                  display: 'flex',
                  padding: 6,
                  flexDirection: 'column',
                }}
              >
                <Typography>{item.registration_number}</Typography>
                <Typography variant="caption">{item.geo.acc}</Typography>
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() =>
                  setState((old) => ({
                    ...old,
                    selectedIndex: index === old.selectedIndex ? -1 : index,
                  }))
                }
              >
                {state.selectedIndex === index ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>

              <IconButton
                sx={{ marginRight: 1 }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) =>
                  setState((old) => ({
                    ...old,
                    anchorEl: e.currentTarget,
                    device: item,
                  }))
                }
              >
                <MoreVert />
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={state.anchorEl}
                open={open}
                onClose={() => setState((old) => ({ ...old, anchorEl: null }))}
                // MenuListProps={{
                //   'aria-labelledby': 'basic-button',
                // }}
              >
                <MenuItem onClick={() => onMapClick(state.device, false)}>
                  Live Tracking
                </MenuItem>
                <MenuItem
                  onClick={() => onDailyReportClick(state.device, false)}
                >
                  Daily Report
                </MenuItem>
                <MenuItem
                  onClick={() => onMonthlyReportClick(state.device, false)}
                >
                  Monthly Report
                </MenuItem>
                <MenuItem
                  onClick={() => onTravelReportClick(state.device, false)}
                >
                  Travel Report
                </MenuItem>
                <MenuItem
                  onClick={() => onSpeedReportClick(state.device, false)}
                >
                  Speed Report
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Card>

        <Collapse
          in={state.selectedIndex === index}
          timeout="auto"
          unmountOnExit
        >
          <div
            style={{
              display: 'flex',
              padding: 10,
              flexDirection: 'column',
            }}
          >
            <Item title="Speed" value={item.geo && item.geo.speed} />
            <Item title="Charging" value={item.geo && item.geo.charging} />
            <Item title="Relay" value={item.geo && item.geo.fuel_line} />
            <Item
              title="Voltage level"
              value={item.geo && item.geo.voltage_level}
            />
            <Item
              title="Number of Satellite"
              value={item.geo && item.geo.number_of_satellite}
            />
            {item.device_model === 'S5E' && (
              <div>
                <Item
                  title="PTO io status"
                  value={
                    item.geo &&
                    item.geo.pto_io_status &&
                    item.geo.pto_io_status === 1
                      ? 'ON'
                      : 'OFF'
                  }
                />
                <Item
                  title="Overspeed"
                  value={
                    item.geo && item.geo.over_speed && item.geo.over_speed === 1
                      ? 'True'
                      : 'False'
                  }
                />
                <Item
                  title="Temperature"
                  value={item.geo && item.geo.temperature}
                />
              </div>
            )}

            <Item
              title="Last Update"
              value={item.geo && lastUpdate(item.geo.update_time)}
            />

            <Item
              title="Stop Duration"
              value={
                item.geo &&
                stop_duration(
                  item.geo.active_time
                    ? item.geo.active_time
                    : item.geo.update_time
                )
              }
            />
            <Item
              title="Total distance"
              value={
                item.device_model === 'S5E'
                  ? item.geo && item.geo.milage + ' KM'
                  : item.geo && (item.geo.milage / 1000).toFixed(2) + ' KM'
              }
            />
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          ref={listRef}
          width={width}
          height={height}
          rowHeight={getRowHeight}
          rowRenderer={renderRow}
          rowCount={data.length}

          // onRowClick={() => console.log('rowClick')}
        />
      )}
    </AutoSizer>
  );
};

export const MobileSharedVehicleList = ({ data }) => {
  const [state, setState] = useState({
    open: false,
    selectedIndex: -1,
    anchorEl: null,
    device: null,
  });

  const open = Boolean(state.anchorEl);

  const {
    onMapClick,
    onDailyReportClick,
    onMonthlyReportClick,
    onTravelReportClick,
    onSpeedReportClick,
  } = useContext(MobileContext);

  const listRef = useRef();

  useEffect(() => {
    listRef.current.recomputeRowHeights();
  }, [state.selectedIndex]);

  const getRowHeight = ({ index }) => {
    return index === state.selectedIndex
      ? data[index].device_model === 'S5E'
        ? 320
        : 260
      : 80;
  };

  const renderRow = ({ index, key, style }) => {
    // const [anchorEl, setAnchorEl] = useState()

    let item = data[index];

    // console.log(key);

    return (
      <div
        style={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
        }}
        key={key}
      >
        <Card style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              padding: 10,
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={getFrontView(item.vehicle_type)}
                alt="type"
                sx={{ backgroundColor: '#555555' }}
              />
              <div
                style={{
                  display: 'flex',
                  padding: 6,
                  flexDirection: 'column',
                }}
              >
                <Typography>{item.registration_number}</Typography>
                <Typography variant="caption">{item.geo.acc}</Typography>
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() =>
                  setState((old) => ({
                    ...old,
                    selectedIndex: index === old.selectedIndex ? -1 : index,
                  }))
                }
              >
                {state.selectedIndex === index ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>

              <IconButton
                sx={{ marginRight: 1 }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) =>
                  setState((old) => ({
                    ...old,
                    anchorEl: e.currentTarget,
                    device: item,
                  }))
                }
              >
                <MoreVert />
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={state.anchorEl}
                open={open}
                onClose={() => setState((old) => ({ ...old, anchorEl: null }))}
                // MenuListProps={{
                //   'aria-labelledby': 'basic-button',
                // }}
              >
                <MenuItem onClick={() => onMapClick(state.device, true)}>
                  Live Tracking
                </MenuItem>
                <MenuItem
                  onClick={() => onDailyReportClick(state.device, true)}
                >
                  Daily Report
                </MenuItem>
                <MenuItem
                  onClick={() => onMonthlyReportClick(state.device, true)}
                >
                  Monthly Report
                </MenuItem>
                <MenuItem
                  onClick={() => onTravelReportClick(state.device, true)}
                >
                  Travel Report
                </MenuItem>
                <MenuItem
                  onClick={() => onSpeedReportClick(state.device, true)}
                >
                  Speed Report
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Card>

        <Collapse
          in={state.selectedIndex === index}
          timeout="auto"
          unmountOnExit
        >
          <div
            style={{
              display: 'flex',
              padding: 10,
              flexDirection: 'column',
            }}
          >
            <Item title="Speed" value={item.geo && item.geo.speed} />
            <Item title="Charging" value={item.geo && item.geo.charging} />
            <Item title="Relay" value={item.geo && item.geo.fuel_line} />
            <Item
              title="Voltage level"
              value={item.geo && item.geo.voltage_level}
            />
            <Item
              title="Number of Satellite"
              value={item.geo && item.geo.number_of_satellite}
            />
            {item.device_model === 'S5E' && (
              <div>
                <Item
                  title="PTO io status"
                  value={
                    item.geo &&
                    item.geo.pto_io_status &&
                    item.geo.pto_io_status === 1
                      ? 'ON'
                      : 'OFF'
                  }
                />
                <Item
                  title="Overspeed"
                  value={
                    item.geo && item.geo.over_speed && item.geo.over_speed === 1
                      ? 'True'
                      : 'False'
                  }
                />
                <Item
                  title="Temperature"
                  value={item.geo && item.geo.temperature}
                />
              </div>
            )}

            <Item
              title="Last Update"
              value={item.geo && lastUpdate(item.geo.update_time)}
            />

            <Item
              title="Stop Duration"
              value={
                item.geo &&
                stop_duration(
                  item.geo.active_time
                    ? item.geo.active_time
                    : item.geo.update_time
                )
              }
            />
            <Item
              title="Total distance"
              value={
                item.device_model === 'S5E'
                  ? item.geo && item.geo.milage + ' KM'
                  : item.geo && (item.geo.milage / 1000).toFixed(2) + ' KM'
              }
            />
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          ref={listRef}
          width={width}
          height={height}
          rowHeight={getRowHeight}
          rowRenderer={renderRow}
          rowCount={data.length}

          // onRowClick={() => console.log('rowClick')}
        />
      )}
    </AutoSizer>
  );
};

export const MonthlyReportList = ({ monthly_data, device }) => {
  const listRef = useRef();

  const [state, setState] = useState({
    selectedIndex: -1,
  });

  useEffect(() => {
    listRef.current.recomputeRowHeights();
  }, [state.selectedIndex]);

  const getRowHeight = ({ index }) => {
    return index === state.selectedIndex ? 200 : 75;
  };

  const renderRow = ({ index, key, style }) => {
    let item = monthly_data[index];

    return (
      <div
        style={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
        }}
        key={key}
      >
        <Card>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: 70,
                height: 70,
                display: 'flex',
                backgroundColor: '#f48e0d',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="button" fontSize="1.2rem">
                {item._id.day}
              </Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>
                {(item.distance / 1000).toFixed(2) + ' KM'}
              </Typography>

              {/* <IconButton>
                <ReportOutlined />
              </IconButton> */}

              <IconButton
                onClick={() =>
                  setState((old) => ({
                    ...old,
                    selectedIndex: index === old.selectedIndex ? -1 : index,
                  }))
                }
              >
                {state.selectedIndex === index ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </div>
          </div>
        </Card>

        <Collapse
          in={state.selectedIndex === index}
          timeout="auto"
          unmountOnExit
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Item
              title="Start Time"
              value={dateformat(item.start_time, 'hh:MM TT')}
            />
            <Item
              title="Stop Time"
              value={dateformat(item.end_time, 'hh:MM TT')}
            />

            <Item
              title="Running Time"
              value={formatDuration(item.running_time)}
            />
            <Item title="Idle Time" value={formatDuration(item.idle_time)} />

            <Item
              title="Congestion Time"
              value={formatDuration(item.congestion_time)}
            />

            <Item
              title="Fuel Consume"
              value={fuelRequired(item.distance, item.congestion_time, device)}
            />
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          ref={listRef}
          width={width}
          height={height}
          rowHeight={getRowHeight}
          rowRenderer={renderRow}
          rowCount={monthly_data.length}
          overscanRowCount={3}
        />
      )}
    </AutoSizer>
  );
};

export const MonthlyReportTable = ({ monthly_data, device }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={monthly_data.length}
          rowGetter={({ index }) => monthly_data[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={80}
            flexGrow={1}
            label="Date"
            dataKey="_id"
            cellRenderer={({ cellData }) => (
              <Typography variant="button">
                {getDateFromCellData(cellData)}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Start"
            dataKey="start_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="button">
                {dateformat(cellData, 'hh:MM TT')}
              </Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="Stop"
            dataKey="end_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="button">
                {dateformat(cellData, 'hh:MM TT')}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Running"
            dataKey="running_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="button">
                {formatDuration(cellData)}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Idle"
            dataKey="idle_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="button">
                {formatDuration(cellData)}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Congestion"
            dataKey="congestion_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="button">
                {formatDuration(cellData)}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Distance(km)"
            dataKey="distance"
            cellRenderer={({ cellData }) => (
              <Typography variant="button">
                {(cellData / 1000).toFixed(2)}
              </Typography>
            )}
          />

          {device.mileage && (
            <Column
              width={80}
              flexGrow={1}
              label="Fuel(km)"
              dataKey="distance"
              cellRenderer={({ rowData }) => (
                <Typography variant="button">
                  {fuelRequired(
                    rowData.distance,
                    rowData.congestion_time,
                    device
                  )}
                </Typography>
              )}
            />
          )}
        </Table>
      )}
    </AutoSizer>
  );
};

export const HourlyReport = ({ hourly_report }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={hourly_report.length}
          rowGetter={({ index }) => hourly_report[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={60}
            flexGrow={2}
            label="HOUR"
            dataKey="_id"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData + 1 + getSuffix(cellData + 1) + ' Hr'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="START"
            dataKey="start"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatTime(cellData) : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="STOP"
            dataKey="end"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatTime(cellData) : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="DISTANCE"
            dataKey="distance"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? String(cellData).padEnd(2, 0) + ' KM' : '0.00 KM'}
              </Typography>
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};

export const TripReport = ({ trip_report }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={trip_report.length}
          rowGetter={({ index }) => trip_report[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={60}
            flexGrow={2}
            label="STATUS"
            dataKey="status"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData === 'OFF' ? 'STOPPED' : 'RUNNING'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="START"
            dataKey="start"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatTime(cellData) : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="STOP"
            dataKey="end"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatTime(cellData) : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="DURATION"
            dataKey="duration"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatDuration(cellData) : formatDuration(0)}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="DISTANCE"
            dataKey="distance"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? String(cellData).padEnd(2, 0) + ' KM' : '0.00 KM'}
              </Typography>
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};

export const RouteSummeryTable = ({ datas }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={datas.length}
          rowGetter={({ index }) => datas[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={80}
            flexGrow={1}
            label="Start Address"
            dataKey="start_address"
            cellRenderer={({ cellData }) => (
              <Tooltip title={cellData}>
                <Typography variant="caption">{cellData}</Typography>
              </Tooltip>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="StartAt"
            dataKey="start_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="Distance(KM)"
            dataKey="distance"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="Duration"
            dataKey="duration"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="EndAt"
            dataKey="end_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="End Address"
            dataKey="end_address"
            cellRenderer={({ cellData }) => (
              <Tooltip title={cellData}>
                <Typography variant="caption">{cellData}</Typography>
              </Tooltip>
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};
