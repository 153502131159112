import React, { useState, useContext, useEffect } from 'react';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';

import {
  Typography,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
  Chip,
} from '@mui/material';
import google_logo from '../../images/google_logo.png';
import { isValidEmail } from '../../utils/utils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MediaQueryContext } from '../../Firebase/MediaQueryProvider';

const initialState = {
  email: null,
  password: '',
};

const Login = ({ loadSignUpPage, loadPasswordResetPage }) => {
  const { login, googlePopupSignIn } = useContext(FirebaseContext);
  const { isBigScreen } = useContext(MediaQueryContext);
  const [state, setState] = useState({
    user: { ...initialState },
    error: {
      mail_valid: true,
      message: '',
      password_valid: false,
    },
    disable: true,
    showPassword: false,
  });
  const handleChange = (e) => {
    const user = { ...state.user };
    user[e.target.name] = e.target.value;
    setState((old) => ({ ...old, user: user }));
  };

  useEffect(() => {
    let valid = isValidEmail(state.user.email);
    let password_valid =
      state.user.password.length >= 8 && state.user.password.length <= 32;
    let msg = '';
    if (!valid) {
      msg = 'Email Not Valid';
    }
    setState((old) => ({
      ...old,
      error: {
        mail_valid: valid,
        message: msg,
        password_valid: password_valid,
      },
      disable: !(valid && password_valid),
    }));
  }, [state.user]);

  const handleClickShowPassword = () => {
    setState((old) => ({ ...old, showPassword: !old.showPassword }));
  };

  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        <Divider>
          <Typography>Login</Typography>
        </Divider>
      </div>

      <Typography textAlign="left" sx={{ width: '100%', marginTop: 2 }}>
        Please Login with Email and Password
      </Typography>

      <TextField
        fullWidth
        required
        label={!state.error.mail_valid ? 'Not a Valid Email' : 'Email'}
        style={{ marginTop: 10 }}
        variant="outlined"
        name="email"
        value={state.user.email}
        error={state.user.email === '' ? false : !state.error.mail_valid}
        onChange={handleChange}
        autoComplete={false}
      />

      <TextField
        fullWidth
        required
        label={
          !state.error.password_valid
            ? 'Password should contain 8 to 32 characters'
            : 'Password'
        }
        style={{ marginTop: 10 }}
        variant="outlined"
        name="password"
        type={state.showPassword ? 'text' : 'password'}
        value={state.user.password}
        error={state.user.password === '' ? false : !state.error.password_valid}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormControlLabel control={<Checkbox />} label="Remember Me" />
        <Button
          sx={{ fontSize: isBigScreen ? '1rem' : '0.8rem' }}
          variant="text"
          onClick={loadPasswordResetPage}
        >
          Forgot Password?
        </Button>
      </div>

      <Button
        disabled={state.disable}
        sx={{ width: '100%' }}
        variant="contained"
        onClick={() => login(state.user.email, state.user.password)}
      >
        Login
      </Button>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>Don't have an account?</Typography>
        <Button variant="text" onClick={loadSignUpPage}>
          Sign up
        </Button>
      </div>

      <div style={{ width: '100%' }}>
        <Divider textAlign="center">
          <Chip label="Social Media Login" color="secondary" />
        </Divider>
      </div>

      <Button
        style={{
          // width: '100%',
          display: 'flex',
          marginTop: 10,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          justifyContent: 'flex-start',
          backgroundColor: 'white',
        }}
        variant="contained"
        onClick={googlePopupSignIn}
      >
        <img src={google_logo} alt="google_logo" width={40} />
        <Typography style={{ marginLeft: 10 }} variant="caption">
          Signin with Google
        </Typography>
      </Button>
    </>
    // <>
    //   <Typography
    //     textAlign={isTabletOrMobile ? 'center' : 'start'}
    //     variant="caption"
    //     fontSize={isTabletOrMobile ? '1.2rem' : '1rem'}
    //   >
    //     Login
    //   </Typography>
    //   <Divider
    //     style={{
    //       width: isTabletOrMobile ? '100%' : '50%',
    //       backgroundColor: 'black',
    //     }}
    //   ></Divider>
    //   <Typography style={{ marginTop: isTabletOrMobile ? 10 : 30 }}>
    //     Please Login with Email and Password
    //   </Typography>

    //   <TextField
    //     fullWidth
    //     required
    //     label={!state.error.mail_valid ? 'Not a Valid Email' : 'Email'}
    //     style={{ marginTop: 10 }}
    //     variant="outlined"
    //     name="email"
    //     value={state.user.email}
    //     error={!state.error.mail_valid}
    //     onChange={handleChange}
    //   />

    //   <TextField
    //     fullWidth
    //     required
    //     label={
    //       !state.error.password_valid
    //         ? 'Password should contain 8 to 32 characters'
    //         : 'Password'
    //     }
    //     style={{ marginTop: 10 }}
    //     variant="outlined"
    //     name="password"
    //     type={state.showPassword ? 'text' : 'password'}
    //     value={state.user.password}
    //     error={!state.error.password_valid}
    //     onChange={handleChange}
    //     InputProps={{
    //       endAdornment: (
    //         <InputAdornment position="end">
    //           <IconButton
    //             aria-label="toggle password visibility"
    //             onClick={handleClickShowPassword}
    //             edge="end"
    //           >
    //             {state.showPassword ? <VisibilityOff /> : <Visibility />}
    //           </IconButton>
    //         </InputAdornment>
    //       ),
    //     }}
    //   />
    //   <div
    //     style={{
    //       display: 'flex',
    //       justifyContent: 'space-between',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <FormControlLabel control={<Checkbox />} label="Remember Me" />
    //     <Button
    //       sx={{ fontSize: isTabletOrMobile ? '0.8rem' : '1.2rem' }}
    //       variant="text"
    //       onClick={loadPasswordResetPage}
    //     >
    //       Forgot Password?
    //     </Button>
    //   </div>
    //   <Button
    //     disabled={state.disable}
    //     style={{ marginTop: 10 }}
    //     variant="contained"
    //     onClick={() => login(state.user.email, state.user.password)}
    //   >
    //     Login
    //   </Button>
    //   <div
    //     style={{
    //       display: 'flex',
    //       justifyContent: 'space-between',
    //       alignItems: 'center',
    //       marginTop: 10,
    //     }}
    //   >
    //     <Typography>Don't have an account?</Typography>
    //     <Button variant="text" onClick={loadSignUpPage}>
    //       Sign up
    //     </Button>
    //   </div>
    //   <div
    //     style={{
    //       display: 'flex',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <Typography>Or,</Typography>
    //   </div>

    //   <div
    //     style={{
    //       display: 'flex',
    //       height: 50,
    //       backgroundColor: '#09a5ef',
    //       alignItems: 'center',
    //       cursor: 'pointer',
    //     }}
    //     onClick={googlePopupSignIn}
    //   >
    //     <div
    //       style={{
    //         display: 'flex',
    //         width: 40,
    //         height: 40,
    //         marginLeft: 5,
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         backgroundColor: 'white',
    //       }}
    //     >
    //       <img src={google_logo} alt="google_logo" width={40} />
    //     </div>
    //     <Typography variant="subtitle2" style={{ marginLeft: 20 }}>
    //       Sign in with Google
    //     </Typography>
    //   </div>
    // </>
  );
};

export default Login;
