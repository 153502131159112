import {
  MONTHLY_TEMP_REPORT,
  MONTHLY_TEMP_REPORT_EXCEL,
  MONTHLY_PTIO_REPORT,
} from '../utils/url';

class TemperatureManager {
  constructor(axios, AUTHORIZATION_KEY) {
    this.axios = axios;
    this.AUTHORIZATION_KEY = AUTHORIZATION_KEY;
  }

  getMonthlyTemperatureData(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(MONTHLY_TEMP_REPORT, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  downloadTemperatureFile(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(MONTHLY_TEMP_REPORT_EXCEL, data, { responseType: 'blob' })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getPTIOReport(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(MONTHLY_PTIO_REPORT, data, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }
}

export default TemperatureManager;
