import {
  MONTHLY_LOCATIONS_URL,
  MONTHLY_FILE_URL,
  DAILY_REPORT_URL,
  ROUTE_SUMMERY_URL,
  DAILY_SPEED_URL,
  DAILY_LOCATIONS_URL,
} from '../utils/url';

class LocationManager {
  constructor(axios, AUTHORIZATION_KEY) {
    this.axios = axios;
    this.AUTHORIZATION_KEY = AUTHORIZATION_KEY;
  }

  getDeviceDailyReport(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(DAILY_REPORT_URL, data, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }

  getMonthlyData(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(MONTHLY_LOCATIONS_URL, data, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }

  downloadMonthlyFile(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(MONTHLY_FILE_URL, data, { responseType: 'blob' })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }

  getRouteSummeryData(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(ROUTE_SUMMERY_URL, data, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  }

  getDailySpeedData(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(DAILY_SPEED_URL, data, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  }

  getDailyLocations(data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(DAILY_LOCATIONS_URL, data, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }
}

export default LocationManager;
