import { useContext } from 'react';
import { Card, Divider, Typography } from '@mui/material';
import { LocalTaxi } from '@mui/icons-material';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import { Chart } from 'react-google-charts';

import { FILTER_DAY } from '../../../utils/web_utils';
import { grey } from '@mui/material/colors';
import { MediaQueryContext } from '../../../Firebase/MediaQueryProvider';

const COLORMAP = [
  { color: '#1b5e20', order: 1, key: 'VERY HIGH' },
  { color: '#2e7d32', order: 2, key: 'HIGH' },
  { color: '#4caf50', order: 3, key: 'MEDIUM' },
  { color: '#e65100', order: 4, key: 'LOW' },
  { color: '#e65100', order: 5, key: 'EXTREMELY LOW' },
  { color: '#ef5350', order: 6, key: 'VERY EXTREMELY LOW' },
  { color: '#d32f2f', order: 7, key: 'NO POWER' },
  { color: '#c62828', order: 8, key: 'INACTIVE' },
];

const DasboardCard = ({ title, value }) => {
  const { isBigScreen } = useContext(MediaQueryContext);
  return (
    <Card style={{ minWidth: 200 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 8,
          height: '100%',
        }}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="caption"
            fontSize={isBigScreen ? '1.2rem' : '1rem'}
          >
            {title}
          </Typography>
          <Typography
            variant="caption"
            fontSize={isBigScreen ? '1.2rem' : '1rem'}
          >
            {value}
          </Typography>
        </div>
        <LocalTaxi sx={{ fontSize: isBigScreen ? 40 : 30 }} color="primary" />
      </div>
    </Card>
  );
};

const VoltageLevelCard = ({ userDevices }) => {
  let connected = userDevices.filter((x) => x.geo);

  const options = {
    title: 'Voltage Level Status of all Vehicles',
    chartArea: { width: '60%' },
    hAxis: {
      title: 'Count',
      minValue: 0,
    },
    vAxis: {
      title: 'Voltage Level',
    },
    bar: { groupWidth: '80%' },
    legend: { position: 'none' },
    fontSize: 12,
    fontName: ['Poppins', 'sans-serif'].join(','),
  };

  const getData = () => {
    let result = connected.reduce(function (r, a) {
      r[a.geo.voltage_level] = r[a.geo.voltage_level] || [];
      r[a.geo.voltage_level].push(a);
      return r;
    }, Object.create(null));

    const data = [];

    for (var key in result) {
      data.push({
        key:
          key === 'undefined'
            ? 'inactive'.toUpperCase()
            : key.replace('Bettery', '').trim().toUpperCase(),
        value: result[key].length,
      });
    }

    // Group BY SUM
    const output = Array.from(
      data.reduce(
        (m, { key, value }) => m.set(key, (m.get(key) || 0) + value),
        new Map()
      ),
      ([key, value]) => ({ key, value })
    );

    let d = [
      [
        'Voltage Level',
        'Count',
        { role: 'style' },
        {
          sourceColumn: 0,
          role: 'annotation',
          type: 'string',
          calc: 'stringify',
        },
      ],
    ];
    // console.log(output);

    output.forEach((x) => {
      COLORMAP.forEach((y) => {
        if (x.key === y.key) {
          y.value = x.value;
        }
      });
    });

    COLORMAP.forEach((x) => {
      d.push([x.key, x.value, x.color, null]);
    });

    return d;
  };
  return (
    <Card square sx={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Divider sx={{ width: '100%' }}>
          <Typography variant="sohel">Voltage Level Status</Typography>
        </Divider>

        <Chart
          chartType="BarChart"
          width="100%"
          height="98%"
          data={getData()}
          options={options}
        />
      </div>
    </Card>
  );
};

const FuelCutCard = ({ userDevices }) => {
  let all = userDevices.filter((x) => x.geo);

  let inactive_count = all.filter((x) => !x.geo.fuel_line).length;

  let off_count = all.filter(
    (x) => x.geo.fuel_line && x.geo.fuel_line === 'OFF'
  ).length;
  let on_count = all.filter(
    (x) => x.geo.fuel_line && x.geo.fuel_line === 'ON'
  ).length;

  const options = {
    title: 'Relay Status',
    is3D: true,
    chartArea: { width: '60%' },
  };

  const data = [
    ['Vehicles', 'Count'],
    ['INACTIVE', inactive_count],
    ['OFF', off_count],
    ['ON', on_count],
  ];

  return (
    <Card square sx={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Divider sx={{ width: '100%' }}>
          <Typography variant="sohel">Relay Status</Typography>
        </Divider>

        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width={'90%'}
          height={'90%'}
        />
      </div>
    </Card>
  );
};

const DistanceCard = ({ userDevices }) => {
  const options = {
    title: 'Distance Travel By Vehicles',
    legend: { position: 'none' },
    colors: ['#4285F4'],
    chartArea: { width: '80%' },
    bar: { gap: 0 },
    histogram: {
      minValue: 0,
      maxValue: 50000,
    },
  };
  const data = userDevices.map((x) => [
    x.id,
    x.geo && x.geo.milage ? Number((x.geo.milage / 1000).toFixed(2)) : 0,
  ]);
  data.unshift(['ID', 'Travel Distance']);

  return (
    <Card square sx={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Divider sx={{ width: '100%' }}>
          <Typography variant="sohel">Travel Distance</Typography>
        </Divider>

        <Chart
          chartType="Histogram"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      </div>
    </Card>
  );
};

const Dashboard = () => {
  const { userDevices } = useContext(FirebaseContext);
  const { isBigScreen } = useContext(MediaQueryContext);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: grey[200],
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '25%',
          padding: 10,
          display: 'grid',
          gap: isBigScreen ? 50 : 30,
          gridTemplateColumns: 'repeat(5, 1fr)',
          gridTemplateRows: 'repeat(2, 1fr)',
        }}
      >
        <DasboardCard title="Total Vehicles" value={userDevices.length} />
        <DasboardCard
          title="Connected"
          value={userDevices.filter((dev) => dev.geo).length}
        />
        <DasboardCard
          title="Not-Connected"
          value={userDevices.filter((dev) => !dev.geo).length}
        />
        <DasboardCard
          title="Active"
          value={
            userDevices
              .filter((dev) => dev.geo)
              .filter(
                (dev) =>
                  dev.geo.update_time &&
                  Math.floor(
                    (new Date() - new Date(dev.geo.update_time)) /
                      (1000 * 60 * 60 * 24)
                  ) <= FILTER_DAY
              ).length
          }
        />
        <DasboardCard
          title="In-Active"
          value={
            userDevices
              .filter((dev) => dev.geo)
              .filter(
                (dev) =>
                  !(
                    dev.geo.update_time &&
                    Math.floor(
                      (new Date() - new Date(dev.geo.update_time)) /
                        (1000 * 60 * 60 * 24)
                    ) <= FILTER_DAY
                  )
              ).length
          }
        />

        <DasboardCard
          title="Running Vehicles"
          value={
            userDevices.filter((dev) => dev.geo && dev.geo.acc === 'ON').length
          }
        />
        <DasboardCard
          title="Stopped Vehicles"
          value={
            userDevices.filter((dev) => dev.geo && dev.geo.acc === 'OFF').length
          }
        />
      </div>
      <Card
        sx={{
          height: '1%',
          position: 'absolute',
          left: 10,
          right: 10,
          backgroundColor: '#f48e0d',
          top: '25%',
        }}
      ></Card>
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '74%',

          padding: 10,
          display: 'flex',
          gap: 10,
        }}
      >
        <VoltageLevelCard userDevices={userDevices} />
        <FuelCutCard userDevices={userDevices} />
        <DistanceCard userDevices={userDevices} />
      </div>
    </div>
  );
};

export default Dashboard;
