import axios from 'axios';
import { AUTHORIZATION_KEY } from '../utils/web_utils';

import DeviceManager from './device';
import UserManager from './user';
import CommandManager from './command';
import SharedDeviceManager from './share_device';
import SharedUsersManager from './shared_users';
import LocationManager from './location';
import TemperatureManager from './temperature';
import AlertManager from './alert';
import NotificationManager from './notification';

export const deviceManager = new DeviceManager(axios, AUTHORIZATION_KEY);
export const userManager = new UserManager(axios, AUTHORIZATION_KEY);
export const commandManager = new CommandManager(axios, AUTHORIZATION_KEY);
export const sharedDeviceManager = new SharedDeviceManager(
  axios,
  AUTHORIZATION_KEY
);

export const sharedUsersManager = new SharedUsersManager(
  axios,
  AUTHORIZATION_KEY
);

export const locationManager = new LocationManager(axios, AUTHORIZATION_KEY);
export const temperatureManager = new TemperatureManager(
  axios,
  AUTHORIZATION_KEY
);

export const alertManager = new AlertManager(axios, AUTHORIZATION_KEY);
export const notificationManager = new NotificationManager(
  axios,
  AUTHORIZATION_KEY
);
