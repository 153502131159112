
import React, { createContext } from "react";

import { useMediaQuery } from 'react-responsive'



export const MediaQueryContext = createContext();

const MediaQueryProvider=({children})=>{

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



      return(
        <MediaQueryContext.Provider value={{isDesktopOrLaptop,isBigScreen,isTabletOrMobile,isPortrait,isRetina}}>
            {children}
        </MediaQueryContext.Provider>
      )

}

export default MediaQueryProvider