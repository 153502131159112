import { Card, TextField, Button, Avatar, Typography } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import { userManager } from '../../../api-networking';

const Profile = () => {
  const { dbUser, setSnackbar } = useContext(FirebaseContext);

  const [state, setState] = useState({
    user: { ...dbUser },
    error: {
      name_valid: false,
      phone_valid: false,
      organization_valid: false,
      address_valid: false,
    },
    disable: true,
    file: null,
  });

  useEffect(() => {
    let name_valid =
      state.user && state.user.name && state.user.name.length > 6;

    let phone_valid =
      state.user && state.user.contact && state.user.contact.length > 10;

    let organization_valid =
      state.user &&
      state.user.organization_name &&
      state.user.organization_name.length > 6;

    let address_valid =
      state.user && state.user.address && state.user.address.length > 6;

    setState((old) => ({
      ...old,
      error: {
        name_valid: name_valid,
        phone_valid: phone_valid,
        organization_valid: organization_valid,
        address_valid: address_valid,
      },

      disable: !(
        name_valid &&
        phone_valid &&
        organization_valid &&
        address_valid
      ),
    }));
  }, [state.user]);

  const handleChange = (e) => {
    const user = { ...state.user };
    user[e.target.name] = e.target.value;
    setState((old) => ({ ...old, user: user }));
  };

  const handleSave = (e) => {
    const formData = new FormData();

    const { name, email, contact, organization_name, address } = state.user;

    formData.append('name', name);
    formData.append('email', email);
    formData.append('contact', contact);
    formData.append('organization_name', organization_name);
    formData.append('address', address);
    if (state.file) {
      formData.append('image', state.file);
    }

    userManager
      .updateUser(formData, dbUser._id)
      .then((data) => {
        setSnackbar(true, 'User Updated', 'success', data);
      })
      .catch((err) => {
        setSnackbar(true, 'User Update Failed', 'error');
        console.log(err);
      });
  };

  const onFileSelect = (e) => {
    setState((old) => ({
      ...old,
      file: e.target.files[0],
      user: {
        ...old.user,
        image: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: 10,
        display: 'flex',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginRight: 10,
        }}
      >
        <Card style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: 20,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ paddingTop: 2, paddingBottom: 3 }}>
              Fillup the form Properly to update User
            </Typography>
            <TextField
              label={
                !state.error.name_valid
                  ? 'Name Should Contain 3 and more Character'
                  : 'User Name'
              }
              name="name"
              variant="outlined"
              onChange={handleChange}
              value={state.user.name}
              fullWidth
              style={{ marginTop: 10 }}
              required
            />
            <TextField
              // error={err.error && err.fieldId === 2}
              // label={err.error && err.fieldId === 2 ? err.message : "Email"}
              name="email"
              variant="outlined"
              onChange={handleChange}
              value={state.user.email}
              fullWidth
              style={{ marginTop: 10 }}
              disabled
            />
            <TextField
              label={
                !state.error.phone_valid
                  ? 'Phone Number Not Vaild'
                  : 'User Contact'
              }
              // error={err.error && err.fieldId === 3}
              // label={err.error && err.fieldId === 3 ? err.message : "Contact"}
              name="contact"
              variant="outlined"
              onChange={handleChange}
              value={state.user.contact}
              fullWidth
              style={{ marginTop: 10 }}
              required
            />

            <TextField
              // error={err.error && err.fieldId === 4}
              // label={
              //   err.error && err.fieldId === 4
              //     ? err.message
              //     : "Company/Organization"
              // }
              label={
                !state.error.organization_valid
                  ? 'Should Contain 6 ro more Character'
                  : 'Organization Name'
              }
              name="organization_name"
              variant="outlined"
              onChange={handleChange}
              value={state.user.organization_name}
              fullWidth
              style={{ marginTop: 10 }}
              required
            />

            <TextField
              // error={err.error && err.fieldId === 5}
              // label={err.error && err.fieldId === 5 ? err.message : "Address"}
              label={
                !state.error.address_valid
                  ? 'Should Contain 6 ro more Character'
                  : 'Address'
              }
              name="address"
              variant="outlined"
              onChange={handleChange}
              value={state.user.address}
              fullWidth
              style={{ marginTop: 10 }}
              required
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <div></div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Card raised>
                  <Avatar
                    variant="square"
                    src={state.user.image}
                    alt="user_photo"
                    style={{ width: 120, height: 120 }}
                  />
                </Card>

                <label htmlFor="upload-photo" style={{ marginTop: 10 }}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={onFileSelect}
                  />

                  <Button color="primary" variant="contained" component="span">
                    Browse File
                  </Button>
                </label>
              </div>
              <Button
                disabled={state.disable}
                color="secondary"
                onClick={handleSave}
              >
                Update
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginLeft: 10,
        }}
      >
        <Card style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: 20,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Avatar
                src={state.user.image}
                alt={state.user.name}
                style={{ width: 80, height: 80 }}
              />
              <Typography variant="h6">{state.user.name}</Typography>
              <Typography variant="body1">{state.user.email}</Typography>
            </div>

            <Typography variant="body2" marginTop={2}>
              Contact:
            </Typography>
            <Typography variant="h6">{state.user.contact}</Typography>

            <Typography variant="body2" marginTop={2}>
              Company/Organization:
            </Typography>
            <Typography variant="h6">{state.user.organization_name}</Typography>
            <Typography variant="body2" marginTop={2}>
              Address:
            </Typography>
            <Typography variant="h6">{state.user.address}</Typography>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Profile;
