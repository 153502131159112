import React, { useContext, useState, useRef, useEffect } from 'react';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import {
  Slide,
  Dialog,
  Button,
  Avatar,
  Typography,
  IconButton,
  Card,
  ListItem,
  Tooltip,
} from '@mui/material';

import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

import { DeleteOutline } from '@mui/icons-material';
import { sharedUsersManager } from '../../../api-networking';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SharedUsersDialog = () => {
  const { selectedDevice, sharedUsersOpen, closeSharedUsersDialog } =
    useContext(FirebaseContext);

  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true,
    })
  );

  const [state, setState] = useState({
    users: [],
  });

  useEffect(() => {
    if (selectedDevice) {
      sharedUsersManager
        .getSharedUsers(selectedDevice)
        .then((users) => setState((old) => ({ ...old, users: users })))
        .catch((err) => console.log(err));
    }
  }, [selectedDevice]);

  const onDeleteClick = (sharedUser) => {
    sharedUsersManager
      .deleteSharedUser(sharedUser)
      .then(() => {
        setState((old) => ({
          ...old,
          users: [...old.users].filter((x) => x._id !== sharedUser._id),
        }));
      })
      .catch((err) => console.log(err));
  };

  return (
    <Dialog
      open={sharedUsersOpen}
      onClose={closeSharedUsersDialog}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <Card style={{ width: 500, height: 500 }}>
        <div
          style={{
            padding: 10,
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="sohel2">
            Shared Users of{' '}
            {selectedDevice && selectedDevice.registration_number}
          </Typography>

          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={70}
                  rowCount={state.users.length}
                  rowRenderer={({ key, index, style, parent }) => {
                    const user = state.users[index];
                    // console.log(user);
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cache.current}
                        rowIndex={index}
                        parent={parent}
                      >
                        <div style={{ ...style, cursor: 'pointer' }}>
                          <ListItem button key={index} divider>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Avatar src={user.user.image} />
                              <div
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                  flexDirection: 'column',
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                              >
                                <Typography variant="body1">
                                  {user.user.name}
                                </Typography>
                                <Typography variant="body1">
                                  {user.user.contact}
                                </Typography>
                              </div>
                              <Tooltip title="Delete Shared User">
                                <IconButton onClick={() => onDeleteClick(user)}>
                                  <DeleteOutline />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </ListItem>
                        </div>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button onClick={closeSharedUsersDialog}>Hide</Button>
          </div>
        </div>
      </Card>
    </Dialog>
  );
};

export default SharedUsersDialog;
