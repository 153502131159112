import {
  Card,
  Divider,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';

import {
  LocalGasStation,
  RoundaboutRight,
  AccessTime,
  BarChart,
  LocalShipping,
} from '@mui/icons-material';
import { MobileContext } from '../../Firebase/MobileProvider';
import { locationManager } from '../../api-networking';

const getRunningTime = (time) => {
  let hr = Math.floor(time / 3600);
  let min = Math.ceil((time % 3600) / 60);

  return hr + ' Hrs ' + min + ' Min';
};

const timeFormat = (time) => {
  let arr = time.split(':');
  let hr = arr[0];
  let min = arr[1];
  let suffix = hr >= 12 ? 'PM' : 'AM';

  if (Number(hr) === 0) {
    hr = 12;
  } else if (Number(hr) > 12) {
    hr = hr - 12;
  }

  return (
    String(hr).padStart(2, 0) + ':' + String(min).padStart(2, 0) + ' ' + suffix
  );
};

const Item = ({ Icon, value }) => {
  return (
    <Card>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 6,
          justifyContent: 'center',
          minWidth: 80,
          alignItems: 'center',
        }}
      >
        <Icon fontSize="large" />
        <Divider sx={{ width: '80%' }} />
        <Typography variant="caption" sx={{ fontSize: 12 }}>
          {value}
        </Typography>
      </div>
    </Card>
  );
};

const getSuffix = (n) => {
  if (n >= 11 && n <= 13) {
    return 'th';
  }
  switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const MobileDailyReport = () => {
  const { selectedDevice, selectedDate } = useContext(MobileContext);
  const [state, setState] = useState({
    value: 0,
    Component: null,
    total_distance: 0,
    running_time: 0,
    trip_report: [],
    hourly_report: [],
  });

  const TripReport = () => {
    const renderRow = ({ index, key, style }) => {
      const item = state.trip_report[index];
      return (
        <div
          style={{
            ...style,
            display: 'flex',
            flexDirection: 'column',
          }}
          key={key}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" style={{ flex: 1 }}>
              {item.status}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
              <Typography variant="caption">
                {timeFormat(item.start)}
              </Typography>
              <Typography variant="caption">{timeFormat(item.end)}</Typography>
            </div>

            <Typography variant="caption" style={{ flex: 2 }}>
              {getRunningTime(item.duration)}
            </Typography>
            <Typography variant="caption" textAlign="right" style={{ flex: 1 }}>
              {item.distance.toFixed(2) + ' KM'}
            </Typography>
          </div>

          <Divider />
        </div>
      );
    };
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Typography textAlign="center">Trip Report</Typography>
        <Divider
          sx={{
            width: '100%',
            backgroundColor: '#f48e0d',
            textAlign: 'center',
            borderWidth: 2,
          }}
        />
        <div style={{ width: '100%', flex: 1 }}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={50}
                rowRenderer={renderRow}
                rowCount={state.trip_report.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  };

  const HourlyReport = () => {
    const renderRow = ({ index, key, style }) => {
      const item = state.hourly_report[index];

      // console.log(item);
      return (
        <div
          style={{
            ...style,
            display: 'flex',
            flexDirection: 'column',
          }}
          key={key}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" style={{ flex: 1 }}>
              {item._id + 1 + getSuffix(item._id + 1) + ' Hr'}
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant="caption">
                {timeFormat(item.start)}
              </Typography>
              <Typography variant="caption">{timeFormat(item.end)}</Typography>
            </div>

            <Typography variant="caption" textAlign="right" style={{ flex: 1 }}>
              {item.distance.toFixed(2) + ' KM'}
            </Typography>
          </div>

          <Divider />
        </div>
      );
    };

    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Typography textAlign="center">Hourly Report</Typography>
        <Divider
          sx={{
            width: '100%',
            backgroundColor: '#f48e0d',
            textAlign: 'center',
            borderWidth: 2,
          }}
        />
        <div style={{ width: '100%', flex: 1 }}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={50}
                rowRenderer={renderRow}
                rowCount={state.hourly_report.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  };

  const MyBarChart = () => {
    const barData = [['Hr', 'Distance']].concat(
      state.hourly_report.map((x) => [
        x._id + 1 + getSuffix(x._id + 1) + ' Hr',
        x.distance,
      ])
    );

    const barOptions = {
      chart: {
        title: 'Hourly Distance',
      },
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      colors: ['#f48e0d'],
      hAxis: {
        title: 'Hr',
        slantedTextAngle: 90,
      },
      vAxis: {
        title: 'Distance',
      },
      legend: { position: 'none' },
    };

    return (
      <div style={{ width: '100%', height: '100%', padding: 10 }}>
        {state.hourly_report.length > 0 && (
          <Chart
            chartType="Bar"
            width="100%"
            height="90%"
            data={barData}
            options={barOptions}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (selectedDate && selectedDevice) {
      let data = {
        device_id: selectedDevice.id,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
        day: selectedDate.getDate(),
        device_type: selectedDevice.vehicle_type,
      };

      locationManager
        .getDeviceDailyReport(data)
        .then((d) => {
          // console.log(d);
          setState((old) => ({
            ...old,
            trip_report: d.trip_report,
            hourly_report: d.hourly_report,
            total_distance: d.total_distance,
            running_time: d.running_time,
          }));
        })
        .catch((err) => {
          setState((old) => ({ ...old }));
        });
    }
  }, [selectedDate, selectedDevice]);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ flex: 2, backgroundColor: '#f48e0d' }}></div>
        <div style={{ flex: 3, backgroundColor: '#eeeeee' }}></div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          zIndex: 100,
          position: 'absolute',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
          }}
        >
          <Item Icon={LocalShipping} value={state.total_distance + ' KM'} />
          <Item Icon={AccessTime} value={getRunningTime(state.running_time)} />
          <Item
            Icon={LocalGasStation}
            value={
              selectedDevice.mileage
                ? (state.total_distance / selectedDevice.mileage).toFixed(2)
                : 'Set Milage'
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            padding: 10,
          }}
        >
          <Card style={{ width: '100%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                height: '100%',
                padding: 10,
              }}
            >
              {state.value === 0 ? (
                <TripReport />
              ) : state.value === 1 ? (
                <HourlyReport />
              ) : (
                <MyBarChart />
              )}
            </div>
          </Card>
        </div>

        <BottomNavigation
          showLabels
          value={state.value}
          onChange={(event, newValue) => {
            setState((old) => ({ ...old, value: newValue }));
          }}
        >
          <BottomNavigationAction
            label="Trip Report"
            icon={<RoundaboutRight />}
          />
          <BottomNavigationAction label="Hourly" icon={<AccessTime />} />
          <BottomNavigationAction label="Chart" icon={<BarChart />} />
        </BottomNavigation>
      </div>
    </div>
  );
};

export default MobileDailyReport;
