import { Card, Typography } from '@mui/material';

const MyCard = ({ title, value }) => {
  return (
    <Card>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 120,
        }}
      >
        <Typography variant="button">{title}</Typography>
        <div
          style={{ width: '100%', height: 2, backgroundColor: '#F18905' }}
        ></div>
        <Typography variant="button">{value}</Typography>
      </div>
    </Card>
  );
};

export default MyCard;
