import { useContext, useState, useEffect } from 'react';
import { Grid, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

import { chunks } from '../../../utils/web_utils';
import { downloadDevicesFile } from '../../../utils/download';
import { LocalTaxi, Search, Clear, List, Download } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { deviceFilter } from '../../../utils/utils';

import {
  Typography,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import SharedDeviceItem from '../../sub/SharedDeviceItem';

const SharedVehicles = () => {
  const { sharedDevices, getUserSharedDevices } = useContext(FirebaseContext);

  const [state, setState] = useState({
    search: '',
    chunkSize: 4,
    list: [],
  });

  const handleChange = (e) => {
    setState((old) => ({ ...old, search: e.target.value }));
  };

  const getSeachedDevices = () => {
    if (state.list[0]) {
      return state.list.length * state.list[0].length <= sharedDevices.length
        ? state.list.length * state.list[0].length
        : sharedDevices.length;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (sharedDevices.length === 0) {
      getUserSharedDevices();
    }
  }, []);

  useEffect(() => {
    if (state.search === '') {
      setState((old) => ({
        ...old,
        list: chunks([...sharedDevices], old.chunkSize),
      }));
    } else {
      setState((old) => ({
        ...old,
        list: chunks(
          [...sharedDevices].filter((x) => deviceFilter(x, state.search)),
          old.chunkSize
        ),
      }));
    }
  }, [state.search, sharedDevices]);

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    return (
      <div
        key={key}
        style={{
          ...style,
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {state.list[rowIndex][columnIndex] && (
          <SharedDeviceItem device={state.list[rowIndex][columnIndex]} />
        )}
      </div>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#f2f2f2',
        padding: 10,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',

            marginRight: 25,
            marginLeft: 25,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              border: '1px solid grey',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="sohel2">Total Devices</Typography>
              <Typography variant="body2">{sharedDevices.length}</Typography>
            </div>
            <LocalTaxi sx={{ fontSize: 30 }} color="primary" />
          </div>

          <div
            style={{
              border: '1px solid grey',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 5,
              padding: 10,
              marginLeft: 10,
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="sohel2">Searched Devices</Typography>
              <Typography variant="body2">{getSeachedDevices()}</Typography>
            </div>
            <LocalTaxi sx={{ fontSize: 30 }} color="primary" />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              backgroundColor: 'white',
              marginLeft: 25,
              marginRight: 30,
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 20,
              paddingRight: 20,
              border: '1px solid grey',
              borderRadius: 5,
            }}
          >
            <TextField
              style={{ flex: 1 }}
              placeholder="Search"
              onChange={handleChange}
              name="search"
              value={state.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: 'pointer',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setState((old) => ({ ...old, search: '' }))
                      }
                    >
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ display: 'flex' }}>
              <Tooltip title="list">
                <IconButton>
                  <List />
                </IconButton>
              </Tooltip>

              <Tooltip title="Download">
                <IconButton
                  onClick={() =>
                    downloadDevicesFile(
                      sharedDevices.map((x) => ({
                        id: x.id,
                        registration_number: x.registration_number
                          ? x.registration_number
                          : '',
                        chasis_number: x.chasis_number ? x.chasis_number : '',
                        vehicle_model: x.vehicle_model ? x.vehicle_model : '',
                        code: x.code ? x.code : '',
                        driver_name: x.driver_name ? x.driver_name : '',
                        driver_phone: x.driver_phone ? x.driver_phone : '',
                        update_time: x.geo ? x.geo.update_time : '',
                      }))
                    )
                  }
                >
                  <Download />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 5,
            width: '100%',
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <Grid
                cellRenderer={cellRenderer}
                columnCount={state.list[0] ? state.list[0].length : 0}
                columnWidth={width / 4 - 4}
                height={height}
                rowCount={state.list.length}
                rowHeight={300}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};

export default SharedVehicles;
