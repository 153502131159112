import { COMMAND_URL } from '../utils/url';

class CommandManager {
  constructor(axios, AUTHORIZATION_KEY) {
    this.axios = axios;
    this.AUTHORIZATION_KEY = AUTHORIZATION_KEY;
  }

  sendCommandToserver(command) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(COMMAND_URL, command)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }
}

export default CommandManager;

// {
//     setVisible(false);
//     setSnacState({
//       open: true,
//       message:
//         'Your command is Executed Successfully. Command will be Executed within 30 seconds',
//     });
//   }
