import { useState, useContext, useEffect } from 'react';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';
import {
  Typography,
  Button,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { isValidEmail } from '../../utils/utils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MediaQueryContext } from '../../Firebase/MediaQueryProvider';

const initialState = {
  email: '',
  password: '',
  confirm_password: '',
};

const SignUp = ({ loadLoginPage }) => {
  const { signUp } = useContext(FirebaseContext);
  const { isTabletOrMobile } = useContext(MediaQueryContext);

  //   console.log(context);
  const [state, setState] = useState({
    user: { ...initialState },
    error: {
      mail_valid: false,
      message: '',
      password_valid: false,
      confirm_password_valid: false,
    },
    disable: true,
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleChange = (e) => {
    const user = { ...state.user };
    user[e.target.name] = e.target.value;
    setState((old) => ({ ...old, user: user }));
  };

  useEffect(() => {
    let valid = isValidEmail(state.user.email);
    let password_valid =
      state.user.password.length >= 8 && state.user.password.length <= 32;
    let confirm_password_valid =
      state.user.password === state.user.confirm_password;
    let msg = '';
    if (!valid) {
      msg = 'Email Not Valid';
    }
    setState((old) => ({
      ...old,
      error: {
        mail_valid: valid,
        message: msg,
        password_valid: password_valid,
        confirm_password_valid: confirm_password_valid,
      },
      disable: !(valid && password_valid && confirm_password_valid),
    }));
  }, [state.user]);

  const handleSignUp = (e) => {
    signUp(state.user.email, state.user.password);
  };

  const handleClickShowPassword = () => {
    setState((old) => ({ ...old, showPassword: !old.showPassword }));
  };

  const handleClickShowConfirmPassword = () => {
    setState((old) => ({
      ...old,
      showConfirmPassword: !old.showConfirmPassword,
    }));
  };

  return (
    <>
      <Typography
        textAlign={isTabletOrMobile ? 'center' : 'start'}
        variant="caption"
        fontSize={isTabletOrMobile ? '1.2rem' : '1rem'}
      >
        Sign Up
      </Typography>
      <Divider
        style={{
          width: isTabletOrMobile ? '100%' : '50%',
          backgroundColor: 'black',
        }}
      ></Divider>
      <Typography style={{ marginTop: isTabletOrMobile ? 10 : 30 }}>
        Create an account with Email and Password
      </Typography>

      <TextField
        fullWidth
        required
        label={!state.error.mail_valid ? 'Not a Valid Email' : 'Email'}
        style={{ marginTop: 10 }}
        variant="outlined"
        name="email"
        value={state.user.email}
        error={!state.error.mail_valid}
        onChange={handleChange}
      />

      <TextField
        fullWidth
        required
        label={
          !state.error.password_valid
            ? 'Password should contain 8 to 32 characters'
            : 'Password'
        }
        style={{ marginTop: 10 }}
        variant="outlined"
        name="password"
        type={state.showPassword ? 'text' : 'password'}
        value={state.user.password}
        error={!state.error.password_valid}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        required
        label={
          !state.error.confirm_password_valid
            ? 'Password not match'
            : 'Confirm Password'
        }
        style={{ marginTop: 10 }}
        variant="outlined"
        name="confirm_password"
        type={state.showConfirmPassword ? 'text' : 'password'}
        value={state.user.confirm_password}
        error={!state.error.confirm_password_valid}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
        style={{ marginTop: 10 }}
        variant="contained"
        onClick={handleSignUp}
        disabled={state.disable}
      >
        Sign up
      </Button>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <Typography>Already have an account?</Typography>
        <Button variant="text" onClick={loadLoginPage}>
          Login
        </Button>
      </div>
    </>
  );
};

export default SignUp;
