import {
  Dashboard,
  LocalTaxi,
  Person3,
  NotificationsNone,
  TaxiAlert,
  Receipt,
  Subject,
  CarRepair,
  Logout,
} from '@mui/icons-material';
import { red, green, grey } from '@mui/material/colors';

export const FILTER_DAY = 7;

export const DASHBOARD_TITLE = 'Dashboard';
export const ALL_VEHICLE_TITLE = 'All Vehicles';
export const ALL_VEHICLE_IN_MAP_TITLE = 'Vehicles in Map';
export const SHARED_VEHICLE_TITLE = 'Shared Vehicles';
export const PROFILE_TITLE = 'Profile';
export const NOTIFICATIONS_TITLE = 'Notifications';
export const ALERTS_TITLE = 'Alerts';
export const EXPENSES_TITLE = 'Expenses';
export const MONTHLY_REPORT_TITLE = 'Monthly Report';
export const DAILY_TRAVEL_REPORT_TITLE = 'Daily Travel Report';
export const WORKSHOP_TITLE = 'Workshop Report';
export const LOGOUT_TITLE = 'Logout';
export const LIVE_TRACKING_TITLE = 'Live Tracking';

export const NOT_CONNECTED = 'NOT CONNECTED';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

export const REFERENCE_LIST = [
  '',
  'Others',
  'Mahindra-Karnaphuli',
  'Abid',
  'Enam Mama',
  'Rangs Motors',
  'Rangpur Office',
  'Dhaka Office',
  'Al Adil Corporation',
  'Asad Vai',
  'Facebook',
];
export const DEVICE_TYPE_LIST = [
  'GT06',
  'WeTrack Two',
  'SinoTrack',
  'S102A',
  'S06A',
  'S5E',
  'R12L',
  'TK303',
  'TK304',
  'TK006',
  'QS109',
  'QSVOICE',
];
export const VEHICLE_TYPE = [
  { name: 'Car', value: 1 },
  { name: 'Bike', value: 2 },
  { name: 'Micro-Bus', value: 3 },
  { name: 'Bus', value: 4 },
  { name: 'Truck', value: 5 },
  { name: 'CNG', value: 6 },
  { name: 'Ship', value: 7 },
  { name: 'Tractor', value: 8 },
  { name: 'Others', value: 9 },
];

export const getCarType = (val) => {
  switch (val) {
    case 1:
      return 'Car';
    case 2:
      return 'Bike';
    case 3:
      return 'Micro-Bus';
    case 4:
      return 'Bus';
    case 5:
      return 'Truck';
    case 6:
      return 'CNG';
    case 7:
      return 'Ship';
    case 8:
      return 'Tractor';
    case 9:
      return 'Others';
    default:
      return 'Car';
  }
};

const workshops = [
  'BOGURA WORKSHOP',
  'CHITTAGONG WORKSHOP',
  'JASHORE WORKSHOP',
  'MYMENSINGH WORKSHOP',
  'GAZIPUR WORKSHOP',
  'TANGAIL WORKSHOP',
  'RANGPUR WORKSHOP',
  'SYLHET WORKSHOP',
  'SONARGAON WORKSHOP',
  'TEJGAON WORKSHOP',
  'CUMILLA WORKSHOP',
  'HOBIGANJ WORKSHOP',
  'THAKURGAON WORKSHOP',
  'BARISHAL WORKSHOP',
  'MANIKGANJ WORKSHOP',
  'SATKHIRA WORKSHOP',
  'FARIDPUR WORKSHOP',
  'DINAJPUR WORKSHOP',
  'SIRAJGANJ WORKSHOP',
  'KUSHTIA WORKSHOP',
  'KHULNA WORKSHOP',
  'OTHERS',
  'BIRULIA',
  'JATRABARI',
  'NARSINGDI',
  'MIRPUR-1',
  'HEAD OFFICE',
  '',
];

const stop_duration = (dateStr) => {
  let updateTime = new Date(dateStr);
  let currentDate = new Date();
  let diff = Math.floor((currentDate.getTime() - updateTime.getTime()) / 1000);

  if (diff <= 30) {
    return 'RUNNING';
  } else if (diff > 30 && diff < 60) {
    return diff + ' seconds ago';
  } else if (diff > 60 && diff < 3600) {
    let min = Math.floor(diff / 60);
    let sec = diff % 60;
    return min + ' min ' + sec + ' secs ago';
  } else if (diff > 3600 && diff < 3600 * 24) {
    let hr = Math.floor(diff / 3600);
    let rem = diff % 3600;
    let min = Math.floor(rem / 60);
    let sec = rem % 60;
    return hr + ' hrs ' + min + ' min ' + sec + ' secs ago';
  } else {
    let day = Math.floor(diff / (3600 * 24));
    let hrRem = diff % (3600 * 24);
    let hr = Math.floor(hrRem / 3600);
    let rem = hrRem % 3600;
    let min = Math.floor(rem / 60);
    let sec = rem % 60;
    return day + ' days ' + hr + ' hrs ' + min + ' mins ago';
  }

  // return diff
};

export const NAVITEMS = [
  { title: DASHBOARD_TITLE, icon: Dashboard },
  { title: ALL_VEHICLE_TITLE, icon: LocalTaxi },
  { title: SHARED_VEHICLE_TITLE, icon: LocalTaxi },
  { title: PROFILE_TITLE, icon: Person3 },
  { title: NOTIFICATIONS_TITLE, icon: NotificationsNone },
  { title: ALERTS_TITLE, icon: TaxiAlert },
  { title: EXPENSES_TITLE, icon: Receipt },
  { title: MONTHLY_REPORT_TITLE, icon: Subject },
  { title: DAILY_TRAVEL_REPORT_TITLE, icon: Subject },
  { title: WORKSHOP_TITLE, icon: CarRepair },
  { title: LOGOUT_TITLE, icon: Logout },
];

export const get_workshops = () => {
  return workshops.sort();
};

export const getDeviceStateColor = (device) => {
  if (device.geo) {
    if (
      device.geo.update_time &&
      Math.floor(
        (new Date() - new Date(device.geo.update_time)) / (1000 * 60 * 60 * 24)
      ) <= FILTER_DAY
    ) {
      return green[500];
    } else {
      return red[500];
    }
  } else {
    return grey[500];
  }
};

export const getDeviceState = (device) => {
  if (device.geo) {
    if (
      device.geo.update_time &&
      Math.floor(
        (new Date() - new Date(device.geo.update_time)) / (1000 * 60 * 60 * 24)
      ) <= FILTER_DAY
    ) {
      return ACTIVE;
    } else {
      return INACTIVE;
    }
  } else {
    return NOT_CONNECTED;
  }
};

export const getStopDuration = (device) => {
  if (device.geo) {
    let time = () => {
      if (device.geo.active_time) {
        return device.geo.active_time;
      } else {
        return device.geo.update_time;
      }
    };

    let t = time();

    return t ? stop_duration(t) : 'undefined';
  } else {
    return 'Not Connected to server';
  }
};

export const chunks = (a, size) =>
  Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
    a.slice(i * size, i * size + size)
  );

export const getActiveCount = (devices) => {
  return devices
    .filter((dev) => dev.geo)
    .filter(
      (dev) =>
        dev.geo.update_time &&
        Math.floor(
          (new Date() - new Date(dev.geo.update_time)) / (1000 * 60 * 60 * 24)
        ) <= FILTER_DAY
    ).length;
};

export const getInactiveCount = (devices) => {
  return devices
    .filter((dev) => dev.geo)
    .filter(
      (dev) =>
        dev.geo.update_time &&
        Math.floor(
          (new Date() - new Date(dev.geo.update_time)) / (1000 * 60 * 60 * 24)
        ) > FILTER_DAY
    ).length;
};

export const getRunningCount = (devices) => {
  return devices.filter((dev) => dev.geo && dev.geo.acc === 'ON').length;
};

export const getStoppedCount = (devices) =>
  devices.filter((dev) => dev.geo && dev.geo.acc === 'OFF').length;

export const MAP_API_KEY = 'AIzaSyDqLAok8VFxErXTvGp_uoWZFRygnOeILoQ';

export const AUTHORIZATION_KEY = 'BEARER ####rangs.sultantrack.com!!!!$$###';
