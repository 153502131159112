export const BLACK = '#000000';
export const ASH_BLACK = '#222';
export const WHITE = '#fafafa';
export const SKY = '#00ccff';
export const GREEN = '#22dddd';
export const BLUE = '#1300ff';
export const DUSK = '#6600ff';
export const PURPLE = '#9900ff';
export const PINK = '#ff0066';
export const RED = '#fe0222';
export const ORANGE = '#fd7702';
export const YELLOW = '#ffbb00';
export const PRIMARY = '#f48e0d';
