import car_front from '../images/car_front.png';
import bike_front from '../images/bike_front.png';
import micro_front from '../images/microbus_front.png';
import bus_front from '../images/bus_front.png';
import truck_front from '../images/truck_front.png';
import cng_front from '../images/cng_front.png';
import water_vesel_front from '../images/ship_front.png';
import tracktor_front from '../images/tractor_front.png';

export const FETCH_USERS = 'FETCH_USERS';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_USER = 'GET_USER';
export const SEARCH_USER = 'SEARCH_USER';
export const AUTH_USER = 'AUTH_USER';

export const ADD_DEVICE = 'ADD_DEVICE';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const ASSIGN_DEVICE = 'ASSIGN_DEVICE';
export const UNASSIGN_DEVICE = 'UNASSIGN_DEVICE';
export const USER_DEVICES = 'USER_DEVICES';
export const ADD_USER_DEVICE = 'ADD_USER_DEVICE';
export const UPDATE_USER_DEVICE = 'UPDATE_USER_DEVICE';
export const FETCH_SHRED_USERS = 'FETCH_SHRED_USERS';
export const FETCH_SHRED_DEVICES = 'FETCH_SHRED_DEVICES';
export const DELETE_SHRED_DEVICE = 'DELETE_SHRED_DEVICE';
export const ADD_SHRED_DEVICE = 'ADD_SHRED_DEVICE';
export const DELETE_USER_SHRED_DEVICE = 'DELETE_USER_SHRED_DEVICE';
export const USER_ALERTS = 'USER_ALERTS';
export const DELETE_ALERT = 'DELETE_ALERT';
export const DELETE_MANY_ALERT = 'DELETE_MANY_ALERT';
export const USER_TRANSACTION_HEADS = 'USER_TRANSACTION_HEADS';
export const ADD_USER_TRANSACTION_HEADS = 'ADD_USER_TRANSACTION_HEADS';
export const USER_TRANSACTIONS = 'USER_TRANSACTIONS';
export const ADD_USER_TRANSACTIONS = 'ADD_USER_TRANSACTIONS';
export const UPDATE_USER_TRANSACTION = 'UPDATE_USER_TRANSACTION';
export const DELETE_USER_TRANSACTION = 'DELETE_USER_TRANSACTION';

const vehicles = [
  'Car',
  'Bike',
  'Micro-Bus',
  'Bus',
  'Truck',
  'CNG',
  'Water-Vessel',
  'Tractor',
];

const getVehicleType = (index) => {
  return vehicles[index - 1];
};

export { getVehicleType };

export const getFrontView = (vehicleType) => {
  if (vehicleType <= 1) {
    return car_front;
  } else if (vehicleType === 2) {
    return bike_front;
  } else if (vehicleType === 3) {
    return micro_front;
  } else if (vehicleType === 4) {
    return bus_front;
  } else if (vehicleType === 5) {
    return truck_front;
  } else if (vehicleType === 6) {
    return cng_front;
  } else if (vehicleType === 7) {
    return water_vesel_front;
  } else if (vehicleType === 8) {
    return tracktor_front;
  } else {
    return car_front;
  }
};
