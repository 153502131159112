import { Card, Divider, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import MyDatePicker from '../../sub/MyDatePicker';
import UserDeviceAutoComplete from '../../sub/UserDeviceAutoComplete';
import SharedDeviceAutoComplete from '../../sub/SharedDeviceAutoComplete';
import { Chart } from 'react-google-charts';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import { locationManager } from '../../../api-networking';
import { minToStr } from '../../../utils/utils';

const SpeedReport = () => {
  const { selectedDevice, fromSharedUser } = useContext(FirebaseContext);

  const [state, setState] = useState({
    date: new Date(),
    visible: false,
    info: { max: 0, min: 0, avg: 0 },
    data: [],
    frequency: [],
    timeLineData: [],
    barData: [],
  });

  const lineOptions = {
    title: 'Speed Timeline',
    titleTextStyle: { color: '#f48e0d', bold: true },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
    colors: ['#f48e0d'],
    hAxis: {
      title: 'Time',
      slantedTextAngle: 90,
    },
    vAxis: {
      title: 'Speed',
    },
    curveType: 'function',
  };

  const barOptions = {
    chart: {
      title: 'Speed Frequency Distribution',
    },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
    colors: ['#f48e0d'],
    vAxis: {
      title: 'Speed',
    },
  };

  useEffect(() => {
    if (state.date && selectedDevice) {
      let data = {
        id: selectedDevice.id,
        year: state.date.getFullYear(),
        month: state.date.getMonth(),
        day: state.date.getDate(),
        device_type: selectedDevice.vehicle_type,
      };
      locationManager
        .getDailySpeedData(data)
        .then((d) => {
          // let a = [['x', 'min', 'avg', 'max']].concat(
          //   d.data.map((x) => [x.time, x.min, x.avg, x.max])
          // );
          // console.log(a);
          setState((old) => ({
            ...old,
            info: d.info ? d.info : { max: 0, min: 0, avg: 0 },
            data: d.data,
            frequency: d.frequency,
          }));
          // console.log(d);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.date, selectedDevice]);

  useEffect(() => {
    if (state.data.length > 1) {
      setState((old) => ({
        ...old,
        timeLineData: [['time', 'speed']].concat(
          old.data.map((x) => [minToStr(x.time), x.avg])
        ),
      }));
    }
  }, [state.data]);

  useEffect(() => {
    if (state.frequency.length > 1) {
      setState((old) => ({
        ...old,
        barData: [['Speed', 'Count']].concat(
          old.frequency.map((x) => [
            x._id + ' to ' + (x._id + 10) + ' (K/M)',
            x.count,
          ])
        ),
      }));
    }
  }, [state.frequency]);

  const setDate = (date) => {
    setState((old) => ({ ...old, date: new Date(date) }));
  };

  const Item = ({ title, value }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography>{title}</Typography>
        <Divider sx={{ bgcolor: 'primary.light', width: '100%' }} />
        <Typography>{value} KMPH</Typography>
      </div>
    );
  };
  return (
    <div style={{ width: '100%', height: '100%', padding: 10 }}>
      <Card style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Item title="Max" value={state.info.max} />
            <Item title="Min" value={state.info.min} />
            <Item title="Avg" value={state.info.avg} />
            <MyDatePicker date={state.date} dateChange={setDate} />
            {fromSharedUser ? (
              <SharedDeviceAutoComplete />
            ) : (
              <UserDeviceAutoComplete />
            )}
          </div>
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ display: 'flex', flex: 1 }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  paddingTop: 10,
                  paddingLeft: 10,
                  paddingBottom: 10,
                  display: 'flex',
                }}
              >
                {state.timeLineData.length > 1 && (
                  <Chart
                    chartType="LineChart"
                    width="100%"
                    height="100%"
                    data={state.timeLineData}
                    options={lineOptions}
                  />
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  paddingTop: 10,
                  paddingLeft: 10,
                  paddingBottom: 10,
                  display: 'flex',
                }}
              >
                {state.barData.length > 1 && (
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="100%"
                    data={state.barData}
                    options={barOptions}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SpeedReport;
