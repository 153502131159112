import * as React from 'react';
import { TextField, IconButton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CalendarToday } from '@mui/icons-material';

import dayjs from 'dayjs';

export default function MyDatePicker({ label, date, dateChange }) {
  //   const [value, setValue] = React.useState(null);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value={dayjs(date)}
          onChange={dateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}

export function MobileDatePicker({ label, date, dateChange }) {
  //   const [value, setValue] = React.useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={dayjs(date)}
        onChange={dateChange}
        // onAccept={dateChange}
        renderInput={(params) => (
          <IconButton {...params}>
            <CalendarToday />
          </IconButton>
        )}
      />
    </LocalizationProvider>
  );
}
