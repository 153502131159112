import React, { useContext, useState, useRef, useEffect } from 'react';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import {
  Slide,
  Dialog,
  Button,
  TextField,
  Avatar,
  Typography,
  InputAdornment,
  IconButton,
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import { grey } from '@mui/material/colors';

import { Search, Clear } from '@mui/icons-material';
import { userManager, sharedDeviceManager } from '../../../api-networking';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SharedDeviceDialog = () => {
  const {
    selectedDevice,
    closeShareDeviceDialog,
    shareDeviceOpen,
    setSnackbar,
  } = useContext(FirebaseContext);
  const [state, setState] = useState({
    search: '',
    users: [],
    searchUsers: [],
    visible: false,
  });

  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight: 70,
      fixedWidth: true,
    })
  );

  const handleChange = (e) => {
    setState((old) => ({ ...old, search: e.target.value }));
  };

  const handleShareClick = (user) => {
    const sharedDevice = { uid: user._id, device_id: selectedDevice.id };
    setState((old) => ({ ...old, visible: true }));
    sharedDeviceManager
      .addSharedUser(sharedDevice, user)
      .then((sd) => {
        setState((old) => ({ ...old, visible: false }));
        setSnackbar(true, 'Shared Device Successfully', 'success');
      })
      .catch((err) => {
        setState((old) => ({ ...old, visible: false }));
        setSnackbar(true, 'Already Shared to this User', 'error');
      });
  };

  useEffect(() => {
    if (selectedDevice) {
      if (state.search.length === 0) {
        setState((old) => ({ ...old, searchUsers: [...old.users] }));
      } else if (state.search.length === 1) {
        userManager
          .getSearchedUsers(state.search)
          .then((users) =>
            setState((old) => ({ ...old, users: users, searchUsers: users }))
          )
          .catch((err) => console.log(err));
      } else {
        setState((old) => ({
          ...old,
          searchUsers: [...old.users].filter((x) =>
            x.email.toLowerCase().includes(state.search.toLowerCase())
          ),
        }));
      }
    }
  }, [state.search, selectedDevice]);

  return shareDeviceOpen ? (
    <Dialog
      open={shareDeviceOpen}
      onClose={closeShareDeviceDialog}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <Card style={{ width: 500, height: 500 }}>
        <div
          style={{
            padding: 10,
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="sohel2">
            Share {selectedDevice && selectedDevice.registration_number} to
            Other Users
          </Typography>
          <TextField
            placeholder="Search User by Email"
            onChange={handleChange}
            fullWidth
            name="search"
            value={state.search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search
                    style={{
                      color: grey[700],
                      padding: 4,
                      cursor: 'pointer',
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setState((old) => ({ ...old, search: '' }))}
                  >
                    <Clear
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {state.visible && (
            <CircularProgress color="secondary" style={{ margin: 10 }} />
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={70}
                  rowCount={state.searchUsers.length}
                  rowRenderer={({ key, index, style, parent }) => {
                    const user = state.searchUsers[index];
                    // console.log(person);
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cache.current}
                        rowIndex={index}
                        parent={parent}
                      >
                        <div style={{ ...style, cursor: 'pointer' }}>
                          <ListItem
                            divider
                            key={index}
                            onClick={() => handleShareClick(user)}
                          >
                            <ListItemAvatar>
                              <Avatar src={user.image} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="button">
                                  {user.name}
                                </Typography>
                              }
                              secondary={
                                <Typography variant="body1">
                                  {user.contact}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </div>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button onClick={closeShareDeviceDialog}>Hide</Button>
          </div>
        </div>
      </Card>
    </Dialog>
  ) : null;
};

export default SharedDeviceDialog;
