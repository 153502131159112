import { useContext, useState, useEffect } from 'react';

import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import { Grid, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import {
  Typography,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Card,
} from '@mui/material';
import { chunks } from '../../../utils/web_utils';
import { downloadDevicesFile } from '../../../utils/download';

import { LocalTaxi, Search, Clear, List, Download } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import EditDevice from './EditDevice';
import Settings from './Settings';
import SharedDeviceDialog from './SharedDeviceDialog';
import SharedUsersDialog from './SharedUsersDialog';
import DeviceItem from '../../sub/DeviceItem';
import { MediaQueryContext } from '../../../Firebase/MediaQueryProvider';

const AllVehicles = () => {
  const {
    userDevices,
    editFormOpen,
    setEditFormOpen,
    settingOpen,
    closeSettings,
    shareDeviceOpen,
    sharedUsersOpen,
  } = useContext(FirebaseContext);

  const { isBigScreen } = useContext(MediaQueryContext);
  const [state, setState] = useState({
    search: '',
    chunkSize: isBigScreen ? 4 : 3,
    list: [],
  });

  useEffect(() => {
    const deviceFilter = (device) => {
      return (
        (device.id &&
          device.id.toLowerCase().includes(state.search.toLowerCase())) ||
        (device.mask_id &&
          device.mask_id.toLowerCase().includes(state.search.toLowerCase())) ||
        (device.registration_number &&
          device.registration_number
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.vehicle_model &&
          device.vehicle_model
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.device_sim_number &&
          device.device_sim_number
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.chasis_number &&
          device.chasis_number
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.code &&
          device.code.toLowerCase().includes(state.search.toLowerCase())) ||
        (device.driver_name &&
          device.driver_name
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.driver_phone &&
          device.driver_phone
            .toLowerCase()
            .includes(state.search.toLowerCase()))
      );
    };
    if (state.search === '') {
      setState((old) => ({
        ...old,
        list: chunks([...userDevices], old.chunkSize),
      }));
    } else {
      setState((old) => ({
        ...old,
        list: chunks(
          [...userDevices].filter((x) => deviceFilter(x)),
          old.chunkSize
        ),
      }));
    }
  }, [state.search, userDevices]);

  const handleChange = (e) => {
    setState((old) => ({ ...old, search: e.target.value }));
  };

  const setSearch = (value) => {
    setState((old) => ({ ...old, search: value }));
  };

  const getSeachedDevices = () => {
    if (state.list[0]) {
      return state.list.length * state.list[0].length <= userDevices.length
        ? state.list.length * state.list[0].length
        : userDevices.length;
    } else {
      return 0;
    }
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    return (
      <div
        key={key}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'center',
          padding: 5,
        }}
      >
        {state.list[rowIndex][columnIndex] && (
          <DeviceItem device={state.list[rowIndex][columnIndex]} />
        )}
      </div>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#f2f2f2',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: isBigScreen ? '10%' : '12%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: isBigScreen ? 1 : 2,
            justifyContent: 'space-between',
            marginLeft: 10,
          }}
        >
          <Card>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: 6,
                gap: 10,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="sohel2">Total Devices</Typography>
                <Typography variant="body2">{userDevices.length}</Typography>
              </div>
              <LocalTaxi sx={{ fontSize: 20 }} color="primary" />
            </div>
          </Card>

          <Card>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: 6,
                gap: 10,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="sohel2">Searched Devices</Typography>
                <Typography variant="body2">{getSeachedDevices()}</Typography>
              </div>
              <LocalTaxi sx={{ fontSize: 20 }} color="primary" />
            </div>
          </Card>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 3,
            // height: '100%',
            // gap: 10,
            // alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: 10,
          }}
        >
          <Card>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                padding: 6,
              }}
            >
              <TextField
                placeholder="Search"
                onChange={handleChange}
                name="search"
                value={state.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: 'pointer',
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearch('')}>
                        <Clear
                          style={{
                            color: grey[700],
                            padding: 4,
                            cursor: 'pointer',
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Tooltip title="list">
                <IconButton>
                  <List />
                </IconButton>
              </Tooltip>

              <Tooltip title="Download">
                <IconButton
                  onClick={() =>
                    downloadDevicesFile(
                      userDevices.map((x) => ({
                        id: x.id,
                        registration_number: x.registration_number
                          ? x.registration_number
                          : '',
                        chasis_number: x.chasis_number ? x.chasis_number : '',
                        vehicle_model: x.vehicle_model ? x.vehicle_model : '',
                        code: x.code ? x.code : '',
                        driver_name: x.driver_name ? x.driver_name : '',
                        driver_phone: x.driver_phone ? x.driver_phone : '',
                        update_time: x.geo ? x.geo.update_time : '',
                      }))
                    )
                  }
                >
                  <Download />
                </IconButton>
              </Tooltip>
            </div>
          </Card>
        </div>
      </div>

      <Card
        style={{
          position: 'absolute',
          left: 10,
          right: 10,
          height: '1%',
          top: isBigScreen ? '10%' : '12%',
          backgroundColor: '#f48e0d',
        }}
      ></Card>

      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: isBigScreen ? '89%' : '87%',
        }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <Grid
              cellRenderer={cellRenderer}
              columnCount={state.list[0] ? state.list[0].length : 0}
              columnWidth={
                width / state.chunkSize - (isBigScreen ? state.chunkSize : 10)
              }
              height={height}
              rowCount={state.list.length}
              rowHeight={300}
              width={width}
            />
          )}
        </AutoSizer>
      </div>

      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 5,
            width: '100%',
          }}
        ></div>
      </div>
      {editFormOpen && (
        <EditDevice open={editFormOpen} setOpen={setEditFormOpen} />
      )}
      {settingOpen && (
        <Settings open={settingOpen} closeSettings={closeSettings} />
      )}
      {shareDeviceOpen && <SharedDeviceDialog />}

      {sharedUsersOpen && <SharedUsersDialog />}
    </div>
  );
};

export default AllVehicles;
