import { useState, useContext } from 'react';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';

import { Typography, Button, TextField, Divider } from '@mui/material';
import { MediaQueryContext } from '../../Firebase/MediaQueryProvider';

const PasswordReset = ({ loadLoginPage }) => {
  const { reset_password_request } = useContext(FirebaseContext);
  const { isTabletOrMobile } = useContext(MediaQueryContext);
  const [state, setState] = useState({
    email: '',
  });
  const handleChange = (e) => {
    setState((old) => ({ ...old, [e.target.name]: e.target.value }));
  };
  return (
    <>
      <Typography
        textAlign={isTabletOrMobile ? 'center' : 'start'}
        variant="caption"
        fontSize={isTabletOrMobile ? '1.2rem' : '1rem'}
      >
        Password Reset
      </Typography>
      <Divider
        style={{
          width: isTabletOrMobile ? '100%' : '50%',
          backgroundColor: 'black',
        }}
      ></Divider>
      <Typography style={{ marginTop: 30 }}>
        Enter a valid Email Address to reset Password
      </Typography>

      <TextField
        fullWidth
        required
        label="Email"
        style={{ marginTop: 10 }}
        variant="outlined"
        name="email"
        value={state.email}
        onChange={handleChange}
      />

      <Button
        style={{ marginTop: 10 }}
        variant="contained"
        onClick={() => reset_password_request(state.email)}
      >
        Reset Password Request
      </Button>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <Typography>Already have an account?</Typography>
        <Button variant="text" onClick={loadLoginPage}>
          Login
        </Button>
      </div>
    </>
  );
};

export default PasswordReset;
