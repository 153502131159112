import React, { createContext, useEffect, useState } from 'react';
import MobileMain from '../components/mobile/MobileMain';
import LiveTrackingMobile from '../components/mobile/LiveTrackingMobile';
import MobileDailyReport from '../components/mobile/MobileDailyReport';
import MobileMonthlyReport from '../components/mobile/MobileMonthlyReport';
import MobileTravelReport from '../components/mobile/MobileTravelReport';
import MobileSpeedReport from '../components/mobile/MobileSpeedReport';
import { locationManager } from '../api-networking';
export const MobileContext = createContext();

const MobileProvider = ({ children }) => {
  const [state, setState] = useState({
    title: 'Title',
    selectedDevice: null,
    backStack: [MobileMain],
    foreStack: [],
    fromSharedUser: false,
    selectedDate: new Date(),
  });

  const onBackButtonClick = () => {
    let backStack = [...state.backStack];
    let foreStack = [...state.foreStack];
    foreStack.push(backStack.pop());
    setState((old) => ({ ...old, backStack: backStack, foreStack: foreStack }));
  };

  const setTitle = (title) => {
    setState((old) => ({ ...old, title: title }));
  };

  const onForwardButtonClick = () => {
    let backStack = [...state.backStack];
    let foreStack = [...state.foreStack];
    backStack.push(foreStack.pop());
    setState((old) => ({ ...old, backStack: backStack, foreStack: foreStack }));
  };

  const setSelectedDevice = (device) => {
    setState((old) => ({ ...old, selectedDevice: device }));
  };

  const onMapClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, LiveTrackingMobile],
      foreStack: [],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onDailyReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, MobileDailyReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onMonthlyReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, MobileMonthlyReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };
  const onTravelReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, MobileTravelReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onSpeedReportClick = (device, fromSharedUser) => {
    setState((old) => ({
      ...old,
      backStack: [...old.backStack, MobileSpeedReport],
      selectedDevice: device,
      fromSharedUser: fromSharedUser,
    }));
  };

  const onDateChange = (date) => {
    // console.log(date);
    setState((old) => ({ ...old, selectedDate: new Date(date) }));
  };

  const prevClick = () => {
    let d = new Date(state.selectedDate);
    d.setMonth(d.getMonth() - 1);
    setState((old) => ({ ...old, selectedDate: d }));
  };

  const nextClick = () => {
    let d = new Date(state.selectedDate);
    d.setMonth(d.getMonth() + 1);
    setState((old) => ({ ...old, selectedDate: d }));
  };

  const downloadFile = () => {
    let data = {
      device: state.selectedDevice,
      year: state.selectedDate.getFullYear(),
      month: state.selectedDate.getMonth(),
    };
    setState((old) => ({ ...old, visible: true }));

    locationManager
      .downloadMonthlyFile(data)
      .then((data) => {
        // setState((old) => ({ ...old }));
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => {
        console.log(err);
        setState((old) => ({ ...old }));
      });
  };

  useEffect(() => {
    if (state.backStack[state.backStack.length - 1] === MobileMain) {
      setState((old) => ({ ...old, title: 'Vehicle List' }));
    } else if (
      state.backStack[state.backStack.length - 1] === LiveTrackingMobile
    ) {
      setState((old) => ({
        ...old,
        title: state.selectedDevice
          ? 'Live Tracking of ' + state.selectedDevice.registration_number
          : 'Live Tracking',
      }));
    } else if (
      state.backStack[state.backStack.length - 1] === MobileDailyReport
    ) {
      setState((old) => ({
        ...old,
        title: state.selectedDevice
          ? 'Daily Report of ' + state.selectedDevice.registration_number
          : 'Daily Report',
      }));
    } else if (
      state.backStack[state.backStack.length - 1] === MobileMonthlyReport
    ) {
      setState((old) => ({
        ...old,
        title: state.selectedDevice
          ? 'Monthly Report of ' + state.selectedDevice.registration_number
          : 'Monthly Report',
      }));
    } else if (
      state.backStack[state.backStack.length - 1] === MobileTravelReport
    ) {
      setState((old) => ({
        ...old,
        title: state.selectedDevice
          ? 'Travel Report of ' + state.selectedDevice.registration_number
          : 'Travel Report',
      }));
    } else if (
      state.backStack[state.backStack.length - 1] === MobileSpeedReport
    ) {
      setState((old) => ({
        ...old,
        title: state.selectedDevice
          ? 'Speed Report of ' + state.selectedDevice.registration_number
          : 'Speed Report',
      }));
    }
  }, [state.backStack, state.selectedDevice, state.foreStack]);

  return (
    <MobileContext.Provider
      value={{
        title: state.title,
        fromSharedUser: state.fromSharedUser,
        selectedDevice: state.selectedDevice,
        selectedDate: state.selectedDate,
        backStack: state.backStack,
        foreStack: state.foreStack,
        setTitle: setTitle,
        Component: state.backStack[state.backStack.length - 1],
        setSelectedDevice: setSelectedDevice,
        onBackButtonClick: onBackButtonClick,
        onForwardButtonClick: onForwardButtonClick,
        onDateChange: onDateChange,
        onMapClick: onMapClick,
        onDailyReportClick: onDailyReportClick,
        onMonthlyReportClick: onMonthlyReportClick,
        onTravelReportClick: onTravelReportClick,
        onSpeedReportClick: onSpeedReportClick,
        prevClick: prevClick,
        nextClick: nextClick,
        downloadFile: downloadFile,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export default MobileProvider;
