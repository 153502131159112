import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';

import { AgGridReact } from 'ag-grid-react';
import dateformat from 'dateformat';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Card, Typography, Tooltip, IconButton } from '@mui/material';
import { temperatureManager } from '../../../api-networking';
import {
  GetApp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';

const timeArr = [
  '12:00 AM',
  '01:00 AM',
  '02:00 AM',
  '03:00 AM',
  '04:00 AM',
  '05:00 AM',
  '06:00 AM',
  '07:00 AM',
  '08:00 AM',
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '01:00 PM',
  '02:00 PM',
  '03:00 PM',
  '04:00 PM',
  '05:00 PM',
  '06:00 PM',
  '07:00 PM',
  '08:00 PM',
  '09:00 PM',
  '10:00 PM',
  '11:00 PM',
];

const TempTable = ({ data, device }) => {
  const [columnDefs, setColumnDefs] = useState([
    {
      field: '_id',
      headerName: 'Day',
      width: 100,
      minWidth: 80,
      maxWidth: 120,
    },

    {
      field: timeArr[0],
      valueGetter: (p) => {
        return p.data.data[0];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[1],
      valueGetter: (p) => {
        return p.data.data[1];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[2],
      valueGetter: (p) => {
        return p.data.data[2];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[3],
      valueGetter: (p) => {
        return p.data.data[3];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[4],
      valueGetter: (p) => {
        return p.data.data[4];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[5],
      valueGetter: (p) => {
        return p.data.data[5];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[6],
      valueGetter: (p) => {
        return p.data.data[6];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[7],
      valueGetter: (p) => {
        return p.data.data[7];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[8],
      valueGetter: (p) => {
        return p.data.data[8];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[9],
      valueGetter: (p) => {
        return p.data.data[9];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[10],
      valueGetter: (p) => {
        return p.data.data[10];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[11],
      valueGetter: (p) => {
        return p.data.data[11];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[12],
      valueGetter: (p) => {
        return p.data.data[12];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[13],
      valueGetter: (p) => {
        return p.data.data[13];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[14],
      valueGetter: (p) => {
        return p.data.data[14];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[15],
      valueGetter: (p) => {
        return p.data.data[15];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[16],
      valueGetter: (p) => {
        return p.data.data[16];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[17],
      valueGetter: (p) => {
        return p.data.data[17];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[18],
      valueGetter: (p) => {
        return p.data.data[18];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[19],
      valueGetter: (p) => {
        return p.data.data[19];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[20],
      valueGetter: (p) => {
        return p.data.data[20];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[21],
      valueGetter: (p) => {
        return p.data.data[21];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[22],
      valueGetter: (p) => {
        return p.data.data[22];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },

    {
      field: timeArr[23],
      valueGetter: (p) => {
        return p.data.data[23];
      },
      valueFormatter: (p) => {
        return p.value === -256 ? '-' : p.value;
      },
      cellStyle: (params) => {
        if (params.value > device.max_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'red' };
        } else if (params.value < device.min_temp || params.value === -256) {
          return { color: 'white', backgroundColor: 'green' };
        }
        return null;
      },
    },
  ]);

  const defaultCollDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      width: 120,
      minWidth: 80,
      maxWidth: 130,
    }),
    []
  );

  return (
    <div className="ag-theme-alpine" style={{ flex: 1 }}>
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultCollDef}
      />
    </div>
  );
};

const MonthlyTemperatureReport = () => {
  const { selectedDevice } = useContext(FirebaseContext);

  const [state, setState] = useState({
    date: new Date(),
    data: [],
  });

  useEffect(() => {
    if (selectedDevice && state.date) {
      let data = {
        device_id: selectedDevice.id,
        registration_number: selectedDevice.registration_number,
        year: state.date.getFullYear(),
        month: state.date.getMonth(),
      };

      temperatureManager
        .getMonthlyTemperatureData(data)
        .then((x) => setState((old) => ({ ...old, data: x })))
        .catch((err) => console.log(err));
    }
  }, [selectedDevice, state.date]);

  const prevClick = () => {
    const bbb = new Date(state.date);
    bbb.setMonth(bbb.getMonth() - 1);
    setState((old) => ({ ...old, date: bbb }));
  };

  const nextClick = () => {
    const bbb = new Date(state.date);
    bbb.setMonth(bbb.getMonth() + 1);
    setState((old) => ({ ...old, date: bbb }));
  };

  const downloadFile = () => {
    let data = {
      device_id: selectedDevice.id,
      registration_number: selectedDevice.registration_number,
      year: state.date.getFullYear(),
      month: state.date.getMonth(),
    };

    temperatureManager
      .downloadTemperatureFile(data)
      .then((xx) => {
        const file = new Blob([xx], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: '100%', height: '100%', padding: 10 }}>
      <Card style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            padding: 10,
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="button">Day Count</Typography>
              {state.data && (
                <Typography variant="body1">
                  {state.data.length} Days
                </Typography>
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Previous">
                <IconButton onClick={prevClick}>
                  <KeyboardArrowLeft />
                </IconButton>
              </Tooltip>
              <Typography
                style={{ marginLeft: 20, marginRight: 20 }}
                variant="button"
              >
                Report on {dateformat(state.date, 'mmmm-yyyy')}
              </Typography>

              <Tooltip title="Next">
                <IconButton onClick={nextClick}>
                  <KeyboardArrowRight />
                </IconButton>
              </Tooltip>
            </div>

            <Tooltip style={{ margin: 10 }} title="Download">
              <IconButton onClick={downloadFile}>
                <GetApp />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ display: 'flex', flex: 1 }}>
            <TempTable data={state.data} device={selectedDevice} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MonthlyTemperatureReport;
