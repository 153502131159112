import React, { useState, useContext } from 'react';
// import axios from 'axios';
// import { COMMAND_URL } from "../../../utils/url";
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';

import {
  Slide,
  Dialog,
  Card,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';

import { Power, PowerOff, ViewComfy } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

const myList = [
  { name: 'Power ON', icon: Power },
  { name: 'Power OFF', icon: PowerOff },
  { name: 'Tax Token', icon: ViewComfy },
  { name: 'Set Boundary', icon: ViewComfy },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Settings = ({ open, closeSettings }) => {
  const [hoverIndex, setHoverIndex] = useState(-1);

  const { selectedDevice, sendCommandToserver } = useContext(FirebaseContext);

  const itemClick = (index) => {
    if (index === 0 || index === 1) {
      const command = {
        device_id: selectedDevice.id,
        command_type: 'S20',
        power: index === 0 ? 'ON' : 'OFF',
        center_number: selectedDevice.center_number,
      };

      sendCommandToserver(command);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={closeSettings}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <Card style={{ padding: 20, width: 500, height: 400 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center">
              <Typography variant="h6">
                Settings of{' '}
                {selectedDevice && selectedDevice.registration_number}
              </Typography>
            </Box>

            <List style={{ width: '100%', overflow: 'hiddeen' }}>
              {myList.map((item, index) => (
                <ListItem
                  divider
                  key={index}
                  style={
                    hoverIndex === index
                      ? { cursor: 'pointer', backgroundColor: grey[200] }
                      : { cursor: 'pointer' }
                  }
                  onMouseEnter={() => setHoverIndex((ii) => index)}
                  onClick={() => itemClick(index)}
                >
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="button">{item.name}</Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button color="secondary" onClick={closeSettings}>
              Hide
            </Button>
          </Box>
        </Box>
      </Card>
    </Dialog>
  );
};

export default Settings;
