import React, { useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Avatar } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../../images/logo.png';

import { FirebaseContext } from '../../Firebase/FirebaseProvider';
import UserProfileSection from '../sub/UserProfileSection';
import NavList from '../sub/NavList';
import { ArrowForward } from '@mui/icons-material';
import { MediaQueryContext } from '../../Firebase/MediaQueryProvider';
import MobileHome from '../mobile/MobileHome';
import MobileProvider from '../../Firebase/MobileProvider';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Home() {
  const theme = useTheme();

  const [state, setState] = useState({
    open: false,
  });

  const { isTabletOrMobile } = useContext(MediaQueryContext);

  const {
    title,
    ChildComponent,
    onNavItemClick,
    backStack,
    foreStack,
    onBackButtonClick,
    onForwardButtonClick,
  } = useContext(FirebaseContext);

  // console.log(isTabletOrMobile);

  const handleDrawerOpen = () => {
    setState((old) => ({ ...old, open: true }));
  };

  const handleDrawerClose = () => {
    setState((old) => ({ ...old, open: false }));
  };

  if (isTabletOrMobile) {
    return (
      <MobileProvider>
        <MobileHome />
      </MobileProvider>
    );
  } else {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={state.open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={
                backStack.length <= 1 ? handleDrawerOpen : onBackButtonClick
              }
              edge="start"
              sx={{
                marginRight: 5,
                ...(state.open && { display: 'none' }),
              }}
            >
              {backStack.length <= 1 ? <MenuIcon /> : <ArrowBackIcon />}
            </IconButton>
            {foreStack.length > 0 && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={onForwardButtonClick}
                sx={{
                  marginRight: 5,
                  ...(state.open && { display: 'none' }),
                }}
              >
                <ArrowForward />
              </IconButton>
            )}

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" noWrap component="div">
                {title}
              </Typography>
              <Typography variant="h6" noWrap component="div">
                Others Component of Users Goes Here
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={state.open}>
          <DrawerHeader>
            <div style={{ flex: 1 }}>
              <Avatar
                alt="Logo"
                variant="rounded"
                src={logo}
                sx={{ width: 150, height: 50 }}
              />
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {state.open && <UserProfileSection />}

          <NavList
            open={state.open}
            onNavItemClick={onNavItemClick}
            title={title}
          />
          {/* <Divider /> */}
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DrawerHeader />
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            {<ChildComponent />}
          </div>
        </Box>
      </Box>
    );
  }
}
