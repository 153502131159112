import { useContext } from 'react';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';
import { Autocomplete, TextField } from '@mui/material';

const SharedDeviceAutoComplete = () => {
  const { sharedDevices, selectedDevice, setSelectedDevice } =
    useContext(FirebaseContext);

  return (
    <Autocomplete
      id="combo-box-demo"
      options={[...sharedDevices].filter(
        (dev) =>
          dev.registration_number &&
          (dev.registration_number !== '' ||
            dev.registration_number.trim().toLowerCase() !== 'undefined')
      )}
      getOptionLabel={(device) => device.registration_number}
      style={{ width: 300 }}
      value={selectedDevice}
      onChange={(event, value, reason) => value && setSelectedDevice(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Registration Number"
          variant="outlined"
          style={{ backgroundColor: '#fff' }}
        />
      )}
    />
  );
};

export default SharedDeviceAutoComplete;
