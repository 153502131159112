import centerImage from '../../images/center.png';
import bottomLeft from '../../images/bottom left corner.png';
import topRight from '../../images/top right corner.png';
import logo from '../../images/logo.png';
import car from '../../images/car.svg';

const Background = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          backgroundColor: '#f4e9c4',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          <img width="100%" src={bottomLeft} alt="center_image" />
        </div>
        <div
          style={{
            flex: 2,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <img width="90%" src={centerImage} alt="center_image" />
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <img width="100%" src={topRight} alt="center_image" />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Background;
