import { useState, useContext, useEffect } from 'react';
import MyCard from '../../sub/MyCard';
import MyDatePicker from '../../sub/MyDatePicker';
import { formatDuration } from '../../../utils/utils';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import UserDeviceAutoComplete from '../../sub/UserDeviceAutoComplete';
import SharedDeviceAutoComplete from '../../sub/SharedDeviceAutoComplete';
import { Card, CircularProgress, Divider, Typography } from '@mui/material';
import { locationManager } from '../../../api-networking';
import { HourlyReport, TripReport } from '../../table';

const DailyReport = () => {
  const { selectedDevice, fromSharedUser } = useContext(FirebaseContext);
  const [state, setState] = useState({
    date: new Date(),
    total_distance: 0,
    running_time: 0,
    visible: false,
    trip_report: [],
    hourly_report: [],
  });

  useEffect(() => {
    if (state.date && selectedDevice) {
      let data = {
        device_id: selectedDevice.id,
        year: state.date.getFullYear(),
        month: state.date.getMonth(),
        day: state.date.getDate(),
        device_type: selectedDevice.vehicle_type,
      };

      setState((old) => ({ ...old, visible: true }));
      locationManager
        .getDeviceDailyReport(data)
        .then((d) =>
          setState((old) => ({
            ...old,
            visible: false,
            trip_report: d.trip_report,
            hourly_report: d.hourly_report,
            total_distance: d.total_distance,
            running_time: d.running_time,
          }))
        )
        .catch((err) => {
          setState((old) => ({ ...old, visible: false }));
        });
    }
  }, [state.date, selectedDevice]);

  const setDate = (date) => {
    setState((old) => ({ ...old, date: new Date(date) }));
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: 10,
        flexDirection: 'column',
      }}
    >
      {state.visible ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <MyCard
            title="Distance"
            value={String(state.total_distance) + ' KM'}
          />
          <MyCard title="Running" value={formatDuration(state.running_time)} />
          <MyCard
            title="Fuel"
            value={
              selectedDevice.milage
                ? (state.total_distance / selectedDevice.milage).toFixed(2) +
                  ' Lit'
                : 'Set Milage'
            }
          />
          <Card>
            <div style={{ padding: 10 }}>
              <MyDatePicker
                label="Select Report Date"
                date={state.date}
                dateChange={setDate}
              />
            </div>
          </Card>

          <Card>
            <div style={{ padding: 10 }}>
              {fromSharedUser ? (
                <SharedDeviceAutoComplete />
              ) : (
                <UserDeviceAutoComplete />
              )}
            </div>
          </Card>
        </div>
      )}

      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div
            style={{
              width: '100%',
              marginTop: 20,
              marginRight: 20,
            }}
          >
            <Card style={{ width: '100%', height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 10,
                  height: '100%',
                }}
              >
                <div
                  style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6">Hourly Travel Distance</Typography>
                  <Divider style={{ width: '50%' }} />
                </div>
                <div style={{ flex: 1 }}>
                  <HourlyReport hourly_report={state.hourly_report} />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          <div
            style={{
              width: '100%',
              marginTop: 20,
              marginLeft: 20,
            }}
          >
            <Card style={{ width: '100%', height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 10,
                  height: '100%',
                }}
              >
                <div
                  style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6">Daily Trip Report</Typography>
                  <Divider style={{ width: '50%' }} />
                </div>
                <div style={{ flex: 1 }}>
                  <TripReport trip_report={state.trip_report} />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyReport;
