import React, { useState, useEffect, useContext } from 'react';

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText,
  DialogTitle,
  TextField,
  Avatar,
  Card,
} from '@mui/material';

import { FirebaseContext } from '../../../Firebase/FirebaseProvider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const EditDevice = ({ open, setOpen }) => {
  const { selectedDevice, updateUserDevice } = useContext(FirebaseContext);

  const [state, setState] = useState({
    device: null,
    error: {
      driver_name_valid: false,
      driver_phone_valid: false,
      registration_number_valid: false,
      mileage_valid: false,
      congestion_consumption_valid: false,
    },
    disable: true,
    file: null,
  });

  const onFileSelect = (e) => {
    // console.log(URL.createObjectURL(e.target.files[0]));

    setState((old) => ({
      ...old,
      file: e.target.files[0],
      device: {
        ...old.device,
        driver_photo: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };

  const handleChange = (e) => {
    const device = { ...state.device };
    device[e.target.name] = e.target.value;
    setState((old) => ({ ...old, device: device }));
  };

  useEffect(() => {
    setState((old) => ({ ...old, device: selectedDevice }));
  }, [selectedDevice]);

  useEffect(() => {
    let driver_name_valid =
      state.device &&
      state.device.driver_name &&
      state.device.driver_name.length > 3;
    let driver_phone_valid =
      state.device &&
      state.device.driver_phone &&
      state.device.driver_phone.length > 10;

    let registration_number_valid =
      state.device &&
      state.device.registration_number &&
      state.device.registration_number.length > 0;
    let mileage_valid = state.device && Number(state.device.mileage) > 0;
    let congestion_consumption_valid =
      state.device &&
      Number(state.device.congestion_consumption) > 0 &&
      Number(state.device.congestion_consumption) < 1;

    setState((old) => ({
      ...old,
      error: {
        driver_name_valid: driver_name_valid,
        driver_phone_valid: driver_phone_valid,
        registration_number_valid: registration_number_valid,
        mileage_valid: mileage_valid,
        congestion_consumption_valid: congestion_consumption_valid,
      },
      disable: !(
        driver_name_valid &&
        driver_phone_valid &&
        registration_number_valid &&
        mileage_valid &&
        congestion_consumption_valid
      ),
    }));
  }, [state.device]);

  const onUpdateClick = () => {
    const formData = new FormData();

    const {
      driver_name,
      driver_phone,
      vehicle_model,
      registration_number,
      mileage,
      congestion_consumption,
      code,
      chasis_number,
    } = state.device;

    formData.append('driver_name', driver_name);
    formData.append('driver_phone', driver_phone);
    formData.append('registration_number', registration_number);
    formData.append('vehicle_model', vehicle_model);

    if (mileage) {
      formData.append('mileage', Number(mileage));
    }

    if (code) {
      formData.append('code', code);
    }

    if (chasis_number) {
      formData.append('chasis_number', chasis_number);
    }

    if (congestion_consumption) {
      formData.append('congestion_consumption', Number(congestion_consumption));
    }

    if (state.file) {
      formData.append('image', state.file);
    }

    updateUserDevice(formData);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ textTransform: 'uppercase' }}
      >
        Edit Vehicle {selectedDevice && selectedDevice.id}
      </DialogTitle>

      {state.device && (
        <DialogContent>
          <DialogContentText style={{ textTransform: 'uppercase' }}>
            To edit your <b>Vehicle</b> fill up the Following Form Properly
          </DialogContentText>

          <TextField
            label={
              !state.error.driver_name_valid
                ? 'Name Should Contain 3 and more Character'
                : 'Customer Name'
            }
            name="driver_name"
            variant="outlined"
            onChange={handleChange}
            value={state.device.driver_name}
            error={!state.error.driver_name_valid}
            style={{ marginTop: 10 }}
            fullWidth
            required
          />

          <TextField
            label={
              !state.error.driver_phone_valid
                ? 'Phone Number should contain 11 characters number'
                : 'Customer Phone'
            }
            name="driver_phone"
            variant="outlined"
            onChange={handleChange}
            value={state.device.driver_phone}
            error={!state.error.driver_phone_valid}
            style={{ marginTop: 10 }}
            fullWidth
            required
          />

          <TextField
            label={
              !state.error.registration_number_valid
                ? 'Empty Field is not Allowed'
                : 'Engine Number'
            }
            name="registration_number"
            variant="outlined"
            onChange={handleChange}
            value={state.device.registration_number}
            error={!state.error.registration_number_valid}
            style={{ marginTop: 10 }}
            fullWidth
            required
          />

          <TextField
            label="Chasis Number"
            name="chasis_number"
            variant="outlined"
            onChange={handleChange}
            value={state.device.chasis_number}
            style={{ marginTop: 10 }}
            fullWidth
          />

          <TextField
            label="Vehicle Model"
            name="chasis_number"
            variant="outlined"
            onChange={handleChange}
            value={state.device.vehicle_model}
            style={{ marginTop: 10 }}
            fullWidth
          />

          <TextField
            label="Code"
            name="chasis_number"
            variant="outlined"
            onChange={handleChange}
            value={state.device.code}
            style={{ marginTop: 10 }}
            fullWidth
          />

          <TextField
            label={
              !state.error.mileage_valid
                ? 'Milage Should greater than 0 and input as number'
                : 'Milage'
            }
            name="mileage"
            variant="outlined"
            onChange={handleChange}
            value={state.device.mileage}
            error={!state.error.mileage_valid}
            style={{ marginTop: 10 }}
            fullWidth
            required
          />

          <TextField
            label={
              !state.error.congestion_consumption_valid
                ? 'Jam Consumption Should greater than 0 and less than 1 and input as number'
                : 'Jam Consumption'
            }
            name="congestion_consumption"
            variant="outlined"
            onChange={handleChange}
            value={state.device.congestion_consumption}
            error={!state.error.congestion_consumption_valid}
            style={{ marginTop: 10 }}
            fullWidth
            required
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 10,
            }}
          >
            <Card raised>
              <Avatar
                variant="square"
                src={state.device.driver_photo}
                alt="driver_photo"
                style={{ width: 120, height: 120 }}
              />
            </Card>

            <label htmlFor="upload-photo" style={{ marginTop: 10 }}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={onFileSelect}
              />

              <Button color="primary" variant="contained" component="span">
                Browse File
              </Button>
            </label>
          </div>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          disabled={state.disable}
          onClick={onUpdateClick}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDevice;
