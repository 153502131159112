import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import logo from '../../images/logo.png';

import { ArrowBack, ArrowForward, GetApp, Logout } from '@mui/icons-material';
import { useContext } from 'react';
import { MobileContext } from '../../Firebase/MobileProvider';
import MobileDailyReport from './MobileDailyReport';
import { MobileDatePicker } from '../sub/MyDatePicker';
import MobileMonthlyReport from './MobileMonthlyReport';
import MobileTravelReport from './MobileTravelReport';
import MobileSpeedReport from './MobileSpeedReport';
import dateFormat from 'dateformat';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';

const MobileAppBar = ({ title }) => {
  const {
    backStack,
    foreStack,
    onBackButtonClick,
    onForwardButtonClick,
    Component,
    selectedDate,
    onDateChange,
    downloadFile,
  } = useContext(MobileContext);
  const { signout } = useContext(FirebaseContext);
  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={backStack.length > 1 ? onBackButtonClick : undefined}
          edge="start"
        >
          {backStack.length <= 1 ? (
            foreStack.length === 0 && (
              <img
                src={logo}
                width={60}
                height={35}
                alt="logo"
                style={{ backgroundColor: 'white' }}
              />
            )
          ) : (
            <ArrowBack />
          )}
        </IconButton>
        {foreStack.length > 0 && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onForwardButtonClick}
          >
            <ArrowForward />
          </IconButton>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption" noWrap component="div">
              {title}
            </Typography>
            {(Component === MobileDailyReport ||
              Component === MobileTravelReport ||
              Component === MobileSpeedReport) && (
              <Typography variant="caption" noWrap component="div">
                {'On ' + dateFormat(selectedDate, 'yyyy-mm-dd')}
              </Typography>
            )}
          </div>

          {Component === MobileDailyReport ||
          Component === MobileTravelReport ||
          Component === MobileSpeedReport ? (
            <MobileDatePicker
              label=""
              date={selectedDate}
              dateChange={onDateChange}
            />
          ) : (
            Component === MobileMonthlyReport && (
              <IconButton onClick={downloadFile}>
                <GetApp />
              </IconButton>
            )
          )}

          <IconButton onClick={signout}>
            <Logout />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;

// db.devices.updateMany({}, [{ $unset: ["geo"] }])
