import { useContext, useEffect, useState, useRef } from 'react';
import { GoogleMap, InfoWindow } from '@react-google-maps/api';
import UserDeviceAutoComplete from '../../sub/UserDeviceAutoComplete';
import SharedDeviceAutoComplete from '../../sub/SharedDeviceAutoComplete';
import { Card, Typography } from '@mui/material';
import MyDatePicker from '../../sub/MyDatePicker';
import { locationManager } from '../../../api-networking';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import marker_blue from '../../../images/marker_blue.png';
import marker_red from '../../../images/marker_red.png';
import LocationPopUp from '../gmap/LocationPopUp';
import { getDistanceFromLocations } from '../../../utils/utils';
let defaultLatLng = {
  lat: 23.8103,
  lng: 90.4125,
};

const DailyTravelReport = () => {
  const markersRef = useRef(null);
  const polyLineRef = useRef(null);
  const { selectedDevice, fromSharedUser } = useContext(FirebaseContext);
  const [state, setState] = useState({
    search: '',
    center: { ...defaultLatLng },
    zoom: 15,
    date: new Date(),
    distance: 0,
    locations: [],
    map: null,
    location: null,
  });

  useEffect(() => {
    if (state.date && selectedDevice) {
      let data = {
        device_id: selectedDevice.id,
        year: state.date.getFullYear(),
        month: state.date.getMonth(),
        day: state.date.getDate(),
        device_type: selectedDevice.vehicle_type,
      };

      locationManager
        .getDailyLocations(data)
        .then((d) => {
          setState((old) => ({ ...old, locations: d }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDevice, state.date]);

  useEffect(() => {
    // doit();

    // Remove Polyline If Exist
    if (polyLineRef.current) {
      polyLineRef.current.setMap(null);
    }

    // Remove Markers If Exist
    if (markersRef.current && markersRef.current.length > 0) {
      markersRef.current.forEach((x) => {
        x.setMap(null);
      });
    }

    if (state.map) {
      let markers = [];
      const polylinePath = [];
      const bounds = new window.google.maps.LatLngBounds();
      state.locations.forEach((x, i) => {
        bounds.extend({ lat: x.geo.lat, lng: x.geo.lng });
        let marker = null;
        if (i === 0) {
          marker = new window.google.maps.Marker({
            position: { lat: x.geo.lat, lng: x.geo.lng },
            map: state.map,
            title: 'Start Position',
            icon: {
              url: marker_blue,
              scaledSize: new window.google.maps.Size(40, 40),
            },
          });
        } else if (i === state.locations.length - 1) {
          marker = new window.google.maps.Marker({
            position: { lat: x.geo.lat, lng: x.geo.lng },
            map: state.map,
            title: 'End Position',
            icon: {
              url: marker_red,
              scaledSize: new window.google.maps.Size(40, 40),
            },
          });
        } else {
          marker = new window.google.maps.Circle({
            center: { lat: x.geo.lat, lng: x.geo.lng },
            radius: 10,
            strokeColor: '#ff9800',
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: '#ff9800',
            fillOpacity: 1,
            map: state.map,
          });
        }

        polylinePath.push({ lat: x.geo.lat, lng: x.geo.lng });

        marker.addListener('click', () => {
          setState((old) => ({ ...old, location: x }));
          // console.log(x, 'On Marker Click');
        });

        markers.push(marker);
      });
      state.map.fitBounds(bounds);

      const routePath = new window.google.maps.Polyline({
        path: polylinePath,
        geodesic: true,
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });

      routePath.setMap(state.map);

      polyLineRef.current = routePath;

      markersRef.current = markers;

      setState((old) => ({
        ...old,
        distance: getDistanceFromLocations(old.locations),
      }));
    }
  }, [state.locations, state.map]);

  const setDate = (date) => {
    setState((old) => ({ ...old, date: new Date(date) }));
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '100%' }}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={state.center}
          zoom={state.zoom}
          onLoad={(map) => setState((old) => ({ ...old, map: map }))}
          onUnmount={(map) => setState((old) => ({ ...old, map: null }))}
        >
          {state.location && (
            <InfoWindow
              position={{
                lat: state.location.geo.lat,
                lng: state.location.geo.lng,
              }}
              onCloseClick={() =>
                setState((old) => ({ ...old, location: null }))
              }
            >
              <LocationPopUp location={state.location} />
            </InfoWindow>
          )}
        </GoogleMap>
      </div>

      <div
        style={{
          position: 'absolute',
          top: 10,
          left: 10,
          right: 10,
          zIndex: 500,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ backgroundColor: 'white', padding: 5 }}>
          <MyDatePicker date={state.date} dateChange={setDate} />
        </div>

        <Card square>
          <div style={{ padding: 15 }}>
            <Typography variant="button">
              Total Distance {state.distance} KM
            </Typography>
          </div>
        </Card>
        <div style={{ backgroundColor: 'white', padding: 5 }}>
          {fromSharedUser ? (
            <SharedDeviceAutoComplete />
          ) : (
            <UserDeviceAutoComplete />
          )}
        </div>
      </div>
    </div>
  );
};

export default DailyTravelReport;
