import { useContext, useEffect, useState } from 'react';
import { Card, Tooltip, IconButton, CircularProgress } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import MyDatePicker from '../../sub/MyDatePicker';
import UserDeviceAutoComplete from '../../sub/UserDeviceAutoComplete';
import SharedDeviceAutoComplete from '../../sub/SharedDeviceAutoComplete';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import { locationManager } from '../../../api-networking';
import { RouteSummeryTable } from '../../table';
import { downloadRouteSummery } from '../../../utils/download';

const RouteSummery = () => {
  const { selectedDevice, fromSharedUser } = useContext(FirebaseContext);
  const [state, setState] = useState({
    date: new Date(),
    datas: [],
    visible: false,
  });

  useEffect(() => {
    if (selectedDevice && state.date) {
      let data = {
        id: selectedDevice.id,
        year: state.date.getFullYear(),
        month: state.date.getMonth(),
        day: state.date.getDate(),
      };
      setState((old) => ({ ...old, visible: true }));
      locationManager
        .getRouteSummeryData(data)
        .then((d) => {
          setState((old) => ({ ...old, datas: d, visible: false }));
        })
        .catch((err) => {
          setState((old) => ({ ...old, visible: false }));
          console.log(err);
        });
    }
  }, [selectedDevice, state.date]);

  const setDate = (date) => {
    setState((old) => ({ ...old, date: new Date(date) }));
  };

  const downloadFile = () => {
    downloadRouteSummery(
      [...state.datas].map((x) => ({
        start_address: x.start_address,
        start_time: x.start_time,
        distance: x.distance,
        duration: x.duration,
        end_time: x.end_time,
        end_address: x.end_address,
      }))
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: 10,
      }}
    >
      <Card style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: 10,

            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <MyDatePicker
              label="Select Report Date"
              date={state.date}
              dateChange={setDate}
            />
            {state.visible && <CircularProgress />}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {fromSharedUser ? (
                <SharedDeviceAutoComplete />
              ) : (
                <UserDeviceAutoComplete />
              )}

              <Tooltip title="Download">
                <IconButton onClick={downloadFile}>
                  <GetApp />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div style={{ display: 'flex', flex: 1, marginTop: 10 }}>
            <RouteSummeryTable datas={state.datas} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RouteSummery;
