import { useState, useEffect, useContext } from 'react';
import { Grid, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import {
  Card,
  Typography,
  Checkbox,
  Tooltip,
  IconButton,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import { notificationManager } from '../../../api-networking';
import { chunks } from '../../../utils/web_utils';
import { lastUpdate } from '../../../utils/utils';
import { LocationOn, Search, Clear } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

const Notifications = () => {
  const { dbUser } = useContext(FirebaseContext);

  const [state, setState] = useState({
    list: [],
    chunkSize: 4,
    deleteList: [],
    search: '',
    notifications: [],
  });

  useEffect(() => {
    if (dbUser) {
      notificationManager.getUserNotification(dbUser._id).then((x) =>
        setState((old) => ({
          ...old,
          list: chunks(x, old.chunkSize),
          notifications: x,
        }))
      );
    }
  }, [dbUser]);

  const AlertItem = ({ alert }) => {
    const handleChange = (e) => {
      console.log(e.target.checked);
      if (e.target.checked) {
        setState((old) => ({ ...old, deleteList: [...old.deleteList, alert] }));
      } else {
        setState((old) => ({
          ...old,
          deleteList: [...old.deleteList].filter((x) => x._id !== alert._id),
        }));
      }
    };
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          padding: 10,

          border: '1px solid #f48e0d',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Typography>{alert.title}</Typography>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="caption">
              {alert.message ? alert.message : 'Unknown'}
            </Typography>
            <Typography variant="caption" fontSize="0.5rem">
              {alert.date ? lastUpdate(alert.date) : 'Unknown'}
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Alert Location">
            <IconButton
              onClick={() => onViewClick({ lat: alert.lat, lng: alert.lng })}
            >
              <LocationOn />
            </IconButton>
          </Tooltip>
          <Tooltip title="Select to Delete">
            <Checkbox
              onChange={handleChange}
              checked={
                state.deleteList.filter((x) => x._id === alert._id).length > 0
              }
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    return (
      <div
        key={key}
        style={{
          ...style,
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {state.list[rowIndex][columnIndex] && (
          <AlertItem alert={state.list[rowIndex][columnIndex]} />
        )}
      </div>
    );
  };

  const onViewClick = (x) => {
    let url =
      'https://www.google.com/maps/search/?api=1&query=' + x.lat + ',' + x.lng;
    window.open(url);
  };

  const handleChange = (e) => {
    e.persist();
    setState((old) => ({ ...old, search: e.target.value }));
  };

  const deleteClick = () => {
    // alertManager
    //   .deleteManyAlerts(state.deleteList)
    //   .then((d) => {
    //     console.log('deleted');
    //     let all = [...state.alerts].filter(
    //       (x) => ![...state.deleteList].includes(x)
    //     );
    //     setState((old) => ({
    //       ...old,
    //       alerts: all,
    //       deleteList: [],
    //       list: chunks(all, old.chunkSize),
    //     }));
    //   })
    //   .catch((err) => console.log(err));
  };
  return (
    <div style={{ width: '100%', height: '100%', padding: 10 }}>
      <Card style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 10,
              alignItems: 'center',
            }}
          >
            {state.deleteList.length > 0 ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>
                  Seleced Alerts ({state.deleteList.length})
                </Typography>
                <Button onClick={deleteClick}>Delete</Button>
              </div>
            ) : (
              <div>
                <Typography>Total ({state.notifications.length})</Typography>
              </div>
            )}

            <TextField
              placeholder="Search"
              onChange={handleChange}
              name="search"
              value={state.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: 'pointer',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setState((old) => ({ ...old, search: '' }))
                      }
                    >
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ display: 'flex', flex: 1 }}>
            <AutoSizer>
              {({ width, height }) => (
                <Grid
                  cellRenderer={cellRenderer}
                  columnCount={state.list[0] ? state.list[0].length : 0}
                  columnWidth={width / 4 - 4}
                  height={height}
                  rowCount={state.list.length}
                  rowHeight={75}
                  width={width}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Notifications;
