import { useContext } from 'react';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';
import { Avatar, Typography, Divider } from '@mui/material';

const UserProfileSection = () => {
  const { dbUser } = useContext(FirebaseContext);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
          alignItems: 'center',
        }}
      >
        {dbUser.image ? (
          <Avatar
            alt="USER PHOTO"
            src="https://sultantracker.com/uploads/profiles/image-1648910721645.jpg"
            sx={{ width: 150, height: 150 }}
          />
        ) : (
          <Avatar
            alt="USER PHOTO"
            sx={{
              width: 150,
              height: 150,
              bgcolor: 'red',
              fontSize: '4rem',
            }}
          >
            {dbUser.name[0].toUpperCase()}
          </Avatar>
        )}

        <Typography variant="body2">{dbUser.name}</Typography>
        <Typography variant="sohel" style={{ wordWrap: 'break-word' }}>
          {dbUser.address}
        </Typography>
      </div>
      <Divider sx={{ width: '100%' }} />
    </>
  );
};

export default UserProfileSection;
