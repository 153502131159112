// import logo from './logo.svg';
// import './App.css';
import { LoadScript } from '@react-google-maps/api';
import { MAP_API_KEY } from './utils/web_utils';
import Main from './components/pages/Main';
import FirebaseProvider from './Firebase/FirebaseProvider';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material';
import MediaQueryProvider from './Firebase/MediaQueryProvider';

let myTheme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    body1: {
      color: '#777777',
    },
    subtitle1: {
      color: '#cc0000',
    },
    subtitle2: {
      color: 'white',
      fontSize: '1rem',
    },
    sohel: {
      fontFamily: ['Tangerine', 'sans-serif'].join(','),
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    sohel2: {
      fontWeight: 300,
      fontSize: '1.1rem',
    },
    textTitle: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontWeight: 800,
      fontSize: '1.2rem',
      color: '#777777',
    },
    textLight: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontWeight: 500,
      fontSize: '0.9rem',
      color: '#999999',
    },
  },
  palette: {
    primary: {
      main: '#f48e0d',
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        wordWrap: 'break-word',
      },
    },
  },
});

myTheme = responsiveFontSizes(myTheme);

function App() {
  return (
    <ThemeProvider theme={myTheme}>
      <MediaQueryProvider>
        <LoadScript googleMapsApiKey={MAP_API_KEY}>
          <FirebaseProvider>
            <Main />
          </FirebaseProvider>
        </LoadScript>
      </MediaQueryProvider>
    </ThemeProvider>
  );
}

export default App;
