import { SHARED_USERS, SHARED_DEVICE } from '../utils/url';

class SharedUsersManager {
  constructor(axios, AUTHORIZATION_KEY) {
    this.axios = axios;
    this.AUTHORIZATION_KEY = AUTHORIZATION_KEY;
  }

  getSharedUsers(device) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(SHARED_USERS + device.id, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteSharedUser(shareUser) {
    return new Promise((resolve, reject) => {
      this.axios
        .delete(SHARED_DEVICE + shareUser._id, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default SharedUsersManager;
