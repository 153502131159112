import { USERS_URL } from '../utils/url';

class UserManager {
  constructor(axios, AUTHORIZATION_KEY) {
    this.axios = axios;
    this.AUTHORIZATION_KEY = AUTHORIZATION_KEY;
  }

  getUserByEmail(user) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(USERS_URL + user.email, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }

  updateUser(formData, uid) {
    return new Promise((resolve, reject) => {
      this.axios
        .put(USERS_URL + uid, formData, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  // USERS_URL + uid+"/devices/newroute"

  getUserDevices(dbUser) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(USERS_URL + dbUser._id + '/devices/newroute', {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));

      //   setState((old) => ({
      //     ...old,
      //     userDevices: response.data,
      //     dbUser: dbUser,
      //     user: user,
      //   }));
    });
  }

  registerToServer(user) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(USERS_URL, user, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getSearchedUsers(search) {
    return new Promise((resolve, reject) => {
      const url = USERS_URL + '/query/' + search;
      this.axios
        .get(url, { headers: { Authorization: this.AUTHORIZATION_KEY } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  }
}

export default UserManager;
