import { IconButton, Typography } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import dateformat from 'dateformat';
import {
  getTotalDistanceFromMonthlyData,
  getTotalFuelFromMonthlyData,
} from '../../utils/utils';
import { locationManager } from '../../api-networking';
import { MobileContext } from '../../Firebase/MobileProvider';
import { MonthlyReportList } from '../table';

const MobileMonthlyReport = () => {
  const { selectedDevice, selectedDate, prevClick, nextClick } =
    useContext(MobileContext);
  const [state, setState] = useState({
    monthlyData: [],
    visible: false,
  });

  useEffect(() => {
    if (selectedDevice && selectedDate) {
      let data = {
        device_id: selectedDevice.id,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
        device_type: selectedDevice.vehicle_type,
      };
      //   setState((old) => ({ ...old, visible: true }));
      locationManager
        .getMonthlyData(data)
        .then((d) => {
          //   console.log(d);
          setState((old) => ({
            ...old,
            monthlyData:
              selectedDevice.vehicle_type === 8
                ? [...d]
                : [...d].filter((x) => x.distance > 1500),
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [selectedDevice, selectedDate]);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: 10,
          backgroundColor: '#f48e0d',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #333333',
          }}
        >
          <IconButton onClick={prevClick}>
            <ChevronLeft fontSize="large" />
          </IconButton>
          <Typography variant="button" sx={{ flex: 1 }} textAlign="center">
            Report on {dateformat(selectedDate, 'mmmm-yyyy')}
          </Typography>
          <IconButton onClick={nextClick}>
            <ChevronRight fontSize="large" />
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            border: '1px solid #555555',
            justifyContent: 'space-between',
            marginTop: 4,
            padding: 6,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="button">Distance</Typography>
            <Typography variant="button">
              {getTotalDistanceFromMonthlyData(state.monthlyData) + ' KM'}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="button" textAlign="right">
              Fuel
            </Typography>
            <Typography variant="button" textAlign="right">
              {selectedDevice.mileage
                ? getTotalFuelFromMonthlyData(
                    state.monthlyData,
                    selectedDevice
                  ) + ' Lit'
                : 'undefined'}
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          padding: 10,
          width: '95%',
          backgroundColor: '#eeeeee',
        }}
      >
        <MonthlyReportList
          monthly_data={state.monthlyData}
          device={selectedDevice}
        />
      </div>
    </div>
  );
};

export default React.memo(MobileMonthlyReport);
