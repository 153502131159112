import React, { useState, useEffect, useContext } from 'react';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Search, Clear } from '@mui/icons-material';
import { MobileSharedVehicleList } from '../table';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';

const SharedDeviceList = () => {
  const { sharedDevices, getUserSharedDevices } = useContext(FirebaseContext);
  const [state, setState] = useState({
    search: '',
  });
  const handleChange = (e) => {};

  useEffect(() => {
    if (sharedDevices.length === 0) {
      getUserSharedDevices();
    }
  }, [sharedDevices, getUserSharedDevices]);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TextField
        placeholder="Search"
        onChange={handleChange}
        name="search"
        value={state.search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search
                style={{
                  color: grey[700],
                  padding: 4,
                  cursor: 'pointer',
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setState((old) => ({ ...old, search: '' }))}
              >
                <Clear
                  style={{
                    color: grey[700],
                    padding: 4,
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div
        style={{
          display: 'flex',
          flex: 1,

          paddingBottom: 6,
          paddingTop: 6,
        }}
      >
        <MobileSharedVehicleList data={sharedDevices} />
      </div>
    </div>
  );
};

export default React.memo(SharedDeviceList);
