import { useState, useEffect, useContext, Fragment } from 'react';
import {
  Box,
  Card,
  IconButton,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Button,
} from '@mui/material';
import dateformat from 'dateformat';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import { temperatureManager } from '../../../api-networking';

const timeFormat = (dateStr) => {
  let timeArr = dateStr.split('T')[1].split('.')[0].split(':');
  let ampm = parseInt(timeArr[0]) >= 12 ? 'PM' : 'AM';
  let hours = parseInt(timeArr[0]) % 12;
  hours = hours ? hours : 12;
  hours = hours.toString().padStart(2, '0');
  return hours + ':' + timeArr[1] + ':' + timeArr[2] + ' ' + ampm;
};

const getduration = (time) => {
  if (time >= 3600) {
    let hr = Math.floor(time / 3600);
    let rem = time % 3600;
    let min = Math.floor(rem / 60);
    let sec = rem % 60;
    return (
      hr +
      ' hr ' +
      min.toString().padStart(2, 0) +
      ' min ' +
      sec.toString().padStart(2, 0) +
      ' sec'
    );
  } else {
    let min = Math.floor(time / 60);
    let sec = time % 60;
    return (
      min.toString().padStart(2, 0) +
      ' min ' +
      sec.toString().padStart(2, 0) +
      ' sec'
    );
  }
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const onButtonClick = (x) => {
    let url =
      'https://www.google.com/maps/search/?api=1&query=' + x.lat + ',' + x.lng;
    window.open(url);
  };

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row._id}
        </TableCell>
        <TableCell align="left">{timeFormat(row.first_start)}</TableCell>
        <TableCell align="left">{timeFormat(row.last_stop)}</TableCell>
        <TableCell align="right">{getduration(row.total_duration)}</TableCell>
        <TableCell align="center">{row.location_found}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell align="right">Duration</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="center">View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.spans.map((span) => (
                    <TableRow key={span.start}>
                      <TableCell component="th" scope="row">
                        {timeFormat(span.start)}
                      </TableCell>
                      <TableCell>{timeFormat(span.end)}</TableCell>
                      <TableCell align="right">
                        {getduration(span.duration)}
                      </TableCell>
                      <TableCell align="right">{span.status}</TableCell>
                      <TableCell align="center">
                        {span.status === 'STATIC' ? (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => onButtonClick(span.position)}
                          >
                            View
                          </Button>
                        ) : (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => onButtonClick(span.start_position)}
                            >
                              Start
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => onButtonClick(span.end_position)}
                            >
                              End
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const PTIOTable = ({ data }) => {
  return (
    <TableContainer style={{ maxHeight: 450 }}>
      <Table
        stickyHeader
        sx={{
          height: 'max-content',
        }}
        aria-label="collapsible table"
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">Day</TableCell>
            <TableCell align="left">First Start</TableCell>
            <TableCell align="left">Last Stop</TableCell>
            <TableCell align="right">Duration</TableCell>
            <TableCell align="center">Location Found</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row._id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PTIOReport = () => {
  const { selectedDevice } = useContext(FirebaseContext);

  const [state, setState] = useState({
    date: new Date(),
    data: [],
  });

  const nextClick = () => {
    const bbb = new Date(state.date);
    bbb.setMonth(bbb.getMonth() + 1);
    setState((old) => ({ ...old, date: bbb }));
  };

  const getTotalDuration = () => {
    let duration = 0;
    for (let i = 0; i < state.data.length; i++) {
      duration = duration + state.data[i].total_duration;
    }
    return duration;
  };

  const prevClick = () => {
    const bbb = new Date(state.date);
    bbb.setMonth(bbb.getMonth() - 1);
    setState((old) => ({ ...old, date: bbb }));
  };

  useEffect(() => {
    let data = {
      BOX_ID: selectedDevice.id,
      year: state.date.getFullYear(),
      month: state.date.getMonth() + 1,
    };

    temperatureManager
      .getPTIOReport(data)
      .then((d) => setState((old) => ({ ...old, data: d })))
      .catch((err) => console.log(err));
  }, [selectedDevice, state.date]);
  return (
    <div style={{ width: '100%', height: '100%', padding: 10 }}>
      <Card style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="button">Day Count</Typography>
              {state.data && (
                <Typography variant="body1">{state.data.length} Nos</Typography>
              )}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Previous">
                <IconButton onClick={prevClick}>
                  <KeyboardArrowLeft />
                </IconButton>
              </Tooltip>
              <Typography
                style={{ marginLeft: 20, marginRight: 20 }}
                variant="button"
              >
                Report on {dateformat(state.date, 'mmmm-yyyy')}
              </Typography>

              <Tooltip title="Next">
                <IconButton onClick={nextClick}>
                  <KeyboardArrowRight />
                </IconButton>
              </Tooltip>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Typography variant="button">PTIO Active</Typography>
                {state.data && (
                  <Typography variant="body1">
                    {getduration(getTotalDuration())}
                  </Typography>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flex: 1 }}>
            <PTIOTable data={state.data} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PTIOReport;
