import { useContext, useState } from 'react';

import 'react-virtualized/styles.css';
import { Typography, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import {
  getCarType,
  getDeviceStateColor,
  getDeviceState,
  getStopDuration,
} from '../../utils/web_utils';

import {
  Circle,
  VpnKeyOutlined,
  EvStation,
  SatelliteAlt,
  PowerOff,
  Power,
  Battery0Bar,
  Battery1Bar,
  Battery2Bar,
  Battery4Bar,
  Battery5Bar,
  Battery6Bar,
  BatteryFull,
  MoreVert,
} from '@mui/icons-material';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';
import { red, green, grey } from '@mui/material/colors';

const SharedDeviceItem = ({ device }) => {
  const {
    onMapClick,
    onDailyReportClick,
    onMonthlyReportClick,
    onRouteSummeryClick,
    onSpeedReportClick,
    onDailyTravelReportClick,
    onMonthlyTemperatureReportClick,
    onPTIOReportClick,
  } = useContext(FirebaseContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '90%',
        flexDirection: 'column',
        backgroundColor: 'white',
        border: '1px solid grey',
        borderRadius: 5,
      }}
    >
      {device && (
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 8,
            }}
          >
            <Typography variant="textTitle">ID : {device.id}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={getDeviceState(device)}>
                <Circle sx={{ color: getDeviceStateColor(device) }} />
              </Tooltip>

              <Tooltip title="More..">
                <IconButton
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => onMapClick(device, true)}>
                  Live Tracking
                </MenuItem>
                <MenuItem onClick={() => onDailyReportClick(device, true)}>
                  Daily Report
                </MenuItem>
                <MenuItem onClick={() => onMonthlyReportClick(device, true)}>
                  Monthly Report
                </MenuItem>
                <MenuItem
                  onClick={() => onDailyTravelReportClick(device, true)}
                >
                  Daily Travel Report
                </MenuItem>
                <MenuItem onClick={() => onSpeedReportClick(device, true)}>
                  Speed Report
                </MenuItem>

                <MenuItem onClick={() => onRouteSummeryClick(device, true)}>
                  Route Summery
                </MenuItem>

                {device && device.device_model === 'S5E' && (
                  <MenuItem
                    onClick={() =>
                      onMonthlyTemperatureReportClick(device, true)
                    }
                  >
                    Monthly Temperature Log
                  </MenuItem>
                )}

                {device && device.device_model === 'S5E' && (
                  <MenuItem onClick={() => onPTIOReportClick(device, true)}>
                    PTIO Report
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              padding: 8,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="textLight">
                Type : {getCarType(device.vehicle_type).toUpperCase()}
              </Typography>
              <Typography variant="textLight">
                Engine : {device.registration_number}
              </Typography>
              <Typography variant="textLight">
                Chasis : {device.chasis_number}
              </Typography>
              <Typography variant="textLight">Code : {device.code}</Typography>
              <Typography variant="textLight">
                SIM : {device.device_sim_number}
              </Typography>
              <Typography variant="textLight">
                Customer : {device.driver_name}
              </Typography>
              <Typography variant="textLight">
                Customer Contact : {device.driver_phone}
              </Typography>

              <Typography variant="textLight">
                Stop Duration : {getStopDuration(device)}
              </Typography>
              <Typography variant="textLight">
                Total Distance :{' '}
                {device.geo
                  ? device.device_model === 'S5E'
                    ? device.geo.milage
                    : (device.geo.milage / 1000).toFixed(2)
                  : 0}{' '}
                KM
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip
                title={
                  device.geo
                    ? 'Ignition ' + device.geo.acc
                    : 'Ignition undefined'
                }
              >
                <VpnKeyOutlined
                  sx={{
                    color:
                      device.geo && device.geo.acc === 'ON'
                        ? green[500]
                        : red[500],
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  device.geo
                    ? 'Charging ' + device.geo.charging
                    : 'Charging undefined'
                }
              >
                <EvStation
                  sx={{
                    color:
                      device.geo && device.geo.charging === 'ON'
                        ? green[500]
                        : red[500],
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  device.geo
                    ? 'Relay ' + device.geo.fuel_line
                    : 'Relay undefined'
                }
              >
                {device.geo ? (
                  device.geo.fuel_line === 'ON' ? (
                    <Power sx={{ color: green[500] }} />
                  ) : (
                    <PowerOff sx={{ color: red[500] }} />
                  )
                ) : (
                  <PowerOff sx={{ color: grey[500] }} />
                )}
                {/* <VpnKeyOutlined /> */}
              </Tooltip>
              <Tooltip
                title={
                  device.geo
                    ? device.geo.voltage_level
                      ? device.geo.voltage_level
                      : 'Battery Inactive'
                    : 'Not-Connected'
                }
              >
                {device.geo ? (
                  device.geo.voltage_level ? (
                    device.geo.voltage_level.toLowerCase().trim() ===
                    'very high bettery' ? (
                      <BatteryFull sx={{ color: green[700] }} />
                    ) : device.geo.voltage_level.toLowerCase().trim() ===
                        'high bettery' ||
                      device.geo.voltage_level.toLowerCase().trim() ===
                        'high' ? (
                      <Battery6Bar sx={{ color: green[500] }} />
                    ) : device.geo.voltage_level.toLowerCase().trim() ===
                      'medium bettery' ? (
                      <Battery5Bar sx={{ color: green[300] }} />
                    ) : device.geo.voltage_level.toLowerCase().trim() ===
                        'low bettery' ||
                      device.geo.voltage_level.toLowerCase().trim() ===
                        'low' ? (
                      <Battery4Bar sx={{ color: red[300] }} />
                    ) : device.geo.voltage_level.toLowerCase().trim() ===
                      'extremely low bettery' ? (
                      <Battery2Bar sx={{ color: red[500] }} />
                    ) : device.geo.voltage_level.toLowerCase().trim() ===
                      'very extremely low bettery' ? (
                      <Battery1Bar sx={{ color: red[600] }} />
                    ) : (
                      <Battery0Bar sx={{ color: red[700] }} />
                    )
                  ) : (
                    <Battery0Bar sx={{ color: grey[500] }} />
                  )
                ) : (
                  <Battery0Bar sx={{ color: grey[700] }} />
                )}
              </Tooltip>
              <Tooltip
                title={
                  device.geo
                    ? device.geo.number_of_satellite >= 6
                      ? 'Positioned'
                      : 'Position not Fixed'
                    : 'Not-Connected'
                }
              >
                <SatelliteAlt
                  sx={{
                    color:
                      device.geo && device.geo.number_of_satellite >= 6
                        ? green[500]
                        : red[500],
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      )}

      {/* <Divider sx={{ borderBottomWidth: 1, bgcolor: 'gray' }} />
      <div
        style={{
          display: 'flex',
          height: 50,

          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tooltip title="Live Tracking">
          <IconButton onClick={() => onMapClick(device)}>
            <LocationOnOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit Vehicle">
          <IconButton onClick={() => onEditClick(device)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title="Settings">
          <IconButton onClick={() => openSettings(device)}>
            <SettingsOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title="Share Vehicle">
          <IconButton onClick={() => openShareDeviceDialog(device)}>
            <ShareOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title="Shared Users">
          <IconButton onClick={() => openShareUsersDialog(device)}>
            <GroupOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title="More..">
          <IconButton
            aria-controls={open ? 'basic-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => onDailyReportClick(device)}>
            Daily Report
          </MenuItem>
          <MenuItem onClick={() => onMonthlyReportClick(device)}>
            Monthly Report
          </MenuItem>
          <MenuItem onClick={() => onDailyTravelReportClick(device)}>
            Daily Travel Report
          </MenuItem>
          <MenuItem onClick={() => onSpeedReportClick(device)}>
            Speed Report
          </MenuItem>

          <MenuItem onClick={() => onRouteSummeryClick(device)}>
            Route Summery
          </MenuItem>

          {device && device.device_model === 'S5E' && (
            <MenuItem>Monthly Temperature Log</MenuItem>
          )}

          {device && device.device_model === 'S5E' && (
            <MenuItem>PTIO Report</MenuItem>
          )}
        </Menu>
      </div> */}
    </div>
  );
};

export default SharedDeviceItem;
