import { useState, useRef, useEffect, useContext } from 'react';
import {
  IconButton,
  Slide,
  TextField,
  InputAdornment,
  ListItem,
  ListItemText,
  List,
} from '@mui/material';
import { ExpandLess, ExpandMore, Search, Clear } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import { deviceFilter } from '../../../utils/utils';

const DeviceListOverlay = () => {
  const {
    selectedDevice,
    userDevices,
    setSelectedDevice,
    fromSharedUser,
    sharedDevices,
  } = useContext(FirebaseContext);

  const [state, setState] = useState({
    open: false,
    search: '',
    searchedDevice: [],
  });

  const containerRef = useRef(null);

  const handleChange = (e) => {
    e.persist();
    setState((old) => ({
      ...old,
      search: e.target ? e.target.value : '',
    }));
  };

  useEffect(() => {
    if (state.search === '') {
      setState((old) => ({
        ...old,
        searchedDevice: fromSharedUser ? [...sharedDevices] : [...userDevices],
      }));
    } else {
      setState((old) => ({
        ...old,
        searchedDevice: fromSharedUser
          ? [...sharedDevices].filter((x) => deviceFilter(x, state.search))
          : [...userDevices].filter((x) => deviceFilter(x, state.search)),
      }));
    }
  }, [state.search, userDevices, sharedDevices, fromSharedUser]);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '20%',
        height: '50%',
        zIndex: 500,
      }}
      ref={containerRef}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {state.open && (
          <Slide
            direction="up"
            in={state.open}
            container={containerRef.current}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                backgroundColor: 'white',
                overflow: 'auto',
              }}
            >
              <List style={{ width: '100%' }}>
                {state.searchedDevice.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    divider
                    onClick={() => setSelectedDevice(item)}
                    selected={selectedDevice && item.id === selectedDevice.id}
                  >
                    <ListItemText style={{ textTransform: 'uppercase' }}>
                      {item.registration_number}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>
          </Slide>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            alignItems: 'center',
            padding: 10,
          }}
        >
          <TextField
            style={{ flex: 1 }}
            placeholder="Search vehicle by reg. number"
            onChange={handleChange}
            name="search"
            value={state.search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search
                    style={{
                      color: grey[700],
                      padding: 4,
                      cursor: 'pointer',
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setState((old) => ({ ...old, search: '' }))}
                  >
                    <Clear
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            onClick={() => setState((old) => ({ ...old, open: !old.open }))}
          >
            {state.open ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default DeviceListOverlay;
