import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Settings, Home, Share } from '@mui/icons-material';
import DeviceList from './DeviceList';
import SharedDevicesList from './SharedDevicesList';
import MobileSettings from './MobileSettings';
import { MobileContext } from '../../Firebase/MobileProvider';
import { grey } from '@mui/material/colors';

const MobileMain = () => {
  const [state, setState] = useState({
    value: 0,
    component: DeviceList,
  });
  const { setTitle } = useContext(MobileContext);

  useEffect(() => {
    if (state.value === 0) {
      setState((old) => ({ ...old, component: DeviceList }));
      setTitle('Vehicle List');
    } else if (state.value === 1) {
      setState((old) => ({ ...old, component: SharedDevicesList }));
      setTitle('Shared Vehicle List');
    } else if (state.value === 2) {
      setState((old) => ({ ...old, component: MobileSettings }));
      setTitle('Settings');
    }
  }, [state.value]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flex: 1,
          backgroundColor: grey[200],
        }}
      >
        {<state.component />}
      </div>
      <BottomNavigation
        showLabels
        value={state.value}
        onChange={(event, newValue) => {
          setState((old) => ({ ...old, value: newValue }));
        }}
      >
        <BottomNavigationAction label="Vehicles" icon={<Home />} />
        <BottomNavigationAction label="Shared" icon={<Share />} />
        <BottomNavigationAction label="Settings" icon={<Settings />} />
      </BottomNavigation>
    </div>
  );
};

export default React.memo(MobileMain);
