import React, { useContext } from 'react';

import { styled } from '@mui/material/styles';

import MobileAppBar from './MobileAppBar';
import { MobileContext } from '../../Firebase/MobileProvider';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MobileHome = () => {
  const { title, Component } = useContext(MobileContext);

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <MobileAppBar title={title} />
      <DrawerHeader />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flex: 1,
        }}
      >
        {<Component />}
      </div>
    </div>
  );
};

export default React.memo(MobileHome);
