import { useContext, useEffect, useState, useRef } from 'react';
import { GoogleMap, TrafficLayer } from '@react-google-maps/api';
import { FirebaseContext } from '../../Firebase/FirebaseProvider';
import { MobileContext } from '../../Firebase/MobileProvider';
import { FILTER_DAY } from '../../utils/web_utils';
import MyMarker from '../pages/gmap/MyMarker';
import MobileVehicleInfo from '../pages/gmap/MobileVehicleInfo';

let defaultLatLng = {
  lat: 23.8103,
  lng: 90.4125,
};

const LiveTrackingMobile = () => {
  const polyLineRef = useRef(null);
  const [state, setState] = useState({
    search: '',
    center: { ...defaultLatLng },
    zoom: 15,
    searchDevice: [],
    open: false,
    status: null,
    map: null,
  });

  const { selectedDevice, setSelectedDevice, fromSharedUser } =
    useContext(MobileContext);

  const { userDevices, sharedDevices } = useContext(FirebaseContext);

  //   console.log(useContext(MobileContext));

  useEffect(() => {
    if (!selectedDevice) {
      if (state.map) {
        const bounds = new window.google.maps.LatLngBounds();
        (fromSharedUser ? [...sharedDevices] : [...userDevices])
          .filter((x) => x.geo)
          .forEach((x) => {
            bounds.extend({ lat: x.geo.lat, lng: x.geo.lng });
          });
        state.map.fitBounds(bounds);
      }
      setState((old) => ({
        ...old,
        searchDevice: (fromSharedUser
          ? [...sharedDevices]
          : [...userDevices]
        ).filter((x) => x.geo),
      }));
    } else {
      if (state.map) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend({ ...state.center });
        state.map.fitBounds(bounds);
      }
    }
  }, [
    selectedDevice,
    userDevices,
    state.map,
    state.center,
    sharedDevices,
    fromSharedUser,
  ]);

  const infoCardClick = (val) => {
    const bounds = new window.google.maps.LatLngBounds();
    let devices = [];

    if (val === 0) {
      devices = (fromSharedUser ? [...sharedDevices] : [...userDevices])
        .filter((x) => x.geo)
        .filter(
          (dev) =>
            dev.geo.update_time &&
            Math.floor(
              (new Date() - new Date(dev.geo.update_time)) /
                (1000 * 60 * 60 * 24)
            ) <= FILTER_DAY
        );
    } else if (val === 1) {
      devices = (fromSharedUser ? [...sharedDevices] : [...userDevices])
        .filter((dev) => dev.geo)
        .filter(
          (dev) =>
            dev.geo.update_time &&
            Math.floor(
              (new Date() - new Date(dev.geo.update_time)) /
                (1000 * 60 * 60 * 24)
            ) > FILTER_DAY
        );
    } else if (val === 2) {
      devices = (fromSharedUser ? [...sharedDevices] : [...userDevices]).filter(
        (x) => x.geo && x.geo.acc === 'ON'
      );
    } else if (val === 3) {
      devices = (fromSharedUser ? [...sharedDevices] : [...userDevices]).filter(
        (x) => x.geo && x.geo.acc === 'OFF'
      );
    }

    devices.forEach((x) => {
      bounds.extend({ lat: x.geo.lat, lng: x.geo.lng });
    });
    state.map.fitBounds(bounds);

    setState((old) => ({ ...old, searchDevice: devices }));
  };

  const handleShowAllVehiclesClick = () => {
    if (polyLineRef.current) {
      polyLineRef.current.setMap(null);
    }
    setSelectedDevice(null);
  };

  const setCenter = (data) => {
    setState((old) => ({
      ...old,
      center: { lat: data.lat, lng: data.lng },
      status: data,
    }));
  };

  const setStatus = (data) => {
    setState((old) => ({ ...old, status: data }));
  };

  const drawPolyLines = (locations) => {
    if (polyLineRef.current) {
      polyLineRef.current.setMap(null);
    }
    const polylinePath = [];
    locations.forEach((x) => {
      polylinePath.push({ lat: x.lat, lng: x.lng });
    });
    const routePath = new window.google.maps.Polyline({
      path: polylinePath,
      geodesic: true,
      strokeColor: '#000000',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });

    routePath.setMap(state.map);

    polyLineRef.current = routePath;
    // console.log(locations, 'Draw Poly Lines');
  };
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={state.center}
          zoom={state.zoom}
          onLoad={(map) => setState((old) => ({ ...old, map: map }))}
          onUnmount={(map) => setState((old) => ({ ...old, map: null }))}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: false,
            panControl: false,
          }}
        >
          <TrafficLayer />
          {selectedDevice ? (
            <MyMarker
              device={selectedDevice}
              setCenter={setCenter}
              setStatus={setStatus}
              drawPolyLines={drawPolyLines}
            />
          ) : (
            state.searchDevice.map((x) => <MyMarker key={x.id} device={x} />)
          )}
        </GoogleMap>
      </div>

      <MobileVehicleInfo
        handleShowAllVehiclesClick={handleShowAllVehiclesClick}
        status={state.status}
        infoCardClick={infoCardClick}
      />
    </div>
  );
};

export default LiveTrackingMobile;
