import React, { useState, useEffect, useRef, useContext } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { off, onChildAdded, onChildChanged } from 'firebase/database';
import { FirebaseContext } from '../../../Firebase/FirebaseProvider';
import { getRotation } from '../../../utils/utils';
import { Typography } from '@mui/material';

const MyMarker = ({ device, setCenter, setStatus, drawPolyLines }) => {
  const { getSingleDeviceDatabaseRef } = useContext(FirebaseContext);

  const [state, setState] = useState({
    geo: null,
    rotation: 0,
    show: false,
    locations: [],
  });
  const myRef = useRef();

  useEffect(() => {
    const animate = (newGeo) => {
      let oldGeo = null;

      myRef.current
        ? (oldGeo = { ...myRef.current })
        : (oldGeo = { ...state.geo });
      let step = 20;
      let deltaLat = (newGeo.lat - oldGeo.lat) / step;
      let deltaLng = (newGeo.lng - oldGeo.lng) / step;

      setState((old) => ({ ...old, rotation: getRotation(oldGeo, newGeo) }));

      const anim = () => {
        if (step > 0) {
          setState((old) => ({
            ...old,
            geo: {
              lat: oldGeo.lat + (21 - step) * deltaLat,
              lng: oldGeo.lng + (21 - step) * deltaLng,
            },
          }));
          requestAnimationFrame(anim);
          step--;
        } else {
          myRef.current = { ...newGeo };

          setState((old) => ({
            ...old,
            geo: {
              ...old.geo,
              acc: newGeo.acc,
              update_time: newGeo.update_time,
              speed: newGeo.speed,
            },
            locations: setCenter
              ? [...old.locations, { lat: newGeo.lat, lng: newGeo.lng }]
              : [...old.locations],
          }));
        }
      };

      requestAnimationFrame(anim);
    };

    let ref = getSingleDeviceDatabaseRef(device.id);

    onChildAdded(ref, (snapshot) => {
      if (snapshot.key === 'geo') {
        let data = snapshot.val();
        myRef.current = { ...data };
        setState((old) => ({
          ...old,
          geo: data,
          locations: setCenter ? [{ lat: data.lat, lng: data.lng }] : [],
        }));
        setCenter && setCenter(data);
      }
    });

    onChildChanged(ref, (snapshot) => {
      let data = snapshot.val();
      setStatus && setStatus(data);
      animate(data);
    });

    return () => off(ref);

    // console.log(ref);
  }, [device, getSingleDeviceDatabaseRef]);

  useEffect(() => {
    // console.log('Location Changed', state.locations);
    if (state.locations.length > 1 && drawPolyLines) {
      drawPolyLines([...state.locations]);
    }
  }, [state.locations, drawPolyLines]);

  return (
    <div>
      {state.geo && (
        <Marker
          alt="Vehicle Location"
          position={{ lat: state.geo.lat, lng: state.geo.lng }}
          onClick={() => setState((old) => ({ ...old, show: true }))}
          clickable={true}
          //   label={device.id}
          icon={{
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z',
            fillColor: 'black',

            fillOpacity: 0.9,
            strokeColor: state.geo.acc === 'ON' ? 'green' : 'red',
            strokeWeight: 2,
            rotation: state.rotation,
            anchor: new window.google.maps.Point(25, 25),
          }}
        >
          {state.show && (
            <InfoWindow
              onCloseClick={() => setState((old) => ({ ...old, show: false }))}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography variant="caption">{device.id}</Typography>
                <Typography variant="caption">
                  {device.registration_number}
                </Typography>
              </div>
            </InfoWindow>
          )}
        </Marker>
      )}
    </div>
  );
};

export default MyMarker;
