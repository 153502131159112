import { Button, Card, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  NotificationsNone,
  Person,
  Payment,
  Language,
  HomeWork,
  SupportAgent,
} from '@mui/icons-material';

const Item = ({ title, Icon }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // border: '1px solid black',
      }}
    >
      <Button variant="outlined" style={{ width: '80%', height: '80%' }}>
        <div
          style={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon />
          <Typography fontSize="0.6rem">{title}</Typography>
        </div>
      </Button>
    </div>
  );
};

const MobileSettings = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          position: 'absolute',
          height: '30%',
          backgroundColor: '#f48e0d',
          top: 0,
          left: 0,
          right: 0,
        }}
      ></div>

      <Card
        style={{
          position: 'absolute',
          height: '85%',
          top: '10%',
          left: '5%',
          right: '5%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '4%',
            top: '4%',
            left: '4%',
            right: '4%',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'repeat(5, 1fr)',
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid black',
            }}
          >
            <Button variant="outlined" style={{ width: '80%', height: '80%' }}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <NotificationsNone />
                <Typography>Alert</Typography>
              </div>
            </Button>
          </div> */}

          <Item title="Profile" Icon={Person} />
          <Item title="Alert Setting" Icon={NotificationsNone} />
          <Item title="Payment Guide" Icon={Payment} />
          <Item title="Language" Icon={Language} />
          <Item title="Info" Icon={HomeWork} />
          <Item title="Customer Service" Icon={SupportAgent} />
        </div>
      </Card>
    </div>
  );
};

export default React.memo(MobileSettings);
