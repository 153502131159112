import { SHARED_DEVICE, USER_SHARED_DEVICE } from '../utils/url';

class SharedDeviceManager {
  constructor(axios, AUTHORIZATION_KEY) {
    this.axios = axios;
    this.AUTHORIZATION_KEY = AUTHORIZATION_KEY;
  }

  addSharedUser(sharedDevice, user) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(SHARED_DEVICE, sharedDevice, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => response.data)
        .then((sd) => {
          //   console.log(sd);
          sd.user = user;
          resolve(sd);
        })
        .catch((err) => reject(err));
    });
  }

  getUserSharedDevices(uid) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(USER_SHARED_DEVICE + uid + '/newroute', {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }
}

export default SharedDeviceManager;
