import { NOTIFICATION } from '../utils/url';

class NotificationManager {
  constructor(axios, AUTHORIZATION_KEY) {
    this.axios = axios;
    this.AUTHORIZATION_KEY = AUTHORIZATION_KEY;
  }

  getUserNotification(uid) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(NOTIFICATION + uid, {
          headers: { Authorization: this.AUTHORIZATION_KEY },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }

  //   deleteManyAlerts(alerts) {
  //     return new Promise((resolve, reject) => {
  //       let ids = alerts.map((x) => x._id);
  //       this.axios
  //         .post(
  //           DELETE_MANY_ALERTS,
  //           { ids: ids },
  //           {
  //             headers: { Authorization: this.AUTHORIZATION_KEY },
  //           }
  //         )
  //         .then((response) => resolve(response.data))
  //         .catch((err) => reject(err));
  //     });
  //   }
}

export default NotificationManager;
