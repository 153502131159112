// import MuiAlert from '@mui/lab/Alert';
import { Snackbar, Alert } from '@mui/material';
import { useState } from 'react';

const MySnackbar = ({ open, handleClose, message, severity }) => {
  const [state] = useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MySnackbar;
